import { ProductoProvider } from "../../../Productos/context/ProductContext";
import { TransaccionesProvider } from "../../TransaccionesEmitidas/context/TransaccionesContext";
import TransferenciasProvider from "./Context/TransferenciasProvider";
import TransferenciasForm from "./TransferenciasForm";

export default function Transferencias() {
  return (
      <TransaccionesProvider>
        <TransferenciasProvider>
          <TransferenciasForm />
        </TransferenciasProvider>
      </TransaccionesProvider>
  );
}
