import { useStore } from "zustand";
import { CrearProductoForm } from "./CrearProductoForm";
import { CrearProductoList } from "./CrearProductoList";
import {
  ModalProductosStatus,
  useModalProductoStore,
} from "./ModalProductoContext";
import { ModalEliminarProducto } from "./ModalEliminarProducto";
import { ModalDesabilitarProducto } from "./ModalDesabilitarProducto";

export const CrearProducto = () => {
  const { ModalProducto, ProductoAfectado } = useStore(useModalProductoStore);

  return (
    <>
      <section className="w-full h-full flex flex-col gap-5">
        <CrearProductoForm />
        <CrearProductoList />
      </section>
      {ModalProducto === ModalProductosStatus.eliminar && ProductoAfectado && (
        <ModalEliminarProducto />
      )}
      {ModalProducto === ModalProductosStatus.desabilitar && (
        <ModalDesabilitarProducto />
      )}
    </>
  );
};
