import { useStore } from "zustand";
import { CrearSeccionForm } from "./CrearSeccionForm";
import { CrearSeccionList } from "./CrearSeccionList";
import {
  ModalSeccionStatus,
  useModalSeccionStore,
} from "./SeccionModalContext";
import { ModalEliminarSeccion } from "./ModalEliminarSeccion";
import { ModalEditarSeccion } from "./ModalEditarSeccion";
import { SeccionProvider } from "../context/SeccionContext";
import EstablecimientosProvider from "../../../Mantenimiento/Establecimiento/EstablecimientosProvider";

export const CrearSeccion = () => {
  const { SeccionAfectada, ModalSeccion } = useStore(useModalSeccionStore);
  return (
    <EstablecimientosProvider>
      <SeccionProvider>
        <section className="w-full h-full flex flex-col gap-5">
          <CrearSeccionForm />
          <CrearSeccionList />
        </section>
        {SeccionAfectada && ModalSeccion === ModalSeccionStatus.eliminar && (
          <ModalEliminarSeccion />
        )}
        {SeccionAfectada && ModalSeccion === ModalSeccionStatus.editar && (
          <ModalEditarSeccion />
        )}
      </SeccionProvider>
    </EstablecimientosProvider>
  );
};
