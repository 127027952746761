import React, { useContext, useEffect, useState } from "react";
import MesageError from "../../../features/components/MessageError";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import {
  StyleInput,
  StyleSelect,
  StyleInputError,
} from "../../../features/Constantes";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
//import { useFormikContext } from 'formik';

import { getListV2 } from "../../../services";
import { EmitirDocContext } from "./EmitirDocProvider";
import {
  getFechaV1,
  getNoventaDiasAtras,
  rellenarCeros,
  roundToTwo,
} from "../../../services/Utilitario";
import Contribuyente from "../../../features/components/Contribuyente";
import useVistaPrevia from "../hooks/useVistaPrevia";
import MobileButtonOptionsNd from "./MobileButtonOptionsNd";
import { toast } from "react-toastify";
import Toggle from "../../../features/components/Toggle";
import { CalcularImpuestosList } from "../EmitirFactura/services/impuestos";
import { ModalHours } from "../../../features/hooks/ModalHours";


const FormDoc = () => {
  const {
    formik,
    DetalleList,
    Totales,
    FormaPagoList,
    InformacionAdicionalList,
    setTotales,
    TotalInicial,
    setDetalleList,
    setFormaPagoList,
    setInformacionAdicionalList,
    isOpenModalHours,
    toggleModal,
  } = useContext(EmitirDocContext);
  //const formikProps = useFormikContext()

  const empresa = useSelector((store) => store.empresa.empresa);

  const { ErrorToast } = useGlobalToastify();

  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);
  const [IsError, setIsError] = useState(false);
  const [estabTmp, setEstabTmp] = useState("###");
  const [pteTmp, setPteTmp] = useState("###");
  const [secTmp, setSecTmp] = useState("#########");

  const { GetVistaPreviaNotaDebito, IsLoading } = useVistaPrevia();
  useEffect(() => {
    (async () => {
      //ObtenerEstablecimientosPorEmpresa
      const GetAllEstablecimientos = await getListV2(
        empresa,
        "ObtenerEstablecimientosPorEmpresa"
      );
      //  if (GetAllEstablecimientos._embedded.length > 1) {

      formik.setFieldValue(
        "establecimiento",
        GetAllEstablecimientos._embedded[0].codigo
      );
      setEstabTmp(GetAllEstablecimientos._embedded[0].codigo);
      setEstablecimientos(GetAllEstablecimientos._embedded);

      await getPuntosEmision(GetAllEstablecimientos._embedded[0]);
      //  }
    })();
  }, []);

  const handleChangeEstablecimiento = async (e) => {
    if (e.target.value) {
      const index = e.target.selectedOptions[0].getAttribute("data-index");
      formik.setFieldValue("establecimiento", e.target.value);
      formik.setFieldValue("puntoEmision", "");
      formik.setFieldValue("secuencial", "");
      setEstabTmp(e.target.value);
      const establecimiento = Establecimientos[index];

      await getPuntosEmision(establecimiento);

      formik.handleChange(e);
    }
  };

  const handleVistaPrevia = async () => {
    let parsedInfo = {
      IdContribuyente: formik.values.idContribuyente,
      IdentificacionCliente: formik.values.clienteIdentificacion,
      RazonSocialContribuyente: formik.values.clienteRazonSocial,
      Direccion: formik.values.clienteDireccion,
      TelefonoCelular: formik.values.clienteTelefono,
      CodigoDocumentoModificado: formik.values.codigoComprobanteModificar,
      NumeroDocumentoModificado: formik.values.numeroComprobanteModificar,
      FechaEmisionDocumentoModificado: getFechaV1(
        formik.values.fechaEmisionComprobanteModificar
      ),
      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      IdEmpresa: empresa.idEmpresa,
      RucEmpresa: empresa.ruc,
      TotalDescuento: Totales.totalDescuentos,
      CodDoc: 5,
      FechaEmision: getFechaV1(formik.values.fechaEmision),
      ImporteTotal: Totales.importeTotal,
      TotalSinImpuestos: Totales.totalSinImpuesto,

      ListaImpuestoND: await CalcularImpuestosList(DetalleList,empresa,Totales),

      InformacionAdicionalNotaDebito: InformacionAdicionalList.map((x) => {
        return {
          Descripcion: x.nombre,
          Valor: x.valor,
        };
      }),
      DetalleNotaDebito: DetalleList.map((e) => {
        return {
          Motivos: e.motivo,
          // (parseFloat (e.valor) * parseFloat(e.porcentaje)) + e.valor,
          Valor: e.valor,
          Descripcion: e.descripcion,
          Cantidad: e.cantidad,
          ImpuestoValorIva: e.iva,
          // CodigoPrincipal: e.codigoPrincipal,
          // PrecioUnitario: e.precioUnitario,
          // Descuento: 0,
          ImpuestoCodigoIva: 2,
          ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
          PrecioTotalSinImpuesto: e.subTotal,
          ImpuestoBaseImponibleIva: e.subTotal,

          Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
          InformacionAdicionalProductoCredito:
            e.servicioDetalle === undefined
              ? []
              : e.servicioDetalle === null
              ? []
              : e.servicioDetalle.map((x) => {
                  return {
                    Valor: x.nombre,
                    Descripcion: x.valor,
                  };
                }),
        };
      }),

      FormaPagoNotaDebito: FormaPagoList.map((x) => {
        // alert("");

        return {
          UnidaDeTiempo: x.tiempoPago,
          Plazo: x.plazoPago,
          Total: x.valor,
          IdFormaPagoSri: x.tipoPago,
        };
      }),
      IdImpuestoNoObjectoImpuesto:
        Totales.subTotalNoObjetoIva !== 0
          ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
          : "",
      IdImpuestoExentoIva:
        Totales.subTotalExentoIva !== 0
          ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
          : "",
      IdImpuestoIVA12:
        Totales.subTotalIva !== 0 ? "A5B8F607-8D38-4150-97AF-C591F83678D8" : "",
      IdImpuestoIVA0:
        Totales.subTotalIva0 !== 0
          ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
          : "",
      //IdImpuestoIVA14= 8DCD2CFA-5F9D-4FB7-B27A-87447D6CDDE7
      TotalTotalIva0: 0,
      TotalTotalIva: Totales.totalIva,
      TotalNoObjetoImpuesto: 0,
      TotalExentoIva: 0,
      TotalBaseIva0: Totales.subTotalIva0,
      TotalBaseIva: Totales.subTotalIva,
      TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
      TotalBaseExentoIva: Totales.subTotalExentoIva,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
      Secuencial: formik.values.secuencial,
      subTotalIva12: Totales.subTotalIva,
      subTotalIva0: Totales.subTotalIva0,
      subTotalNoObjetoIva: Totales.subTotalNoObjetoIva,
      subTotalExentoIva: Totales.subTotalExentoIva,
      Iva12: Totales.totalIva.toFixed(2),
      Iva0: 0,
    };

    // if (Totales.totalIva === 0) {
    //   parsedInfo.Iva0 = Totales.totalIva.toFixed(2);
    // }
    // else{
    //   parsedInfo.Iva12 = Totales.totalIva.toFixed(2);
    // }

    if (formik.values.clienteEmail !== null)
      if (formik.values.clienteEmail.trim() !== "")
        parsedInfo.InformacionAdicionalNotaDebito.push({
          Valor: formik.values.clienteEmail,
          Descripcion: "email",
        });

    let data = await GetVistaPreviaNotaDebito(parsedInfo);
    if (data === "") {
      toast.error("Faltan datos por llenar");
      return;
    }
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const getPuntosEmision = async (establecimiento) => {
    const GetAllPuntosEmision = await getListV2(
      establecimiento,
      "GetListPuntosEmision",
      `?Estado=1`
    );

    setSecTmp("#########");
    if (GetAllPuntosEmision._embedded.length > 0) {
      setPteTmp(GetAllPuntosEmision._embedded[0].codigo);
      setPuntosEmision(GetAllPuntosEmision._embedded);
      formik.setFieldValue(
        "puntoEmision",
        GetAllPuntosEmision._embedded[0].codigo
      );
      await getSecuencial(GetAllPuntosEmision._embedded[0]);
    } else {
      setPteTmp("###");
      formik.setFieldValue("puntoEmision", "");
      setPuntosEmision([]);
    }
  };

  const getSecuencial = async (puntoEmisionObj) => {
    try {
      // agregar codigo de buscar la secuencia por idPuntoMEision y el tipo de documento 01=factura

      const GetAllPuntosEmision = await getListV2(
        puntoEmisionObj,
        "getSecuencia-NotaDebito"
      );
      // set el secuencial en el form_secuencial y en el numero

      setSecTmp(GetAllPuntosEmision._embedded.codigo);
      formik.setFieldValue("secuencial", GetAllPuntosEmision._embedded.codigo);
    } catch (error) {
      //  console.log(error);
      setSecTmp("#########");
      formik.setFieldValue("secuencial", "");
    }
  };

  const handleChangePuntoEmision = (e) => {
    setSecTmp("#########");
    if (e.target.value) {
      setPteTmp(e.target.value);

      const index = e.target.selectedOptions[0].getAttribute("data-index");

      const puntoEmision = PuntosEmision[index];

      getSecuencial(puntoEmision);

      formik.handleChange(e);
    }
  };

  const handleResetDocument = () => {
    let oldPuntoEmision = formik.values.puntoEmision;
    let oldEstablecimiento = formik.values.establecimiento;
    //console.log(formik.values);
    // console.log(Number(formik.values.secuencial));
    formik.resetForm();
    formik.setFieldValue("secuencial", Number(formik.values.secuencial) + 1);
    formik.setFieldValue("puntoEmision", oldPuntoEmision);
    formik.setFieldValue("establecimiento", oldEstablecimiento);
    setTotales({ ...TotalInicial });
    setDetalleList([]);
    setFormaPagoList([]);
    setInformacionAdicionalList([]);
  };

  const handleClickSubmitFactura = async (e) => {
    try {
      e.preventDefault();

      const isValid = await formik.validateForm();

      if (DetalleList.length <= 0) {
        ErrorToast("Campos requeridos.");
      } else if (!formik.values.numeroComprobanteModificar) {
        ErrorToast("Número de documento sustento requerido.");
        setIsError(true);
      } else if (isValid) {
        setIsError(false);
        formik.handleSubmit(() => {
          setSecTmp((prevSecTmp) => prevSecTmp + 1);
          formik.setFieldValue("secuencial", secTmp + 1);
        });
      }
    } catch (ex) {
      // console.log(ex);
    }
  };

  const [valueOptionsHeader, setValueOptionsHeader] = useState("FACTURA");

  return (
    <form onSubmit={formik.handleSubmit}>
            					  <ModalHours isOpenModalHours={isOpenModalHours} toggleModal={toggleModal} />

      {IsLoading && (
        <div
          style={{ width: "100vw", height: "100vh", zIndex: "100" }}
          className="bg-opacity-70 fixed justify-center align-middle top-0 l-0 bg-white text-center"
        >
          <div className="w-full h-full flex justify-center items-center py-4">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"
              role="status"
            >
              <span className="hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      <div className="border rounded-xl bg-white dark:bg-gray-700 dark:border-gray-600  ">
        <div className="flex justify-between">
         

        <Toggle
        text="Nota de Débito"
        setValueOptionsHeader={setValueOptionsHeader}
        >

        </Toggle>
          <div className="my-auto lg:flex md:flex sm:hidden ">
            <div className="group relative mr-4 hidden md:table-cell lg:table-cell">
              {/* nuevo boton */}

              {/*  */}

              <div className="absolute mx-auto w-28  -top-8 -left-8 hidden dark:bg-white  dark:text-black     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                <p className="text-white dark:text-black mx-auto text-sm">
                  Punto de Venta{" "}
                </p>
              </div>
            </div>

 

            <div className=" group relative inline-block">
              <div className=" cursor-pointer w-10 h-10 rounded-full  inline-flex items-center justify-center">
                <button
                  type="button"
                  onClick={handleResetDocument}
                  className="inline-flex h-10 w-10 cursor-pointer hover:shadow-md  items-center justify-center rounded-xl  transition dark:bg-gray-900 dark:hover:bg-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 dark:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>

                  <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                    <p className="text-white dark:text-black mx-auto text-sm ">
                      Limpiar
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2">
          {valueOptionsHeader === "FACTURA" ? (
            <>
              <div className="bg-[#F7F7F7] dark:bg-gray-700 p-2  rounded-lg">
                <div className=" grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2 w-full max-w-2xl  text-center">
                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Establecimiento:{" "}
                    </label>
                    <select
                      onChange={handleChangeEstablecimiento}
                      type="text"
                      name="establecimiento"
                      id="establecimiento"
                      className={StyleSelect}
                      placeholder=" "
                      defaultValue={formik.values.establecimiento}
                      onBlur={formik.handleBlur}
                    >
                      {/*data-id-establecimiento={el.idEstablecimiento}*/}
                      {Establecimientos.map((el, index) => {
                        return (
                          <option
                            key={el.idEstablecimiento}
                            data-index={index}
                            value={el.codigo}
                          >
                            {rellenarCeros(el.codigo)} {/*- {el.descripcion}*/}
                          </option>
                        );
                      })}
                    </select>
                    {/* <label htmlFor="establecimiento" className={StyleLabelForm}>
                  Establecimiento.
                </label> */}
                    {formik.touched.establecimiento &&
                    formik.errors.establecimiento ? (
                      <MesageError message={formik.errors.establecimiento} />
                    ) : null}
                  </div>

                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Punto emisión:{" "}
                    </label>
                    <select
                      type="text"
                      className={StyleSelect}
                      placeholder=" "
                      name="puntoEmision"
                      id="puntoEmision"
                      defaultValue={formik.values.puntoEmision}
                      onChange={handleChangePuntoEmision}
                      onBlur={formik.handleBlur}
                    >
                      {PuntosEmision.map((el, index) => {
                        return (
                          <option
                            key={el.idPuntoEmision}
                            value={el.codigo}
                            data-index={index}
                          >
                            {rellenarCeros(el.codigo)} {/*- {el.descripcion}*/}
                          </option>
                        );
                      })}
                    </select>
                    {/* <label htmlFor="puntoEmision" className={StyleLabelForm}>
                  Punto Emisión
                </label> */}
                    {formik.touched.puntoEmision &&
                    formik.errors.puntoEmision ? (
                      <MesageError message={formik.errors.puntoEmision} />
                    ) : null}
                  </div>

                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Secuencial:{" "}
                    </label>
                    <input
                      type="text"
                      className={StyleInput + " bg-block"}
                      value={rellenarCeros(secTmp, 9)}
                      disabled
                    />
                    {/* <label htmlFor="secuencial" className={StyleLabelForm}>
                  Secuencial
                </label> */}
                    {formik.touched.secuencial && formik.errors.secuencial ? (
                      <MesageError message={formik.errors.secuencial} />
                    ) : null}
                  </div>

                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Fecha emisión:{" "}
                    </label>
                    <input
                      type="date"
                      className={StyleInput}
                      name="fechaEmision"
                      id="fechaEmision"
                      min={getNoventaDiasAtras()}
                      value={formik.values.fechaEmision}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder=" "
                    />
                    {/* <label htmlFor="secuencial" className={StyleLabelForm}>
                  Secuencial
                </label> */}
                    {formik.touched.secuencial && formik.errors.secuencial ? (
                      <MesageError message={formik.errors.secuencial} />
                    ) : null}
                  </div>
                </div>{" "}
              </div>
            </>
          ) : (
            <>
              <div className="bg-[#F7F7F7] dark:bg-gray-700 p-2  rounded-lg">
                <div className=" grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-2 w-full max-w-2xl  text-center">
                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Código Doc. Sust.:{" "}
                    </label>

                    <select
                      value={formik.values.codigoComprobanteModificar}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={StyleSelect}
                      id="codigoComprobanteModificar"
                      name="codigoComprobanteModificar"
                    >
                      <option value="01">FACTURA</option>
                      <option value="03">
                        LIQUIDACIÓN DE COMPRA DE BIENES Y PRESTACIÓN DE
                        SERVICIOS
                      </option>
                      <option value="04">NOTA DE CRÉDITO</option>
                      <option value="05">NOTA DE DÉBITO</option>
                      <option value="07">COMPROBANTE DE RETENCIÓN</option>
                    </select>

                    {/* <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="codigoComprobanteModificar"
                  name="codigoComprobanteModificar"
                  value={formik.values.codigoComprobanteModificar}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                /> */}
                    {/* <label
                  htmlFor="codigoComprobanteModificar"
                  className={StyleLabelForm}
                >
                  Codigo Doc. Sust.
                </label> */}
                  </div>

                  <div className=" relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Número Doc. Sust.: *{" "}
                    </label>
                    <NumberFormat
                      format="###-###-#########"
                      id="numeroComprobanteModificar"
                      name="numeroComprobanteModificar"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.numeroComprobanteModificar}
                      className={IsError ? StyleInputError : StyleInput}
                    />
                    {/* <label
                  htmlFor="numeroComprobanteModificar"
                  className={StyleLabelForm}
                >
                  Número Doc. Sust.
                </label> */}
                  </div>

                  <div className="relative z-0 w-full group">
                    <label
                      htmlFor="company-website"
                      className="block text-sm font-medium text-black dark:text-white text-left"
                    >
                      {" "}
                      Fecha emisión doc. sust.:{" "}
                    </label>
                    <input
                      type="date"
                      className={StyleInput}
                      name="fechaEmisionComprobanteModificar"
                      id="fechaEmisionComprobanteModificar"
                      min={getNoventaDiasAtras()}
                      value={formik.values.fechaEmisionComprobanteModificar}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder=" "
                    />
                    {/* <label
              htmlFor="fechaEmisionComprobanteModificar"
              className={StyleLabelForm}
            >
              Fecha Emisión Doc. Sust.
            </label> */}
                  </div>
                </div>{" "}
              </div>
            </>
          )}
        </div>
      </div>

      <div className=" lg:p-3 md:p-3 rounded-t-lg mt-2 px-2 md:mb-0 mb-3   bg-white dark:bg-gray-900">
        <Contribuyente
          IsShowConsumFinal={true}
          Totales={Totales}
          formik={formik}
        />
      </div>
    </form>
  );
};

export default FormDoc;
