import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post } from "../../../services";
import {
  getFechaInicio,
  getFechaV1,
  rellenarCeros,
  roundToTwo,
} from "../../../services/Utilitario";
import {
  CalcularSubTotales,
  TotalInicial,
} from "../../../features/bl/DocumentSharedBL";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import useTotales from "../../../features/hooks/useTotales";
import { CalcularImpuestosList } from "../EmitirFactura/services/impuestos.js";
import { toast } from "react-toastify";
import moment from "moment";
import { useHours } from "../../../features/hooks/useHours.jsx";
import { TextMessageCom } from "../../../features/components/TextMessageCom.jsx";

export const EmitirDocContext = createContext();

const EmitirDocProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);
  const [saveData, setSaveData] = useState(false);
  const [saveDataMessage, setSaveDataMessage] = useState("");
  const [saveDataSvg, setSaveDataSvg] = useState(0);
  const [DetalleList, setDetalleList] = useState([]);
  const [saveEstado, setsaveEstado] = useState(false);
  //const [Totales, setTotales] = useState({ ...TotalInicial });
  const { Totales } = useTotales(DetalleList);
  const [DestinatariosList, setDestinatarioList] = useState([]);
  const [DataFromAdd, setDataFromAdd] = useState([]);

  const [ValorTotal, setValorTotal] = useState(1);
  const [ObjSaveData, setObjSaveData] = useState({});

  const [ActivarModalDetalle, setActivarModalDetalle] = useState(false);
  const [DetalleItem, setDetalleItem] = useState([]);

  const { ErrorToast } = useGlobalToastify();

  const formik = useFormik({
    initialValues: {
      establecimiento: "",
      puntoEmision: "",
      secuencial: "",
      fechaEmision: getFechaInicio(),
      codigoComprobanteModificar: "01",
      numeroComprobanteModificar: "",
      motivo: "",
      fechaEmisionComprobanteModificar: getFechaInicio(),
      clienteIdentificacion: "",
      clienteRazonSocial: "",
      clienteDireccion: "",
      clienteEmail: "",
      clienteTelefono: "",
      fechaEmisionFactura: new Date().toLocaleDateString(),
    },
    validationSchema: Yup.object({
      /*establecimiento: Yup.string()
                .max(3, 'Tamaño del campo 3'),
            puntoEmision: Yup.string()
                .max(3, 'Tamaño del campo 3'),*/
      secuencial: Yup.string().max(9, "Tamaño del campo 9"),
      clienteIdentificacion: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteRazonSocial: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteDireccion: Yup.string()
        .required("Este campo es requerido")
        .max(300),
      clienteEmail: Yup.string().required("Este campo es requerido").max(300),
      clienteTelefono: Yup.string().max(20),
      motivo: Yup.string().max(300),
    }),
    onSubmit: async (obj) => {
      if (formik.values.numeroComprobanteModificar.trim().length < 1) {
        toast.error(
          "El campo Secuencial Factura es Requerido, por favor dirijase a la sección 'Más opciones' y complete el campo"
        );
        formik.setErrors({
          numeroComprobanteModificar:
            "El campo Secuencial Factura es Requerido, por favor dirijase a la sección 'Más opciones' y complete el campo ",
        });
        return;
      }
      if (formik.values.numeroComprobanteModificar.trim().length !== 17) {
        toast.error("El campo Secuencial Factura debe contener 15 caracteres");
        return;
      }
      if (formik.values.motivo.length < 1) {
        toast.error(
          "El campo Motivo de comprobante es requerido,por favor dirijase a la sección 'Más opciones' y complete el campo"
        );
        formik.setErrors({
          motivo: "El campo Motivo de comprobante es requerido",
        });
        return;
      }
      const fechaEmision = moment(formik.values.fechaEmision, "YYYY-MM-DD");
      const fechaLimite = moment().subtract(3, "months");
      if (fechaEmision.isAfter(fechaLimite)) {
        console.log(
          "La fecha de emisión es válida y está dentro de los últimos 3 meses."
        );
      } else {
        toast.error(
          "La fecha de emisión no es válida, no está dentro de los últimos 3 meses."
        );

        throw new Error("Error de validacion");
      }

      if (formik.values.fechaEmision > moment().format("YYYY-MM-DD")) {
        toast.error("La fecha de inicio no puede ser mayor a la fecha actual");
        return;
      }
      setSaveDataSvg(0);
      if (isOver72Hours) {
        setSaveDataMessage(<TextMessageCom />);
      } else {
        setSaveDataMessage("¿ESTÁ SEGURO DE QUE DESEA ENVIAR AL SRI?");
      }
      setSaveData(true);
      //console.log('---------------------')
      //console.log(obj)
      setObjSaveData(obj);
    },
  });

  const [isOpenModalHours, setisOpenModalHours] = useState(false);

  const toggleModal = () => {
    setisOpenModalHours(!isOpenModalHours);
  };

  const { isOver72Hours } = useHours({
    fechaActual: getFechaInicio(),
    FechaInput: formik.values.fechaEmision,
    toggleModal,
  });

  const guardarDocumento = async () => {
    setSaveDataSvg(1);
    setSaveDataMessage("PROCESANDO...");

    if (DetalleList.length > 0) {
      try {
        // console.log(ObjSaveData);
        if (DetalleList.length > 0) {
          const documento = {
            IdentificacionContribuyente: ObjSaveData.clienteIdentificacion,
            RazonSocialContribuyente: ObjSaveData.clienteRazonSocial,
            correoContribuyente: ObjSaveData.clienteEmail
              .replace(/,/g, ";")
              .replace(/\s+/g, ""),
            Direccion: ObjSaveData.clienteDireccion,
            TelefonoCelular: ObjSaveData.clienteTelefono,
            CodigoComprobanteModificar: ObjSaveData.codigoComprobanteModificar,
            NumeroComprobanteModificar:
              ObjSaveData.numeroComprobanteModificar.replace(/\s+/g, ""),
            Motivo: ObjSaveData.motivo,
            FechaEmisionComprobanteModificar: getFechaV1(
              ObjSaveData.fechaEmisionComprobanteModificar
            ),
            Ambiente: process.env.REACT_APP_ENVIRONMENT,
            IdEmpresa: empresa.idEmpresa,
            Emisor: empresa.emisor,
            RucEmpresa: empresa.ruc,
            CodDoc: 4,
            FechaEmision: getFechaV1(ObjSaveData.fechaEmision),
            ImporteTotal: Totales.importeTotal,
            TotalSinImpuestos: Totales.totalSinImpuesto,
            TotalDescuento: Totales.totalDescuentos,

            ListaImpuestoNC: await CalcularImpuestosList(
              DetalleList,
              empresa,
              Totales
            ),

            InformacionAdicionalCredito: InformacionAdicionalList.map((x) => {
              return {
                Descripcion: x.nombre,
                Valor: x.valor,
              };
            }),
            DetalleNotaCredito: DetalleList.map((e) => {
              return {
                Descripcion: e.descripcion,
                Cantidad: e.cantidad,
                CodigoPrincipal: e.codigoPrincipal,
                CodigoAuxiliar:
                  e.codigoAuxiliar === "" ? null : e.codigoAuxiliar,
                PrecioUnitario: e.precioUnitario,
                Descuento: e.descuento !== "" ? e.descuento : 0,
                ImpuestoCodigoIva: 2,
                ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
                PrecioTotalSinImpuesto: roundToTwo(e.subTotal),
                ImpuestoBaseImponibleIva: roundToTwo(e.subTotal),
                ImpuestoValorIva:
                  e.idImpuestoIvaNavigation.porcentaje === 0
                    ? 0
                    : roundToTwo(e.iva),
                Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
                DetalleServicioNotaCredito:
                  e.servicioDetalle === undefined
                    ? []
                    : e.servicioDetalle === null
                    ? []
                    : e.servicioDetalle.map((x) => {
                        return {
                          Valor: x.nombre,
                          Descripcion: x.valor,
                        };
                      }),
              };
            }),
            IdImpuestoNoObjectoImpuesto: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "8E7CF164-C66B-4312-945F-57AE08C86A33"
            )
              ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
              : null,
            IdImpuestoExentoIva: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "F03C7F10-2773-4505-ACCB-B2FA88D62049"
            )
              ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
              : null,
            IdImpuestoIVA12: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "A5B8F607-8D38-4150-97AF-C591F83678D8"
            )
              ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
              : null,
            IdImpuestoIVA0: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "81BAF871-E113-48CF-8AA8-670CB43494B6"
            )
              ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
              : null,

            IdImpuestoIVA15: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "BED68281-CF9C-458E-9E0F-517A8FB02528"
            )
              ? "BED68281-CF9C-458E-9E0F-517A8FB02528"
              : null,

            IdImpuestoIVA5: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "2ED52B34-BA6C-475B-A82D-0F7C933412A7"
            )
              ? "2ED52B34-BA6C-475B-A82D-0F7C933412A7"
              : null,
            //IdImpuestoIVA14= 8DCD2CFA-5F9D-4FB7-B27A-87447D6CDDE7
            TotalTotalIva0: 0,
            TotalTotalIva: roundToTwo(Totales.totalIva),
            TotalNoObjetoImpuesto: 0,
            TotalExentoIva: 0,
            TotalBaseIva0: Totales.subTotalIva0,
            TotalBaseIva: roundToTwo(Totales.subTotalIva),
            TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
            TotalBaseExentoIva: Totales.subTotalExentoIva,
            Establecimiento: ObjSaveData.establecimiento,
            PuntoEmision: ObjSaveData.puntoEmision,
          };

          const secuencial222 = ObjSaveData.numeroComprobanteModificar.replace(
            /\s+/g,
            ""
          );
          if (secuencial222.length !== 17) {
            ErrorToast(
              "El campo 'Secuencial Factura*' no coincide con el patron requerido"
            );
            throw new Error("Error de validacion");
          }
          const cadenaCorreosModificada = ObjSaveData.clienteEmail
            .replace(/,/g, ";")
            .replace(/\s+/g, "");

          if (
            cadenaCorreosModificada !== "" &&
            !/^([^\s@]+@[^\s@]+\.[^\s@]+)(;[^\s@]+@[^\s@]+\.[^\s@]+)*$/.test(
              cadenaCorreosModificada
            )
          ) {
            ErrorToast("El campo 'Correo' no coincide con el patron requerido");
            throw new Error("Error de validacion");
          }

          if (ObjSaveData.clienteEmail !== null)
            if (ObjSaveData.clienteEmail.trim() !== "")
              documento.InformacionAdicionalCredito.push({
                Valor: ObjSaveData.clienteEmail
                  .replace(/,/g, ";")
                  .replace(/\s+/g, ""),
                Descripcion: "email",
              });

          // console.log(JSON.stringify(documento));

          const Establecimiento = ObjSaveData.establecimiento;
          const PuntoEmision = ObjSaveData.puntoEmision;
          const Secuencial = ObjSaveData.secuencial;
          await post("/api/NotaCredito/Guardar", documento);
          formik.resetForm();
          formik.setFieldValue("establecimiento", Establecimiento);
          formik.setFieldValue("puntoEmision", PuntoEmision);
          formik.setFieldValue("secuencial", rellenarCeros(Secuencial, 9));
          //setTotales({ ...TotalInicial });
          setDetalleList([]);
          setInformacionAdicionalList([]);
          setSaveDataSvg(2);
          setSaveDataMessage("EL DOCUMENTO SE GENERO EXITOSAMENTE");
        }
      } catch (error) {
        // console.log(error);
        setSaveDataSvg(-1);
        setSaveDataMessage("ERROR AL GENERAR EL DOCUMENTO");
      }
    } else {
      setSaveDataSvg(-1);
    }
  };

  useEffect(() => {
    let Totales = CalcularSubTotales(DetalleList);
    //setTotales(Totales);
    setValorTotal(Totales.ValorTotal);
  }, [DetalleList]);

  const resetFormNc = () => {
    let oldPuntoEmision = formik.values.puntoEmision;
    let oldEstablecimiento = formik.values.establecimiento;
    //console.log(formik.values);
    // console.log(Number(formik.values.secuencial));
    formik.resetForm();
    formik.setFieldValue("secuencial", Number(formik.values.secuencial) + 1);
    formik.setFieldValue("puntoEmision", oldPuntoEmision);
    formik.setFieldValue("establecimiento", oldEstablecimiento);
    //setTotales({ ...TotalInicial });
    setDetalleList([]);
  };

  return (
    <EmitirDocContext.Provider
      value={{
        isOpenModalHours,
        setisOpenModalHours,
        toggleModal,
        DetalleList,
        setDetalleList,
        resetFormNc,
        saveData,
        setSaveData,
        saveDataMessage,
        setSaveDataMessage,
        saveDataSvg,
        setSaveDataSvg,
        guardarDocumento,
        formik,
        setsaveEstado,
        saveEstado,
        Totales,
        InformacionAdicionalList,
        setInformacionAdicionalList,
        ActivarModalDetalle,
        setActivarModalDetalle,
        DetalleItem,
        setDetalleItem,
        ValorTotal,
        TotalInicial,
        DestinatariosList,
        DataFromAdd,
        //setTotales,
      }}
    >
      {props.children}
    </EmitirDocContext.Provider>
  );
};

export default EmitirDocProvider;
