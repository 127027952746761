import { Menu } from "@headlessui/react";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const CargaMasiva = (props) => {
  const [isPagesMenuOpen, setIsPagesMenuOpen] = useState(false);

  const [isPagesMenuOpen2, setIsPagesMenuOpen2] = useState(false);
  let activeClassName =
    "  w-full inline-block px-12 bg-[#5F7F93] truncate text-white py-3 transition-colors duration-300 dark:hover:text-gray-200";


  const menus = (codigo, index) => {
    switch (codigo) {
      case "34":
        return (
          <NavLink
            key={codigo}
            title="Factura"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white  truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/carga/generar/factura"
          >
            Factura
          </NavLink>
        );
      case "35":
        return (
          <NavLink
            key={codigo}
            title="Retención"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white  truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/carga/generar/retencion"
          >
            Retención
          </NavLink>

        );
      default:
        return null;
    }
  };

  const hanldeclick2 = () => {
    setIsPagesMenuOpen2(!isPagesMenuOpen2);
  };


  return (
    <ul
      className="pt-2 text-white"
      title={!props.OpenMenu ? "Emisión Masiva" : ""}
    >
      {props.OpenMenu ? (
        <Menu>
          <Menu.Button
            className={"w-full"}
            onClick={props.OpenMenu ? () => props.toggleItem("item13") : <></>}
          >
            <li className=" text-sm flex justify-between items-center  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <div className="flex gap-x-4 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-6 h-6"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <path d="M12 11v6" />
                  <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
                </svg>

                <span
                  className={`${!props.OpenMenu && "hidden"
                    } origin-left duration-200`}
                >
                  {props.modulo}
                </span>
              </div>

              <span
                className={`${!props.OpenMenu && "hidden"
                  } origin-left duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-4 h-4 ${isPagesMenuOpen
                    ? "rotate-180 duration-300"
                    : "rotate-0 duration-300"
                    }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>


              </span>
            </li>
          </Menu.Button>
        </Menu>
      ) : (
        <Menu>
          <Menu.Button className={"w-full lg:table-cell hidden"}>
            <li className="  text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-6 h-6"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                <path d="M12 11v6" />
                <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
              </svg>

              <span
                className={`${!props.OpenMenu && "hidden"
                  } origin-left duration-200`}
              >
                {props.modulo}
              </span>
              <span
                className={`${!props.OpenMenu && "hidden"
                  } origin-left duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={1.5}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className={`w-4 h-4 ${isPagesMenuOpen
                    ? "rotate-180 duration-300"
                    : "rotate-0 duration-300"
                    }`}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                  <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                  <path d="M12 11v6" />
                  <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
                </svg>

              </span>
            </li>
          </Menu.Button>

        </Menu>
      )}

      {props.openMobil ? (
        <ul className="" onClick={hanldeclick2}>
          <li className=" text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-6 h-6"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M14 3v4a1 1 0 0 0 1 1h4" />
              <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
              <path d="M12 11v6" />
              <path d="M9.5 13.5l2.5 -2.5l2.5 2.5" />
            </svg>

            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              {props.modulo}
            </span>
            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`w-4 h-4 ${isPagesMenuOpen2
                  ? "rotate-180 duration-300"
                  : "rotate-0 duration-300"
                  }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </li>
        </ul>
      ) : null}

      {props.openItem === "item13" && (
        <ul
          className={` mt-2 bg-[#003B5B] w-full    overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900 ${!props.OpenMenu && "hidden"
            }`}
          aria-label="submenu"
        >
          {props.items.map((item, index) => menus(item.codigo, index))}

        </ul>
      )}

      {isPagesMenuOpen2 ? (
        <ul
          className={`p-2 mt-2 bg-[#003B5B] space-y-2 overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900
          `}
          aria-label="submenu"
        >
          {props.items.map((item, index) => menus(item.codigo, index))}
        </ul>
      ) : null}
    </ul>
  );
};

export default CargaMasiva;
