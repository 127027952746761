import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Agregar,
  Editar,
  EliminarBodega,
  Obtener,
  SubirExcel,
} from "../../../../../services/InventarioService";

export const BodegaContext = createContext();

export const BodegaProvider = ({ children }) => {
  const empresa = useSelector((state) => state.empresa.empresa);
  const [isLoading, setIsLoading] = useState(false);
  const [bodegas, setBodegas] = useState([]);
  const [infoPaginacion, setInfoPaginacion] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [parametroPaginacion, setParametroPaginacion] = useState({
    pageNumber: 1,
    pageSize: 50,
  });

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      getBodegas();
    }
  }, [parametroPaginacion, empresa]);

  const getBodegas = async () => {
    setIsLoading(true);
    try {
      const response = await Obtener(
        "/api/bodega/obtener-bodegas",
        empresa.idEmpresa,
        parametroPaginacion.pageSize,
        parametroPaginacion.pageNumber
      );
      setBodegas(response.data.data);
      setInfoPaginacion({
        totalPages: response.data.totalPages,
        totalRecords: response.data.totalRecords,
      });
    } catch (err) {
      throw new Error(
        err,
        "No se pudo obtener la información de las bodegas. Inténtelo de nuevo más tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addBodega = async (newBodega) => {
    const res = await Agregar("/api/bodega/crear-bodega", newBodega);
    if (res.code === 200) {
      await getBodegas();
    } else {
      throw new Error("No se pudo guardar la bodega");
    }
  };

  const deleteBodega = async (idBodega) => {
    const res = await EliminarBodega("/api/bodega/eliminar-bodega", idBodega);
    if (res.code === 200) {
      await getBodegas();
    } else {
      throw new Error("No se pudo eliminar la bodega");
    }
  };

  const updateBodega = async (newBodega) => {
    const res = await Editar("/api/bodega/editar-bodega", newBodega);
    if (res.code === 200) {
      await getBodegas();
    } else {
      throw new Error("No se pudo editar la bodega");
    }
  };

  const uploadExcelBodegas = async (bodegas) => {
    const res = await SubirExcel("/api/bodega/crear-bodegas-excel", bodegas);
    if (res.code === 200) {
    } else {
      throw new Error("No se pudo subir las bodegas");
    }
  };

  return (
    <BodegaContext.Provider
      value={{
        bodegas,
        addBodega,
        deleteBodega,
        updateBodega,
        uploadExcelBodegas,
        isLoading,
        infoPaginacion,
        setParametroPaginacion,
        parametroPaginacion,
        getBodegas,
      }}
    >
      {children}
    </BodegaContext.Provider>
  );
};
