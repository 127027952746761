import { TransaccionesProvider } from "./context/TransaccionesContext";
import { TransaccionesEmitidas } from "./TransaccionesEmitidas";

export const IndexTransacciones = () => {
  return (
    <TransaccionesProvider>
      <TransaccionesEmitidas />
    </TransaccionesProvider>
  );
};
