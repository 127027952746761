import { useContext, useEffect, useState } from "react";
import { TransaccionesContext } from "./context/TransaccionesContext";
import { Switch } from "@headlessui/react";
import {
  TableStyle,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../../features/Constantes";
import { ModalBase } from "../../Shared/ModalBase";

export const TransaccionInfoModal = () => {
  const { selectedTransaccion, setSelectedTransaccion } =
    useContext(TransaccionesContext);

  const [showedInfo, setShowedInfo] = useState("General");
  const [viewLote, setViewLote] = useState(true);
  const [viewCaducidad, setViewCaducidad] = useState(true);

  const closeModal = () => setSelectedTransaccion(null);

  return (
    <ModalBase onClose={closeModal}>
      <section
        style={{
          padding: "15px",
          position: "relative",
        }}
        className="shadow-xl rounded-lg flex bg-white dark:bg-gray-900 flex-col gap-3 lg:h-[525px] h-fit lg:w-[966px]"
      >
        <button
          type="button"
          onClick={() => setSelectedTransaccion(null)}
          data-bs-dismiss="modal"
          aria-label="Close"
          id={`close`}
          className="shadow absolute -right-5 -top-5 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
        >
          <svg
            className="fill-current w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
          </svg>
        </button>
        <div className="rounded-lg flex flex-col gap-5 w-full">
          <h1 className="font-semibold p-3 pb-1 lg:text-xl dark:text-white text-lg font-[Outfit]">
            Vista Previa de la Transacción | {selectedTransaccion.trasaccion}
          </h1>
          <nav className="flex flex-row gap-3 w-full justify-around items-center">
            <button
              onClick={() => setShowedInfo("General")}
              className={`flex flex-row p-2 m-auto dark:text-white font-semibold ${
                showedInfo === "General" &&
                "text-[#003B5B] dark:text-[#003B5B] border-b-4 border-[#003B5B]"
              } items-center gap-2`}
            >
              <span className="hide-on-small">Información General</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-card-text"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z" />
                  <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8m0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5" />
                </svg>
              </span>
            </button>
            <button
              onClick={() => setShowedInfo("Productos")}
              className={`flex flex-row p-2 m-auto dark:text-white font-semibold ${
                showedInfo === "Productos" &&
                "text-[#003B5B] dark:text-[#003B5B] border-b-4 border-[#003B5B]"
              } items-center gap-2`}
            >
              <span className="hide-on-small">Lista de Productos</span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-table"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 2h-4v3h4zm0 4h-4v3h4zm0 4h-4v3h3a1 1 0 0 0 1-1zm-5 3v-3H6v3zm-5 0v-3H1v2a1 1 0 0 0 1 1zm-4-4h4V8H1zm0-4h4V4H1zm5-3v3h4V4zm4 4H6v3h4z" />
                </svg>
              </span>
            </button>
          </nav>
        </div>
        {showedInfo === "General" && (
          <div className="flex lg:flex-col flex-row h-full gap-3 lg:p-3">
            <section
              className={`w-full ${
                !selectedTransaccion.nombreSeccionDestino
                  ? "infoTransaccion"
                  : "infoTransferencia"
              } gap-4 p-2 text-[14px]`}
            >
              <div>
                <label className="block text-[12px] font-medium text-gray-700 dark:text-white">
                  {" "}
                  Fecha{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      viewBox="0 0 24 24"
                      fill="white"
                      width={"18"}
                      height={"18"}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M3 9H21M7 3V5M17 3V5M6 12H8M11 12H13M16 12H18M6 15H8M11 15H13M16 15H18M6 18H8M11 18H13M16 18H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                          stroke="#d5d6d7"
                          stroke-width="2"
                          stroke-linecap="round"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="fechaCreacion"
                    readOnly
                    className={
                      "'peer pl-2 block w-fit rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                    }
                    value={selectedTransaccion?.fechaCreacion
                      .toString()
                      .replaceAll("T", " ")}
                  />
                </div>
              </div>
              <div>
                <label className="block text-[12px] font-medium text-gray-700 dark:text-white">
                  {" "}
                  Tipo{" "}
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                    <svg
                      fill="#d5d6d7"
                      viewBox="0 0 512 512"
                      enable-background="new 0 0 512 512"
                      version="1.1"
                      width={"18"}
                      height={"18"}
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g id="Layer_1"></g>{" "}
                        <g id="Layer_2">
                          {" "}
                          <g>
                            {" "}
                            <path d="M382.6,82h-39.2c-4.1,0-7.5,3.4-7.5,7.5v14.9c0,3.9-3.2,7.1-7.1,7.1s-7.1-3.2-7.1-7.1V89.5c0-4.1-3.4-7.5-7.5-7.5h-41.4 c-4.1,0-7.5,3.4-7.5,7.5v14.9c0,3.9-3.2,7.1-7.1,7.1h-3.4c-3.9,0-7.1-3.2-7.1-7.1V90.6c0-4.1-3.4-7.5-7.5-7.5h-35.8 c-4.1,0-7.5,3.4-7.5,7.5v13.8c0,3.9-3.2,7.1-7.1,7.1h-4.5c-3.9,0-7.1-3.2-7.1-7.1V90.6c0-4.1-3.4-7.5-7.5-7.5h-41.4 c-4.1,0-7.5,3.4-7.5,7.5v330.9c0,4.1,3.4,7.5,7.5,7.5h41.4c4.1,0,7.5-3.4,7.5-7.5v-13.8c0-3.9,3.2-7.1,7.1-7.1h4.5 c3.9,0,7.1,3.2,7.1,7.1v13.8c0,4.1,3.4,7.5,7.5,7.5h35.8c4.1,0,7.5-3.4,7.5-7.5v-13.8c0-3.9,3.2-7.1,7.1-7.1h3.4 c3.9,0,7.1,3.2,7.1,7.1v14.9c0,4.1,3.4,7.5,7.5,7.5h41.4c4.1,0,7.5-3.4,7.5-7.5v-14.9c0-3.9,3.2-7.1,7.1-7.1s7.1,3.2,7.1,7.1v14.9 c0,4.1,3.4,7.5,7.5,7.5h39.2c4.1,0,7.5-3.4,7.5-7.5V89.5C390.1,85.4,386.7,82,382.6,82z M375.1,415h-24.2v-7.4 c0-12.2-9.9-22.1-22.1-22.1c-12.2,0-22.1,9.9-22.1,22.1v7.4h-26.4v-7.4c0-12.2-9.9-22.1-22.1-22.1h-3.4 c-12.2,0-22.1,9.9-22.1,22.1v6.3H212v-6.3c0-12.2-9.9-22.1-22.1-22.1h-4.5c-12.2,0-22.1,9.9-22.1,22.1v6.3h-26.4V98.1h26.4v6.3 c0,12.2,9.9,22.1,22.1,22.1h4.5c12.2,0,22.1-9.9,22.1-22.1v-6.3h20.8v6.3c0,12.2,9.9,22.1,22.1,22.1h3.4 c12.2,0,22.1-9.9,22.1-22.1V97h26.4v7.4c0,12.2,9.9,22.1,22.1,22.1c12.2,0,22.1-9.9,22.1-22.1V97h24.2V415z"></path>{" "}
                            <path d="M282.1,161.5H167.9c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h114.3c4.1,0,7.5-3.4,7.5-7.5S286.3,161.5,282.1,161.5z"></path>{" "}
                            <path d="M344.1,161.5h-24.6c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h24.6c4.1,0,7.5-3.4,7.5-7.5S348.3,161.5,344.1,161.5z"></path>{" "}
                            <path d="M282.1,209.3H167.9c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h114.3c4.1,0,7.5-3.4,7.5-7.5S286.3,209.3,282.1,209.3z"></path>{" "}
                            <path d="M319.5,224.3h24.6c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5h-24.6c-4.1,0-7.5,3.4-7.5,7.5S315.3,224.3,319.5,224.3z"></path>{" "}
                            <path d="M282.1,257.1H167.9c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h114.3c4.1,0,7.5-3.4,7.5-7.5S286.3,257.1,282.1,257.1z"></path>{" "}
                            <path d="M319.5,272.1h24.6c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5h-24.6c-4.1,0-7.5,3.4-7.5,7.5S315.3,272.1,319.5,272.1z"></path>{" "}
                            <path d="M333.7,308.3c7.7,0,12.7,2.5,15.5,3.7l3.1-12.2c-3.6-1.7-8.4-3.2-15.6-3.5v-9.5h-10.5V297c-11.5,2.3-18.2,9.7-18.2,19.1 c0,10.4,7.9,15.8,19.4,19.7c8,2.7,11.4,5.3,11.4,9.4c0,4.3-4.2,6.7-10.3,6.7c-7,0-13.3-2.3-17.9-4.7l-3.2,12.6 c4.1,2.4,11.1,4.3,18.3,4.6v10.2h10.5v-11c12.4-2.2,19.1-10.3,19.1-19.9c0-9.7-5.2-15.6-18-20.1c-9.1-3.4-12.9-5.7-12.9-9.2 C324.5,311.3,326.7,308.3,333.7,308.3z"></path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </span>
                  <input
                    type="text"
                    id="tipo"
                    readOnly
                    className={
                      "'peer pl-2 block w-fit rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                    }
                    value={
                      selectedTransaccion.tipoTransaccion.slice(0, 1) +
                      selectedTransaccion.tipoTransaccion.slice(1).toLowerCase()
                    }
                  />
                </div>
              </div>
              {!selectedTransaccion.nombreSeccionDestino ? (
                <>
                  <div>
                    <label className="block text-[12px] font-medium text-gray-700 dark:text-white">
                      {" "}
                      Bodega{" "}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          viewBox="0 0 16 16"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="#d5d6d7"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill=""
                              d="M16 4l-8.060-4-7.94 4v1h1v11h2v-9h10v9h2v-11h1v-1zM4 6v-1h2v1h-2zM7 6v-1h2v1h-2zM10 6v-1h2v1h-2z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M6 9h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M6 13h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M10 13h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                          </g>
                        </svg>
                      </span>
                      <input
                        type="text"
                        id="bodega"
                        readOnly
                        className={
                          "'peer pl-2 block w-fit rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                        }
                        value={selectedTransaccion.nombreBodegaOrigen}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-[12px] font-medium text-gray-700 dark:text-white">
                      {" "}
                      Sección{" "}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          fill="#d5d6d7"
                          height="18"
                          width="18"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          stroke="#ffffff"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <path d="M469.333,0h-192H42.667C30.885,0,21.333,9.551,21.333,21.333v128v213.333V448v42.667c0,11.782,9.551,21.333,21.333,21.333 C54.449,512,64,502.449,64,490.667v-21.333h213.333H448v21.333c0,11.782,9.551,21.333,21.333,21.333 c11.782,0,21.333-9.551,21.333-21.333V448V341.333V234.667V128V21.333C490.667,9.551,481.115,0,469.333,0z M64,170.667h42.667V192 c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333v-21.333h21.333V192 c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333v-21.333H256v64v106.667H64V170.667z M448,213.333H298.667v-64 H448V213.333z M298.667,256H448v64H298.667V256z M448,106.667H298.667v-64H448V106.667z M64,42.667h192V128h-42.667v-21.333 c0-11.782-9.551-21.333-21.333-21.333s-21.333,9.551-21.333,21.333V128h-21.333v-21.333c0-11.782-9.551-21.333-21.333-21.333 c-11.782,0-21.333,9.551-21.333,21.333V128H64V42.667z M64,384h192v42.667H64V384z M298.667,362.667H448v64H298.667V362.667z"></path>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </span>
                      <input
                        type="text"
                        id="seccion"
                        readOnly
                        className={
                          "'peer pl-2 block w-fit rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                        }
                        value={selectedTransaccion.nombreSeccionOrigen}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <label className="block text-[12px] font-medium text-gray-700 dark:text-white">
                      {" "}
                      Bodega Origen{" "}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          viewBox="0 0 16 16"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="#d5d6d7"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill=""
                              d="M16 4l-8.060-4-7.94 4v1h1v11h2v-9h10v9h2v-11h1v-1zM4 6v-1h2v1h-2zM7 6v-1h2v1h-2zM10 6v-1h2v1h-2z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M6 9h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M6 13h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M10 13h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                          </g>
                        </svg>
                      </span>
                      <input
                        type="text"
                        id="bodega"
                        readOnly
                        className={
                          "'peer pl-2 block w-fit rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                        }
                        value={selectedTransaccion.nombreBodegaOrigen}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-[12px] font-medium text-gray-700 dark:text-white">
                      {" "}
                      Sección Origen{" "}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          fill="#d5d6d7"
                          height="18"
                          width="18"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          stroke="#ffffff"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <path d="M469.333,0h-192H42.667C30.885,0,21.333,9.551,21.333,21.333v128v213.333V448v42.667c0,11.782,9.551,21.333,21.333,21.333 C54.449,512,64,502.449,64,490.667v-21.333h213.333H448v21.333c0,11.782,9.551,21.333,21.333,21.333 c11.782,0,21.333-9.551,21.333-21.333V448V341.333V234.667V128V21.333C490.667,9.551,481.115,0,469.333,0z M64,170.667h42.667V192 c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333v-21.333h21.333V192 c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333v-21.333H256v64v106.667H64V170.667z M448,213.333H298.667v-64 H448V213.333z M298.667,256H448v64H298.667V256z M448,106.667H298.667v-64H448V106.667z M64,42.667h192V128h-42.667v-21.333 c0-11.782-9.551-21.333-21.333-21.333s-21.333,9.551-21.333,21.333V128h-21.333v-21.333c0-11.782-9.551-21.333-21.333-21.333 c-11.782,0-21.333,9.551-21.333,21.333V128H64V42.667z M64,384h192v42.667H64V384z M298.667,362.667H448v64H298.667V362.667z"></path>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </span>
                      <input
                        type="text"
                        id="seccion"
                        readOnly
                        className={
                          "'peer pl-2 block w-fit rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                        }
                        value={selectedTransaccion.nombreSeccionOrigen}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-[12px] font-medium text-gray-700 dark:text-white">
                      {" "}
                      Bodega Destino{" "}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          viewBox="0 0 16 16"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="#d5d6d7"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <path
                              fill=""
                              d="M16 4l-8.060-4-7.94 4v1h1v11h2v-9h10v9h2v-11h1v-1zM4 6v-1h2v1h-2zM7 6v-1h2v1h-2zM10 6v-1h2v1h-2z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M6 9h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M6 13h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                            <path
                              fill="#d5d6d7"
                              d="M10 13h-1v-1h-1v3h3v-3h-1v1z"
                            ></path>{" "}
                          </g>
                        </svg>
                      </span>
                      <input
                        type="text"
                        id="bodega"
                        readOnly
                        className={
                          "'peer pl-2 block w-fit rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                        }
                        value={selectedTransaccion.nombreBodegaDestino}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-[12px] font-medium text-gray-700 dark:text-white">
                      {" "}
                      Sección Destino{" "}
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                        <svg
                          fill="#d5d6d7"
                          height="18"
                          width="18"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          stroke="#ffffff"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <path d="M469.333,0h-192H42.667C30.885,0,21.333,9.551,21.333,21.333v128v213.333V448v42.667c0,11.782,9.551,21.333,21.333,21.333 C54.449,512,64,502.449,64,490.667v-21.333h213.333H448v21.333c0,11.782,9.551,21.333,21.333,21.333 c11.782,0,21.333-9.551,21.333-21.333V448V341.333V234.667V128V21.333C490.667,9.551,481.115,0,469.333,0z M64,170.667h42.667V192 c0,11.782,9.551,21.333,21.333,21.333c11.782,0,21.333-9.551,21.333-21.333v-21.333h21.333V192 c0,11.782,9.551,21.333,21.333,21.333s21.333-9.551,21.333-21.333v-21.333H256v64v106.667H64V170.667z M448,213.333H298.667v-64 H448V213.333z M298.667,256H448v64H298.667V256z M448,106.667H298.667v-64H448V106.667z M64,42.667h192V128h-42.667v-21.333 c0-11.782-9.551-21.333-21.333-21.333s-21.333,9.551-21.333,21.333V128h-21.333v-21.333c0-11.782-9.551-21.333-21.333-21.333 c-11.782,0-21.333,9.551-21.333,21.333V128H64V42.667z M64,384h192v42.667H64V384z M298.667,362.667H448v64H298.667V362.667z"></path>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </span>
                      <input
                        type="text"
                        id="seccion"
                        readOnly
                        className={
                          "'peer pl-2 block w-fit rounded-none form-control border py-1.5 rounded-r-md sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                        }
                        value={selectedTransaccion.nombreSeccionDestino}
                      />
                    </div>
                  </div>
                </>
              )}
            </section>
            <div className="flex flex-1  flex-col text-[14px]">
              <label className="inline-flex items-center py-2 gap-2 dark:bg-gray-700 dark:border-gray-600  px-3 rounded-t-md border  border-gray-300 bg-gray-50 text-gray-500 text-sm">
                <span>
                  <svg
                    fill="#d5d6d7"
                    viewBox="0 0 36 36"
                    version="1.1"
                    width={"18"}
                    height={"18"}
                    stroke="#d5d6d7"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>details-line</title>{" "}
                      <path
                        d="M32,6H4A2,2,0,0,0,2,8V28a2,2,0,0,0,2,2H32a2,2,0,0,0,2-2V8A2,2,0,0,0,32,6Zm0,22H4V8H32Z"
                        class="clr-i-outline clr-i-outline-path-1"
                      ></path>
                      <path
                        d="M9,14H27a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
                        class="clr-i-outline clr-i-outline-path-2"
                      ></path>
                      <path
                        d="M9,18H27a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
                        class="clr-i-outline clr-i-outline-path-3"
                      ></path>
                      <path
                        d="M9,22H19a1,1,0,0,0,0-2H9a1,1,0,0,0,0,2Z"
                        class="clr-i-outline clr-i-outline-path-4"
                      ></path>{" "}
                      <rect
                        x="0"
                        y="0"
                        width="36"
                        height="36"
                        fillOpacity="0"
                      ></rect>{" "}
                    </g>
                  </svg>
                </span>{" "}
                <span className="text-black dark:text-white">Detalle </span>
              </label>
              <textarea
                name="detalle"
                readOnly
                id="detalle"
                className="pl-2 w-full h-full resize-none form-control border py-1.5 rounded-b-lg sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-gray-300 focus:outline-none focus:border-blue-500 focus:ring-blue-500 focus:ring-1 '"
                value={selectedTransaccion.detalle}
              ></textarea>
            </div>
          </div>
        )}
        {showedInfo === "Productos" && (
          <div className="flex flex-col gap-6 justify-between flex-1 p-2 ">
            <div className={"flex-1 overflow-auto whitespace-nowrap"}>
              <div className="h-[220px]">
                <table className={TableStyle}>
                  <thead
                    className={
                      TableStyleTHead + " bg-gray-200 text-sm divide-x"
                    }
                  >
                    {selectedTransaccion.tipoTransaccion ===
                    "AJUSTE DE INVENTARIO" ? (
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Codigo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Nombre
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600 "
                        >
                          Stock Previo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600 "
                        >
                          Ajuste
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600 "
                        >
                          Costo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600 "
                        >
                          Nuevo Stock
                        </th>
                      </tr>
                    ) : (
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Codigo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Nombre
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Cantidad
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600 "
                        >
                          Costo
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600 "
                        >
                          Total
                        </th>

                        {viewLote && (
                          <th
                            scope="col"
                            className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                          >
                            Lote
                          </th>
                        )}

                        {viewCaducidad && (
                          <th
                            scope="col"
                            className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                          >
                            Fecha de Caducidad
                          </th>
                        )}
                      </tr>
                    )}
                  </thead>
                  <tbody className={TableStyleTBody}>
                    {selectedTransaccion.tipoTransaccion ===
                    "AJUSTE DE INVENTARIO"
                      ? selectedTransaccion.productos.map((row, index) => (
                          <tr key={index} className="text-center">
                            <td className="p-2 relative border-2 border-gray-300 dark:text-white dark:border-gray-600">
                              {row.codigo}
                            </td>
                            <td className="border-2 border-gray-300 dark:text-white dark:border-gray-600">
                              {row.nombre}
                            </td>
                            <td
                              className={`border-2 border-gray-300 dark:text-white dark:border-gray-600`}
                            >
                              {row.cantidad - row.ajuste}
                            </td>
                            <td
                              className={`border-2 border-gray-300 dark:text-white dark:border-gray-600`}
                            >
                              {row.ajuste}
                            </td>
                            <td
                              className={`border-2 border-gray-300 dark:text-white dark:border-gray-600`}
                            >
                              $
                              {Number(row.costoDecimal).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                            <td
                              className={`border-2 border-gray-300 dark:text-white dark:border-gray-600`}
                            >
                              {row.cantidad}
                            </td>
                          </tr>
                        ))
                      : selectedTransaccion.productos.map((row, index) => (
                          <tr key={index} className="text-center">
                            <td className="p-2 relative border-2 border-gray-300 dark:text-white dark:border-gray-600">
                              {row.codigo}
                            </td>
                            <td className="border-2 border-gray-300 dark:text-white dark:border-gray-600">
                              {row.nombre}
                            </td>
                            <td
                              className={`border-2 border-gray-300 dark:text-white dark:border-gray-600`}
                            >
                              {row.cantidad}
                            </td>
                            <td className=" border-2 border-gray-300 dark:text-white dark:border-gray-600">
                              $
                              {Number(row.costoDecimal).toLocaleString(
                                undefined,
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </td>
                            <td
                              className={`border-2 border-gray-300 dark:text-white dark:border-gray-600`}
                            >
                              $
                              {Number(
                                row.cantidad * row.costoDecimal
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            {viewLote && (
                              <td
                                className={`border-2 border-gray-300 dark:text-white dark:border-gray-600`}
                              >
                                {row?.lote ? row.lote : "-"}
                              </td>
                            )}
                            {viewCaducidad && (
                              <td
                                className={`border-2 border-gray-300 dark:text-white dark:border-gray-600`}
                              >
                                {row?.fechaCaducidad ? row.fechaCaducidad : "-"}
                              </td>
                            )}
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
            <section
              className={`w-full flex flex-row items-center`}
            >
              {selectedTransaccion.tipoTransaccion !==
                "AJUSTE DE INVENTARIO" && (
                <div className="flex flex-row items-center gap-4 h-8">
                  <div className="flex flex-row items-center gap-3 ">
                    <label
                      htmlFor="company-website"
                      className="block text-sm  font-medium text-gray-700 dark:text-white"
                    >
                      Lote
                    </label>
                    <div className="py-4 ">
                      <Switch
                        checked={viewLote}
                        onChange={() => setViewLote(!viewLote)}
                        className={`${
                          viewLote ? "bg-blue-600" : "bg-gray-400"
                        } relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-9 h-5  border-2 my-auto border-transparent mt-1 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            viewLote ? "translate-x-5" : "translate-x-0"
                          } pointer-events-none inline-block md:h-5 md:w-5 w-4 h-4 transform rounded-full bg-white  shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                    </div>{" "}
                  </div>
                  <div className="flex items-center flex-row gap-3">
                    <label
                      htmlFor="company-website"
                      className="block text-sm whitespace-nowrap font-medium text-gray-700 dark:text-white"
                    >
                      Fecha de Caducidad
                    </label>
                    <div className="py-4 ">
                      <Switch
                        checked={viewCaducidad}
                        onChange={() => setViewCaducidad(!viewCaducidad)}
                        className={`${
                          viewCaducidad ? "bg-blue-600" : "bg-gray-400"
                        } relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-9 h-5  border-2 my-auto border-transparent  mt-1 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            viewCaducidad ? "translate-x-5" : "translate-x-0"
                          } pointer-events-none inline-block md:h-5 md:w-5 w-4 h-4 transform rounded-full bg-white  shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                    </div>{" "}
                  </div>
                </div>
              )}
              {/* <table className="border-none text-xs text-end">
                {selectedTransaccion.tipoTransaccion !==
                "AJUSTE DE INVENTARIO" ? (
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <p>
                            <strong>Total:</strong>
                          </p>
                          <p>
                            <strong>Total artículos:</strong>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p className="ml-3">
                            $
                            {selectedTransaccion.productos
                              .reduce(
                                (sum, s) => sum + s.cantidad * s.costoDecimal,
                                0
                              )
                              .toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </p>
                          <p className="ml-3">
                            {selectedTransaccion.productos.reduce(
                              (sum, s) => sum + s.cantidad,
                              0
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <p>
                            <strong>Total monetario ingresado:</strong>
                          </p>
                          <p>
                            <strong>Total artículos ingresados:</strong>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p className="ml-3">
                            $
                            {selectedTransaccion.productos.filter(s => s.ajuste > 0)
                              .reduce(
                                (sum, s) => sum + s.ajuste * s.costoDecimal,
                                0
                              )
                              .toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </p>
                          <p className="ml-3">
                            {selectedTransaccion.productos.filter(s => s.ajuste > 0).reduce(
                              (sum, s) => sum + s.ajuste,
                              0
                            )}
                          </p>
                        </div>
                      </td>
                      <td >
                        <div>
                          <p className="ml-8">
                            <strong>Total monetario egresado:</strong>
                          </p>
                          <p className="ml-8">
                            <strong>Total artículos egresados:</strong>
                          </p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <p className="ml-3">
                            $
                            {selectedTransaccion.productos.filter(s => s.ajuste < 0)
                              .reduce(
                                (sum, s) => sum + Math.abs(s.ajuste) * s.costoDecimal,
                                0
                              )
                              .toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                          </p>
                          <p className="ml-3">
                            {selectedTransaccion.productos.filter(s => s.ajuste < 0).reduce(
                              (sum, s) => sum + Math.abs(s.ajuste),
                              0
                            )}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table> */}
            </section>
          </div>
        )}
      </section>
    </ModalBase>
  );
};
