import { getNoventaDiasAtras } from "../../../../../services/Utilitario";
import { useEffect, useState } from "react";
import { InputGlobal } from "../../../context/inputContext";
import AutoCompleteInput from "../../../context/AutoCompleteSelect";
import { ObtenerSeccionesTransaccion } from "../../../../../services/InventarioService";
import { useSelector } from "react-redux";

export default function IngresoGeneral() {
  const [listaSecciones, setListaSecciones] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerSeccionesTransaccion(
        "/api/transacciones/obtener-secciones",
        empresa.idEmpresa
      ).then((response) => setListaSecciones(response.data));
    }
  }, [empresa]);

  const searchSecciones = async (inputText) => {
    return listaSecciones.filter(
      (seccion) =>
        String(seccion.nombreBodega)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(seccion.nombreSeccion)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(seccion.establecimiento)
          .padStart(3, "0")
          .toLowerCase()
          .includes(String(inputText).toLowerCase())
    );
  };

  return (
    <>
      <div className="">
        <div
          name="InformeGeneral"
          className="border rounded-lg border-gray-300 dark:border-gray-600"
        >
          <p className="bg-[#003B5B] text-white font-semibold rounded-t-lg p-3">
            Información General
          </p>

          <div name="Formularios" className="formCrearBodega gap-6 p-6">
            <InputGlobal
              name={"tipoOperacion"}
              title={"Tipo de Operación"}
              validations={{ required: true }}
              disabled={true}
            />
            <InputGlobal
              name={"fechaCreacion"}
              readOnly={true}
              title={"Fecha de Ingreso"}
              type={"date"}
              validations={{ required: true, min: getNoventaDiasAtras() }}
            />
            <AutoCompleteInput
              title={"Sección / Ubicación"}
              name={"ubicacion"}
              searchFn={searchSecciones}
              array={listaSecciones}
              parametro={"nombre"}
              identificador={"idSeccion"}
              validations={{ required: true }}
              option={(e) => `${String(e["bodegaSeccion"])}`}
            />
            <InputGlobal
              title={"Detalle / Descripción"}
              name={"detalle"}
              // specificStyle={"col-span-2"}
              type={"text"}
              validations={{ required: true }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
