import { createContext, useEffect, useState } from "react";
import useContribuyenteServices from "../../CargaMasiva/Services/useContribuyenteServices";

export const ConsultaContext = createContext();

export const ConsultaProvider = ({ children }) => {
  const { constribuyenteService } = useContribuyenteServices();

  const [isConsulta, setIsConsulta] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [identificacion, setIdentificacion] = useState("");
  const [Error, setError] = useState(false);
  const [Message, setMessage] = useState(null);
  const [paginacionEstablecimiento, setPaginacionEstablecimiento] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const [infoPaginacionEstablecimiento, setInfoPaginacionEstablecimiento] =
    useState({
      totalRecords: 0,
      totalPages: 0,
    });

  const [IsLoadingPagination, setIsLoadingPagination] = useState(false);

  useEffect(() => {
    const fetchContribuyenteData = async () => {
      if (isConsulta !== null) {
        setIsLoadingPagination(true);
        try {
          const res = await constribuyenteService(
            isConsulta?.ruc,
            paginacionEstablecimiento.pageNumber,
            paginacionEstablecimiento.pageSize
          );
          setIsConsulta(res);
        } catch (error) {
          console.error("Error fetching contribuyente data:", error);
        } finally {
          setIsLoadingPagination(false);
        }
      }
    };

    fetchContribuyenteData();
  }, [paginacionEstablecimiento]);

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      data.identificacionConsulta = identificacion.trim();
      setError(false);
      setMessage(null);

      if (data.identificacionConsulta === "") {
        setError(true);
        setMessage("El Ruc es requerido.");
        return;
      }

      if (!/^\d+$/.test(data.identificacionConsulta)) {
        setError(true);
        setMessage("El Ruc debe contener solo números.");
        return;
      }

      if (data.identificacionConsulta.length !== 13) {
        setError(true);
        setMessage("El Ruc debe contener 13 caracteres.");
        return;
      }

      const res = await constribuyenteService(
        data.identificacionConsulta,
        paginacionEstablecimiento.pageNumber,
        paginacionEstablecimiento.pageSize
      );
      setInfoPaginacionEstablecimiento({
        totalRecords: res.establecimientos.infoPaginacion.totalRecords,
        totalPages: res.establecimientos.infoPaginacion.totalPages,
      });
      setError(false);
      setMessage("Contribuyente Encontrado");
      setTimeout(() => {
        setIsConsulta(res);
      }, 1500);
    } catch (error) {
      setError(true);
      setMessage(error ?? "Ocurrio un error");
    } finally {
      setLoading(false);
    }
  };

  const cleanConsulta = () => {
    setIsConsulta(null);
    setMessage(null);
    setError(false);
    setPaginacionEstablecimiento({
      pageNumber: 1,
      pageSize: 10,
    });
    setInfoPaginacionEstablecimiento({
      totalRecords: 0,
      totalPages: 0,
    });
    setIdentificacion("");
  };

  return (
    <ConsultaContext.Provider
      value={{
        handleSubmit,
        identificacion,
        isConsulta,
        Loading,
        paginacionEstablecimiento,
        IsLoadingPagination,
        infoPaginacionEstablecimiento,
        setPaginacionEstablecimiento,
        Error,
        Message,
        cleanConsulta,
        setIdentificacion,
      }}
    >
      {children}
    </ConsultaContext.Provider>
  );
};
