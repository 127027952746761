const ProgressBar = ({ progress }) => (
  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
    <div
      className="bg-blue-600 transition-all text-xs font-medium text-white text-center p-1 leading-none rounded-full"
      style={{ width: `${progress}%` }}
    >
      {progress === 100 ? 100 : progress.toFixed(2)}%
    </div>
  </div>
);

export default ProgressBar;
