import { ModalButtons } from "../../Shared/ModalButtons";
import { BodegaModalOff } from "./modalCrearBodega.slice";
import { InputGlobal } from "../../context/inputContext";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { BodegaContext } from "./context/BodegasContext";
import { EstablecimientosContext } from "../../../Mantenimiento/Establecimiento/EstablecimientosProvider";
import { AlfaNumerico, SoloLetras } from "../../regex.d";
import AutoCompleteInput from "../../context/AutoCompleteSelect";
import { toast } from "react-toastify";
import { ModalBase } from "../../Shared/ModalBase";

//Modal para la edicción de una Bodega

export const ModalEditarBodega = () => {
  const empresa = useSelector((state) => state.empresa.empresa);
  const { updateBodega } = useContext(BodegaContext);
  const registro = useSelector((state) => state.modalBodega.registroAfectado);
  const { documentos } = useContext(EstablecimientosContext);
  const dispatch = useDispatch();
  const [isSending, setIsSending] = useState(false);

  const methods = useForm({
    defaultValues: {
      codigo: registro.codigo,
      nombreBodega: registro.nombre,
      establecimiento: documentos.find(
        (establecimiento) =>
          establecimiento.idEstablecimiento === registro.idEstablecimiento
      ),
      direccion: registro.direccion,
    },
  });

  useEffect(() => {
    toast.dismiss();
  }, []);

  //Funcion para realizar la ediccion de la Bodega
  const doBodegaEdit = async (data) => {
    const editedBodega = {
      idBodega: registro.idBodega,
      idEmpresa: empresa.idEmpresa,
      idEstablecimiento: data.establecimiento.idEstablecimiento,
      codigo: data.codigo.trimEnd().trimStart(),
      nombre: data.nombreBodega.trimEnd().trimStart(),
      direccion: data.direccion.trimEnd().trimStart(),
      direccionEstablecimiento: data.establecimiento.direccion,
      codigoEstablecimiento: data.establecimiento.codigo,
    };
    try {
      setIsSending(true);
      await toast.promise(updateBodega(editedBodega), {
        pending: "Editando bodega...",
        success: "¡La bodega fue editada con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al editar la bodega."
            );
          },
        },
      });
      dispatch(BodegaModalOff());
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  //Funcion para filtrar establecimientos en el Select con Entrada
  const searchEstablecimiento = async (inputText) => {
    if (documentos.length < 10) {
      return documentos
        .filter((documento) => documento.activo === true)
        .filter((documento) =>
          String(documento.codigo)
            .padStart(3, "0")
            .toLowerCase()
            .includes(String(inputText).toLowerCase())
        );
    } else {
      return documentos
        .filter((documento) => documento.activo === true)
        .filter(
          (documento) =>
            String(documento.codigo)
              .padStart(3, "0")
              .toLowerCase()
              .includes(String(inputText).toLowerCase()) ||
            String(documento.direccion)
              .toLowerCase()
              .includes(String(inputText).toLowerCase())
        );
    }
  };
  return (
    <ModalBase onClose={BodegaModalOff} useRedux={true}>
      <FormProvider {...methods}>
        <section
          style={{
            padding: "20px",
            height: "fit-content",
            position: "relative",
          }}
          className="shadow-xl rounded-lg flex bg-white dark:bg-gray-900 flex-col gap-4 lg:w-[500px] md:w-[450px] w-[375px]"
        >
          <div className="bg-gray-200 dark:bg-gray-700 rounded-lg dark:text-white text-[12px] w-full flex flex-col justify-center p-6">
            <h1 className="font-semibold text-2xl font-[Outfit]">
              Información de Bodega
            </h1>
            <h4>Editar Información</h4>
          </div>
          <form
            onSubmit={methods.handleSubmit(doBodegaEdit)}
            autoComplete="off"
            className="flex flex-col gap-8"
          >
            <div className="flex flex-col gap-4">
              <InputGlobal
                title={"Código"}
                type={"text"}
                max={6}
                validations={{
                  required: true,
                  maxLength: 6,
                  pattern: AlfaNumerico,
                }}
                isHook={true}
                name={"codigo"}
                isPlaceHolder={"Digite el codigo de la bodega"}
              />
              <InputGlobal
                title={"Nombre de la Bodega"}
                max={45}
                validations={{
                  required: true,
                  maxLength: 45
                }}
                type={"text"}
                name={"nombreBodega"}
                isHook={true}
                isPlaceHolder={"Digite el nombre de la bodega"}
              />
              <AutoCompleteInput
                identificador={"idEstablecimiento"}
                title={"Establecimiento"}
                option={
                  documentos.filter(
                    (establecimiento) => establecimiento.activo === true
                  ).length > 10
                    ? (e) =>
                        `${String(e["codigo"]).padStart(3, "0")} - ${
                          e["direccion"]
                        }`
                    : (e) => `${String(e["codigo"]).padStart(3, "0")}`
                }
                validations={{ required: true }}
                array={documentos.filter(
                  (establecimiento) => establecimiento.activo === true
                )}
                searchFn={searchEstablecimiento}
              />
              <InputGlobal
                title={"Dirección"}
                type={"text"}
                max={40}
                validations={{
                  required: true,
                  maxLength: 40
                }}
                name={"direccion"}
                isPlaceHolder={"Digite el nombre de la dirección"}
                isHook={true}
              />
            </div>
            <ModalButtons
              closeFn={BodegaModalOff}
              isSending={isSending}
              useRedux={true}
            />
          </form>
        </section>
      </FormProvider>
    </ModalBase>
  );
};
