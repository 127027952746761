import { CrearBodegaForm } from "./CrearBodegaForm";
import { CrearBodegaList } from "./CrearBodegaList";
import { useSelector } from "react-redux";
import { ModalStatusBodega } from "./modalCrearBodega.slice";
import { ModalEditarBodega } from "./ModalEditarBodega";
import { ModalEliminarBodega } from "./ModalEliminarBodega";
import EstablecimientosProvider from "../../../Mantenimiento/Establecimiento/EstablecimientosProvider";
import { BodegaProvider } from "./context/BodegasContext";

export const CrearBodega = () => {
  const { modalStatus, registroAfectado } = useSelector(
    (state) => state.modalBodega
  );

  return (
    <BodegaProvider>
      <EstablecimientosProvider>
        <section className="w-full h-full flex flex-col gap-5">
          <CrearBodegaForm />
          <CrearBodegaList />
        </section>
        {modalStatus === ModalStatusBodega.editar && registroAfectado && (
          <ModalEditarBodega />
        )}
        {modalStatus === ModalStatusBodega.eliminar && registroAfectado && (
          <ModalEliminarBodega />
        )}
      </EstablecimientosProvider>
    </BodegaProvider>
  );
};
