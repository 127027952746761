import React, { useState } from "react";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import { cargarEmpresas, seleccionarEmpresa } from "./navBar.slice";
import { useDispatch, useSelector } from "react-redux";
import { getList } from "../../services";

const SelectEmpresa = () => {
  const empresa = useSelector((store) => store.empresa.empresa);
  const empresas = useSelector((store) => store.empresa.empresas);
  const dispatch = useDispatch();

  const loadOptions = React.useCallback(
    debounce((inputText, callback) => {
      buscarEmpresas(inputText).then((options) => callback(options));
    }, 400),
    []
  );

  function customStyles() {
    return {
      control: function (styles, { isFocused }) {
        const isMediumScreen = window.matchMedia('(min-width: 768px)').matches;
  
        return {
          ...styles,
          minWidth: isMediumScreen ? '400px' : '250px',
        };
      },
    };
  }

  const handleChangeSuperAdmin = async (value) => {
    const listItems = empresas.filter((el) => el.idEmpresa !== value.idEmpresa);
    dispatch(cargarEmpresas([...listItems, value]));
    dispatch(seleccionarEmpresa(value.idEmpresa));
    window.localStorage.setItem("empresaSelected", value.idEmpresa);
  };

  const handleChange = async (e) => {
    let value = e.target ? e.target.value : e.value;
    dispatch(seleccionarEmpresa(value));
    window.localStorage.setItem("empresaSelected", value);
  };

  const listaEmpresas = empresas.map((el) => {
    return { value: el.idEmpresa, label: `${el.razonSocial} - ${el.ruc}` };
  });

  const buscarEmpresas = async (busquedad) => {
    let res = await getList(
      `api/Empresas/GetEmpresasByUserSearch?searchTerm=${busquedad}`
    );
    return res._embedded;
  };

  const [changeDetalle, setChangeDetalle] = useState("");

  if (empresas.length < 1)
    return (
      <h1 className="text-center mt-2 mb-1 text-gray-400 dark:text-white">
        No tienes ninguna empresa ligada a tu cuenta!
      </h1>
    );
  if (empresas.length === 1) return <></>;
  if (empresas.length > 15)
    return (
      <div className="mt-2 mb-2 flex rounded-md shadow-sm ">
        <span className="lg:inline-flex md:inline-flex hidden dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 stroke-gray-500 dark:stroke-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
            />
          </svg>

          <span className="lg:table-cell hidden">Empresa</span>
        </span>
        <AsyncSelect
          noOptionsMessage={() => "sin datos!"}
          loadingMessage={() => "cargando"}
          loadOptions={loadOptions}
          defaultOptions={empresas}
          styles={customStyles()}
          placeholder={
            empresa.idEmpresa
              ? `${empresa.razonSocial} - ${empresa.ruc}`
              : "Busca tu empresa..."
          }
          id="empresaSelect"
          name="empresaSelect"
          className="z-40 w-full lg:text-lg md:text-lg text-xs"
          getOptionLabel={(e) => `${e.razonSocial} - ${e.ruc}`}
          getOptionValue={(e) => e.idEmpresa}
          defaultValue={empresa.idEmpresa && empresa.idEmpresa}
          onChange={(e) => {
            handleChangeSuperAdmin(e);
            setChangeDetalle(e);
          }}
          value={changeDetalle}
          options={listaEmpresas}
        />
      </div>
    );
  else
    return (
      <div className="mt-1 flex rounded-md shadow-sm ">
        <span className="inline-flex dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5 stroke-gray-500 dark:stroke-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
            />
          </svg>

          <span className="lg:table-cell hidden">Empresa</span>
        </span>
        <select
          value={empresa.idEmpresa}
          onChange={handleChange}
          className="pl-2  flex-1 block w-full dark:text-white rounded-none  form-control border py-1.5 rounded-r-md sm:text-sm dark:border-gray-600 border-gray-300 dark:bg-gray-700 dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 "
        >
          {empresas.map(({ idEmpresa, ruc, razonSocial }) => (
            <option key={idEmpresa} value={idEmpresa}>
              {razonSocial} - {ruc}
            </option>
          ))}
        </select>
      </div>
    );
};

export default SelectEmpresa;
