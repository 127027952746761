import React, { useContext } from "react";
import { useSelector } from "react-redux";
import useVistaPrevia from "../hooks/useVistaPrevia";
import { getFechaV1, roundToTwo } from "../../../services/Utilitario";
import { EmitirDocContext } from "./EmitirDocProvider";
import { toast } from "react-toastify";
import { CalcularImpuestosList } from "../EmitirFactura/services/impuestos";
import { ClipLoader } from "react-spinners";

const SubmitButtonND = () => {
  const {
    formik,
    DetalleList,
    Totales,
    FormaPagoList,
    InformacionAdicionalList,
  } = useContext(EmitirDocContext);

  const { GetVistaPreviaNotaDebito, IsLoading } = useVistaPrevia();

  const empresa = useSelector((store) => store.empresa.empresa);

  const handleVistaPrevia = async () => {
    try {
      let parsedInfo = {
        IdContribuyente: formik.values.idContribuyente,
        IdentificacionCliente: formik.values.clienteIdentificacion,
        RazonSocialContribuyente: formik.values.clienteRazonSocial,
        Direccion: formik.values.clienteDireccion,
        TelefonoCelular: formik.values.clienteTelefono,
        CodigoDocumentoModificado: formik.values.codigoComprobanteModificar,
        NumeroDocumentoModificado: formik.values.numeroComprobanteModificar,
        FechaEmisionDocumentoModificado: getFechaV1(
          formik.values.fechaEmisionComprobanteModificar
        ),
        Ambiente: process.env.REACT_APP_ENVIRONMENT,
        IdEmpresa: empresa.idEmpresa,
        RucEmpresa: empresa.ruc,
        TotalDescuento: Totales.totalDescuentos,
        CodDoc: 5,
        FechaEmision: getFechaV1(formik.values.fechaEmision),
        ImporteTotal: Totales.importeTotal,
        TotalSinImpuestos: Totales.totalSinImpuesto,
        ListaImpuestoND: await CalcularImpuestosList(
          DetalleList,
          empresa,
          Totales
        ),

        InformacionAdicionalNotaDebito: InformacionAdicionalList.map((x) => {
          return {
            Descripcion: x.nombre,
            Valor: x.valor,
          };
        }),
        DetalleNotaDebito: DetalleList.map((e) => {
          return {
            Motivos: e.motivo,
            // (parseFloat (e.valor) * parseFloat(e.porcentaje)) + e.valor,
            Valor: e.valor,
            Descripcion: e.descripcion,
            Cantidad: e.cantidad,
            ImpuestoValorIva: e.iva,
            // CodigoPrincipal: e.codigoPrincipal,
            // PrecioUnitario: e.precioUnitario,
            // Descuento: 0,
            ImpuestoCodigoIva: 2,
            ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
            PrecioTotalSinImpuesto: e.subTotal,
            ImpuestoBaseImponibleIva: e.subTotal,

            Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
            InformacionAdicionalProductoCredito:
              e.servicioDetalle === undefined
                ? []
                : e.servicioDetalle === null
                ? []
                : e.servicioDetalle.map((x) => {
                    return {
                      Valor: x.nombre,
                      Descripcion: x.valor,
                    };
                  }),
          };
        }),

        FormaPagoNotaDebito: FormaPagoList.map((x) => {
          // alert("");

          return {
            UnidaDeTiempo: x.tiempoPago,
            Plazo: x.plazoPago,
            Total: x.valor,
            IdFormaPagoSri: x.tipoPago,
          };
        }),
        IdImpuestoNoObjectoImpuesto:
          Totales.subTotalNoObjetoIva !== 0
            ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
            : "",
        IdImpuestoExentoIva:
          Totales.subTotalExentoIva !== 0
            ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
            : "",
        IdImpuestoIVA12:
          Totales.subTotalIva !== 0
            ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
            : "",
        IdImpuestoIVA0:
          Totales.subTotalIva0 !== 0
            ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
            : "",
        //IdImpuestoIVA14= 8DCD2CFA-5F9D-4FB7-B27A-87447D6CDDE7
        TotalTotalIva0: 0,
        TotalTotalIva: Totales.totalIva,
        TotalNoObjetoImpuesto: 0,
        TotalExentoIva: 0,
        TotalBaseIva0: Totales.subTotalIva0,
        TotalBaseIva: Totales.subTotalIva,
        TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
        TotalBaseExentoIva: Totales.subTotalExentoIva,
        Establecimiento: formik.values.establecimiento,
        PuntoEmision: formik.values.puntoEmision,
        Secuencial: formik.values.secuencial,
        subTotalIva12: Totales.subTotalIva,
        subTotalIva0: Totales.subTotalIva0,
        subTotalNoObjetoIva: Totales.subTotalNoObjetoIva,
        subTotalExentoIva: Totales.subTotalExentoIva,
        Iva12: Totales.totalIva.toFixed(2),
        Iva0: 0,
      };

      // if (Totales.totalIva === 0) {
      //   parsedInfo.Iva0 = Totales.totalIva.toFixed(2);
      // }
      // else{
      //   parsedInfo.Iva12 = Totales.totalIva.toFixed(2);
      // }

      if (formik.values.clienteEmail !== null)
        if (formik.values.clienteEmail.trim() !== "")
          parsedInfo.InformacionAdicionalNotaDebito.push({
            Valor: formik.values.clienteEmail
              .replace(/,/g, ";")
              .replace(/\s+/g, ""),
            Descripcion: "email",
          });

      let data = await GetVistaPreviaNotaDebito(parsedInfo);
      if (data === "") {
        toast.error("Faltan datos por llenar");
        return;
      }
      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (err) {
      toast.error("Hubo un error al generar la vista previa.");
    }
  };

  return (
    <>
      {IsLoading && (
        <div
          style={{ width: "100%", height: "100%", zIndex: "100" }}
          className="bg-opacity-80 fixed justify-center flex items-center top-0 l-0  bg-white text-center"
        >
          <ClipLoader color="#d8d4d4" size={"50"} />
        </div>
      )}
      <div className="flex space-x-2">
        <div className="group relative  border-gray-300 border rounded-md inline-block">
          <button
            type="button"
            onClick={handleVistaPrevia}
            className="inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl  hover:shadow-md    transition dark:bg-gray-900 dark:hover:bg-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 dark:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>

            <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
              <p className="text-white dark:text-black  mx-auto text-sm">
                Vista previa{" "}
              </p>
            </div>
          </button>
        </div>
        <button
          onClick={formik.handleSubmit}
          type="submit"
          className="border p-2 rounded-lg bg-[#04BC53] hover:bg-[#03a448] transition-all duration-150 w-auto flex"
          data-bs-toggle="tooltip"
          title="GUARDAR DOCUMENTO"
        >
          <p className=" mx-2 text-white">Enviar al SRI</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mr-2 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default SubmitButtonND;
