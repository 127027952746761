import { create } from 'zustand'

export const ModalProductosStatus = {
    off: "off",
    eliminar: "eliminar",
    desabilitar: "desabilitar"
}

export const useModalProductoStore = create((set) => ({
  ModalProducto: ModalProductosStatus.off,
  ProductoAfectado: null,
  changeProductoModal: (status, producto) => set({ModalProducto: status, ProductoAfectado: producto}),
  turnProductoModalOff: () => set({ModalProducto: ModalProductosStatus.off, ProductoAfectado: null}),
  setProductoOnly: (producto) => set({ProductoAfectado: producto})
  
}))