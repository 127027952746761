import {
  ValoracionRows,
  ValoracionRowsAvailable,
} from "./ValoracionConstants.d";
import { ArrowsColIcon, ThreeDotsIcon } from "../../../../Icons/ButtonIcons";
import { useState } from "react";
import { useAvailableRows } from "./hooks/useAvailableRows";
import { ValoracionInventarioItem } from "./ValoracionInventarioItem";
import { ClipLoader } from "react-spinners";
import { useContext } from "react";
import { ValoracionInventarioContext } from "./context/ValoracionInventarioContext";

export const ValoracionInventarioList = () => {
  const [openModal, setOpenModal] = useState(false);
  const { availableRows, handleClick } = useAvailableRows(
    ValoracionRowsAvailable,
    ValoracionRows
  );
  const { isLoading, valoracionInventario } = useContext(
    ValoracionInventarioContext
  );

  return (
    <div className="w-full bg-white dark:bg-gray-900 h-fit relative p-10 shadow-md rounded-lg">
      {openModal && (
        <div className="absolute p-5 rounded-lg flex flex-col text-sm shadow-xl bg-white dark:bg-gray-700 dark:text-white z-20 gap-4 left-28 top-0">
          <label
            className="flex items-center flex-row gap-2"
            htmlFor="Inventario Inicial"
          >
            <input
              type="checkbox"
              checked={
                availableRows.find((row) => row.row === "Inventario Inicial")
                  ? true
                  : false
              }
              onChange={() => handleClick("Inventario Inicial")}
              id="Inventario Inicial"
            />
            Inventario Inicial
          </label>
          <label className="flex items-center flex-row gap-2" htmlFor="Compras">
            <input
              type="checkbox"
              id="Compras"
              checked={
                availableRows.find((row) => row.row === "Compras")
                  ? true
                  : false
              }
              onChange={() => handleClick("Compras")}
            />
            Compras
          </label>
          <label
            className="flex items-center flex-row gap-2"
            htmlFor="Devolución Compras"
          >
            <input
              type="checkbox"
              id="Devolución Compras"
              checked={
                availableRows.find((row) => row.row === "Devolución Compras")
                  ? true
                  : false
              }
              onChange={() => handleClick("Devolución Compras")}
            />
            Devolución Compras
          </label>
          <label className="flex items-center flex-row gap-2" htmlFor="Ventas">
            <input
              type="checkbox"
              id="Ventas"
              checked={
                availableRows.find((row) => row.row === "Ventas") ? true : false
              }
              onChange={() => handleClick("Ventas")}
            />
            Ventas
          </label>
          <label
            className="flex items-center flex-row gap-2"
            htmlFor="Devolución Ventas"
          >
            <input
              type="checkbox"
              id="Devolución Ventas"
              checked={
                availableRows.find((row) => row.row === "Devolución Ventas")
                  ? true
                  : false
              }
              onChange={() => handleClick("Devolución Ventas")}
            />
            Devolución Ventas
          </label>
          <label
            className="flex items-center flex-row gap-2"
            htmlFor="Ingresos de Inventario"
          >
            <input
              type="checkbox"
              id="Ingresos de Inventario"
              checked={availableRows.find(
                (row) => row.row === "Ingresos de Inventario"
              )}
              onChange={() =>
                handleClick("Ingresos de Inventario") ? true : false
              }
            />
            Ingresos de Inventario
          </label>
          <label
            className="flex items-center flex-row gap-2"
            htmlFor="Egresos de Inventario"
          >
            <input
              type="checkbox"
              id="Egresos de Inventario"
              checked={
                availableRows.find((row) => row.row === "Egresos de Inventario")
                  ? true
                  : false
              }
              onChange={() => handleClick("Egresos de Inventario")}
            />
            Egresos de Inventario
          </label>
          <label
            className="flex items-center flex-row gap-2"
            htmlFor="Inventario Final"
          >
            <input
              type="checkbox"
              id="Inventario Final"
              checked={availableRows.find(
                (row) => row.row === "Inventario Final"
              )}
              onChange={() => (handleClick("Inventario Final") ? true : false)}
            />
            Inventario Final
          </label>
        </div>
      )}
      <div className="overflow-x-scroll w-fit max-w-full overflow-y-hidden pb-4">
        <table className="self-center border-collapse  w-fit text-[12px] ">
          <thead>
            <tr className="bg-gray-200 dark:text-white dark:bg-gray-700 text-center">
              <th
                colSpan={2}
                style={{ padding: "25px" }}
                className="border-2 dark:border-gray-600 border-gray-300 relative"
              >
                <button
                  onClick={() => setOpenModal(!openModal)}
                  className="absolute left-2 top-[13px]"
                >
                  <ThreeDotsIcon />
                </button>
              </th>
              {availableRows.map((col, i) => (
                <th
                  key={i}
                  colSpan={col.subRows.length}
                  className="border-2 dark:border-gray-600 dark:bg-gray-700 dark:text-white border-gray-300"
                  style={{ padding: "15px" }}
                >
                  {col.row}
                </th>
              ))}
            </tr>
            <tr className="bg-gray-200 text-center">
              <th
                scope="col"
                style={{ padding: "15px" }}
                className="border-2 dark:border-gray-600 dark:bg-gray-700 dark:text-white border-gray-300"
              >
                <div className="flex flex-row gap-3 items-center whitespace-nowrap">
                  <span>Codigo</span>{" "}
                  <button className="opacity-50 hover:opacity-100 z-0">
                    <ArrowsColIcon />
                  </button>
                </div>
              </th>
              <th
                scope="col"
                className="border-2 dark:border-gray-600 min-w-[320px] dark:bg-gray-700 dark:text-white border-gray-300"
                style={{ padding: "15px" }}
              >
                <div className="flex flex-row justify-center gap-3 items-center whitespace-nowrap">
                  <span>Nombre</span>{" "}
                  <button className="opacity-50 hover:opacity-100 z-0">
                    <ArrowsColIcon />
                  </button>
                </div>
              </th>
              {availableRows.flatMap((col) =>
                col.subRows.map((subRow, j) => (
                  <th
                    key={`${col.row}-${subRow}`}
                    scope="col"
                    style={{ padding: "15px" }}
                    className="border-2 dark:border-gray-600 dark:bg-gray-700 dark:text-white border-gray-300"
                  >
                    <div className="flex flex-row gap-3 items-center whitespace-nowrap">
                      <span>{subRow}</span>
                      <button className="opacity-50 hover:opacity-100 z-0">
                        <ArrowsColIcon />
                      </button>
                    </div>
                  </th>
                ))
              )}
            </tr>
          </thead>
          <tbody>
            {!isLoading && valoracionInventario.length > 0 &&
              valoracionInventario.map((producto,i) => (
                <ValoracionInventarioItem
                  key={i}
                  producto={producto}
                  availableRows={availableRows}
                />
              ))}
            {isLoading && (
              <tr className="bg-white dark:bg-gray-700 text-center font-normal">
                <td className="border-2 dark:border-gray-600 border-gray-300" colSpan={20} style={{ padding: "15px" }}>
                  <ClipLoader color="#d8d4d4" />
                </td>
              </tr>
            )}
            {!isLoading && valoracionInventario.length === 0 && (
              <tr className="bg-white dark:text-white dark:bg-gray-700 text-center font-normal">
                <td
                  colSpan={20}
                  className="border-2 dark:border-gray-600 border-gray-300"
                  style={{ padding: "15px" }}
                >
                  <span className="flex flex-row gap-2 items-center justify-center">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    No se encontraron datos para la busqueda
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
