import React, { useState } from "react";

import FormDoc from "./FormDoc";

import EmitirDocProvider, { EmitirDocContext } from "./EmitirDocProvider";
import FormBuscarDetalle from "./FormBuscarDetalle";
import ListaDetalle from "./ListaDetalle";
import TotalDocumento from "./TotalDocumento";
import Modals from "./Modals";
import ModalGuardar from "./ModalGuardar";
import InformacionAdicional from "../../../features/components/InformacionAdicional";
import FormaPago from "./FormaPago";
import MobileSubmitButton from "./MobileSubmitButton";
import SubmitButtonND from "./SubmitButtonND";
import { Transition } from "@headlessui/react";
const EmitirNotaDebito = () => {
  const [valueOptions, setValueOptions] = useState("FORMAPAGO");

  const ClassAvite =
    " lg:py-1.5 md:py-1.5 py-1 px-1 bg-[#003B5B] cursor-pointer w-full rounded-tl-lg flex items-center justify-center text-white";

  const ClassInactive =
    " lg:py-1.5 md:py-1.5 py-1 px-1 w-full cursor-pointer bg-gray-400 flex items-center justify-center rounded-tl-lg text-white";
  const ClassInactive2 =
    " lg:py-1.5 md:py-1.5 py-1 px-1 w-full cursor-pointer bg-gray-400 flex lg:justify-center justify-between items-center rounded-tr-lg text-white";
  
  const ClassActive2 =
    " lg:py-1.5 md:py-1.5 py-1 px-1 cursor-pointer text-white w-full bg-[#003B5B] lg:justify-center  flex justify-between items-center rounded-tr-lg";

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  return (
    <EmitirDocProvider>
      {/*h-screen overflow-hidden flex items-center  justify-center*/}
      <div className="p-4">
        <div className=" dark:bg-gray-900 sm:rounded-lg   ">
          <FormDoc />
          <div className=" px-2 md:mb-0 mb-3  bg-white dark:bg-gray-900  lg:p-3 md:p-3">
            <FormBuscarDetalle />
          </div>

          <div className="bg-white md:block flex flex-col gap-4 px-2 dark:bg-gray-900 rounded-b-lg lg:p-3 md:p-3">
            <div className="lg:grid md:grid hidden lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 py-1">
              <div className="relative z-0 border rounded-lg dark:border-gray-600  w-full group">
                <div className="flex space-x-0 w-full">
                  <h3
                    onClick={() => setValueOptions("FORMAPAGO")}
                    className={
                      valueOptions === "FORMAPAGO" ? ClassAvite : ClassInactive
                    }
                  >
                    Forma de pago
                  </h3>
                  <h3
                    onClick={() => setValueOptions("INFORMACIONADICIONAL")}
                    className={
                      valueOptions === "INFORMACIONADICIONAL"
                        ? ClassActive2
                        : ClassInactive2
                    }
                  >
                    Información adicional (Opcional)
                  </h3>
                </div>
                <div className="p-4">
                  {valueOptions === "FORMAPAGO" ? (
                    <FormaPago
                      valueOptions={valueOptions}
                      setValueOptions={setValueOptions}
                    />
                  ) : (
                    <InformacionAdicional Contexto={EmitirDocContext} />
                  )}{" "}
                </div>
              </div>
              <div className=" z-0 border dark:border-gray-600  rounded-lg  w-full ">
                <TotalDocumento />
              </div>
            </div>

            <div className="lg:hidden md:hidden border rounded-b-lg dark:border-gray-600 rounded-t-lg">
              <div className="text-sm py-1.5 px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 dark:border-gray-700 rounded-t-lg font-medium leading-6 text-white flex justify-between">
                <h3 className="flex w-full justify-between items-center">
                  Forma de pago
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => setIsOpen(!isOpen)}
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 duration-150 ease-in-out ${isOpen ? 'transform rotate-180' : ''}`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </h3>
              </div>
              <Transition
                show={isOpen}
                className={"mx-4"}
                enter="transition ease-out duration-300" // Aumenta la duración de entrada
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-300" // Aumenta la duración de salida
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <FormaPago />
              </Transition>
            </div>

            <div className="lg:hidden md:hidden border dark:border-gray-600 rounded-b-lg rounded-t-lg ">
              <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 dark:border-gray-700 rounded-t-lg font-medium leading-6 text-white flex justify-between">
                <h3 className="flex w-full justify-between items-center">
                  Información adicional (Opcional)
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => setIsOpen2(!isOpen2)}
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className={`w-4 h-4 duration-150 ease-in-out ${isOpen2 ? 'transform rotate-180' : ''}`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </h3>
              </div>
              <Transition
                show={isOpen2}
                className={"mx-4"}
                enter="transition ease-out duration-300" // Aumenta la duración de entrada
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-300" // Aumenta la duración de salida
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <InformacionAdicional
                  Contexto={EmitirDocContext}
                  valueOptions={valueOptions}
                  setValueOptions={setValueOptions}
                />
              </Transition>
            </div>
            <div className="lg:hidden md:hidden  border rounded-lg dark:border-gray-600 w-full my-1 ">
              <TotalDocumento />
            </div>

            <div
              className="flex my-2
            lg:justify-end justify-center"
            >
              <SubmitButtonND />
            </div>
          </div>

          {/* <MobileSubmitButton /> */}
        </div>
      </div>
      <Modals />
      <ModalGuardar />
    </EmitirDocProvider>
  );
};

export default EmitirNotaDebito;
