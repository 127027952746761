import React from "react";
import FormUsuarios from "./FormUsuarios";
import ListaUsuarios from "./ListaUsuarios";
import ReporteUsuariosProvider from "./ReporteUsuariosProvider";
import ModalAdd from "./ModalAdd";
import ModalEdit from "./ModalEdit";

const ReporteFactura = () => {
  return (
    <ReporteUsuariosProvider>
      <div className="lg:mx-1 md:mx-1 lg:my-4 md:my-4">
        <div className="  dark:bg-gray-800 sm:rounded-lg  ">
          <div className=" border bg-white pb-3 dark:bg-gray-700 dark:border-gray-600 rounded-lg ">
            <FormUsuarios />
          </div>
          <div className="bg-white dark:bg-gray-700 border dark:border-gray-600 rounded-lg my-4 px-2">
            <ListaUsuarios />
          </div>
          <ModalAdd />
          <ModalEdit />
        </div>
      </div>
    </ReporteUsuariosProvider>
  );
};

export default ReporteFactura;
