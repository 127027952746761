import { FormProvider, useForm } from "react-hook-form";
import {
  AddIcon,
  UploadIcon,
  DownloadIcon,
  SearchIcon,
  OffIcon,
} from "../../../../Icons/ButtonIcons";
import { InputGlobal } from "../../context/inputContext";
import { SelectGlobal } from "../../context/selectContext";
import { NavLink } from "react-router-dom";
import { useStore } from "zustand";
import { ModalSubirExcel } from "../../Shared/ModalSubirExcel";
import { v4 as uuidv } from "uuid";
import {
  ObtenerUsoProducto,
  ObtenerElementoConfiguracion,
  ObtenerTerminoProducto,
  ObtenerTipoProducto,
  GenerarExcelProductos,
} from "../../../../services/InventarioService";
import { getListV2 } from "../../../../services";
import {
  ModalProductosStatus,
  useModalProductoStore,
} from "./ModalProductoContext";
import * as XLSX from "xlsx";
import { useContext, useState, useEffect } from "react";
import { ProductoContext } from "../context/ProductContext";

import { useSelector } from "react-redux";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import {
  ObtenerConfiguracionInventarioEmpresa,
  ObtenerCategoriasActivas,
} from "../../../../services/InventarioService";
import { useProgressBar } from "../../hooks/useProgressBar";

export const CrearProductoForm = () => {
  const methods = useForm();
  const { changeProductoModal } = useStore(useModalProductoStore);
  const {
    setFiltros,
    uploadExcelProductos,
    productosChecked,
    productos,
    getProductos,
  } = useContext(ProductoContext);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const empresa = useSelector((state) => state.empresa.empresa);
  const { WarningToast, ErrorToast } = useGlobalToastify();
  const [UsoProducto, setUsoProducto] = useState([]);
  const [medidas, setMedidas] = useState([]);
  const [terminosProducto, setTerminosProducto] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [impuestosDetalles, setImpuestosDetalles] = useState([]);

  const {
    isUploading,
    setIsUploading,
    progress,
    updateProgressBar,
    setProgress,
  } = useProgressBar();

  const SearchProduct = (data) => {
    const Filtros = {
      nombre: data.codigo_Nombre,
      estado: data.isActive,
      usoProducto: data.usoProducto,
    };
    setFiltros(Filtros);
  };

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerCategoriasActivas(
        "/api/categoria/obtener-categorias-activas",
        empresa.idEmpresa
      ).then((response) => setCategorias(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    ObtenerTerminoProducto().then((res) => {
      setTerminosProducto(res.data);
    });
  }, []);

  useEffect(() => {
    ObtenerElementoConfiguracion(
      "/api/unidad-medida/obtener-unidad-medida"
    ).then((response) => setMedidas(response.data));
  }, []);

  useEffect(() => {
    ObtenerUsoProducto().then((res) => {
      setUsoProducto(res.data);
    });
  }, []);

  useEffect(() => {
    getListV2(empresa, "GetAllimpDetalles", "?activo=true&idImpuesto=1").then(
      (response) => setImpuestosDetalles(response._embedded)
    );
  }, [empresa]);

  const desactiveProducto = () => {
    changeProductoModal(ModalProductosStatus.desabilitar);
  };

  const handleFileImportProducto = (e) => {
    const importedFile = e.target.files[0];
    const reader = new FileReader();

    if (importedFile.length === 0) {
      return;
    }
    reader.onload = async (e) => {
      const medidasEmpresa = await ObtenerConfiguracionInventarioEmpresa(
        "/api/unidad-medida/obtener-unidad-medida-empresa",
        empresa.idEmpresa
      );
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const propiedadesRequeridas = [
        "Código",
        "Nombre Producto",
        "Término",
        "Uso de Producto",
        "Tipo de Producto",
        "Categoría",
        "Control de Lote",
        "¿Control Fecha Expiración?",
        "¿Mínimo Stock?",
        "Medida",
        "Precio Venta",
        "Limite de Stock",
        "Impuesto",
        "Costo",
      ];
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
      const xlData = XLSX.utils.sheet_to_json(worksheet, { header: 0 });
      let isValid = true;

      for (const prop of [
        ...propiedadesRequeridas,
        "Detalle",
        "Código de Barras",
      ]) {
        if (!headers.includes(prop)) {
          isValid = false;
        }
      }
      if (isValid === false) {
        ErrorToast(
          "La plantilla de excel no cumple con los campos requeridos."
        );
        return;
      }
      let percentageIncrement = 0;
      let newProductos = [];
      let productosNoUploaded = [];
      const totalIteraciones =
        Math.round(xlData.length / 10) < 1 ? 1 : Math.round(xlData.length / 10);
      percentageIncrement = 100 / totalIteraciones;
      for (const producto of xlData) {
        const hasAllRequiredValues = propiedadesRequeridas.every(
          (prop) =>
            producto[prop] !== "" &&
            producto[prop] !== null &&
            producto[prop] !== undefined
        );
        if (!hasAllRequiredValues) {
          productosNoUploaded.push(producto);
          continue;
        } else if (
          productos
            .map((p) => String(p.codigo))
            .includes(String(producto["Código"]))
        )
          continue;
        else {
          const newProducto = {
            idProducto: uuidv(),
            idEmpresa: empresa.idEmpresa,
            codigo: String(producto["Código"]),
            nombre: producto["Nombre Producto"],
            detalle: producto["Detalle"] ?? "",
            idTerminoProducto: terminosProducto.find(
              (termino) =>
                String(termino.nombre).toUpperCase() ===
                String(producto["Término"].toUpperCase())
            )?.idTerminoProducto,
            idTipoProducto: "9200f400-32eb-4fc6-8f6b-78d2b8be684a",
            idUsoProducto: UsoProducto.find(
              (uso) =>
                String(uso.nombre).toUpperCase() ===
                String(producto["Uso de Producto"].toUpperCase())
            )?.idUsoProducto,
            idCategoriaProducto:
              categorias &&
              categorias
                .filter((c) => c.estado === true)
                .find(
                  (categoria) =>
                    String(categoria.nombre) === String(producto["Categoría"])
                )?.idCategoriaProducto,
            codigoBarra: String(producto["Código de Barras"] ?? ""),
            isLote: producto["Control de Lote"] === "Si" ? true : false,
            isFechaCaducidad:
              producto["¿Control Fecha Expiración?"] === "Si" ? true : false,
            isMinimoStock: producto["¿Mínimo Stock?"] === "Si" ? true : false,
            alertaStock: producto["Limite de Stock"]
              ? producto["Limite de Stock"]
              : 0,
            idUnidadesMedida:
              medidasEmpresa.data.length > 0 &&
              medidasEmpresa.data.includes(
                medidas?.find(
                  (medida) =>
                    String(medida.nombre).toUpperCase() ===
                    String(producto["Medida"]).toUpperCase()
                )?.idUnidadMedida
              )
                ? medidas?.find(
                    (medida) =>
                      String(medida.nombre).toUpperCase() ===
                      String(producto["Medida"]).toUpperCase()
                  )?.idUnidadMedida
                : null,
            precioVenta: parseFloat(
              parseFloat(producto["Precio Venta"]).toFixed(2)
            ),
            idImpuestoDetalle:
              impuestosDetalles.length > 0 &&
              impuestosDetalles?.find(
                (impuesto) =>
                  String(impuesto.detalle).toUpperCase() ===
                  String(producto["Impuesto"].toUpperCase())
              )?.idImpuestoDetalle,
            costo: parseFloat(parseFloat(producto["Costo"])).toFixed(6),
            imagenes: [],
            isCompuesto: false,
            estado: true,
          };
          if (
            newProducto.idCategoriaProducto &&
            newProducto.idTerminoProducto &&
            newProducto.idTipoProducto &&
            newProducto.idUsoProducto &&
            newProducto.idImpuestoDetalle &&
            newProducto.idUnidadesMedida
          ) {
            newProductos.push(newProducto);
          } else {
            productosNoUploaded.push(newProducto);
          }
        }
      }
      setIsUploading(true);
      for (let i = 0; i < totalIteraciones; i++) {
        const batch = newProductos.slice(i * 10, (i + 1) * 10);
        try {
          await uploadExcelProductos(batch);
          updateProgressBar(percentageIncrement);
        } catch (err) {
          console.log(err);
        }
      }
      await getProductos();
      setIsUploading(false);
      setProgress(0);
      if (productosNoUploaded.length > 0) {
        WarningToast("Algunos productos que no cumplieron los requerimientos");
      }
    };
    reader.readAsArrayBuffer(importedFile);
    setFileInputKey(Date.now());
  };

  const handleFileExportProducto = async () => {
    try {
      const excelBase64 = await GenerarExcelProductos(empresa.idEmpresa);
      const excelBinary = atob(excelBase64);
      const byteNumbers = new Array(excelBinary.length);
      for (let i = 0; i < excelBinary.length; i++) {
        byteNumbers[i] = excelBinary.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Productos.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error("Error al procesar el archivo Excel:", err);
    }
  };

  const SearchActive =
    "p-3 rounded-lg text-white bg-blue-600 mt-4 transition-all h-fit w-fit hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center ";

  return (
    <>
      {" "}
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(SearchProduct)}
          autoComplete="off"
          className="w-full bg-white dark:bg-gray-900 rounded-lg p-4 shadow-md flex flex-col gap-4"
        >
          <h1 className="font-semibold text-xl dark:text-white p-2 pr-0 pl-0 border-b border-gray-200">
            Registro de Productos
          </h1>
          <div>
            <p className="bg-[#003B5B] text-white flex items-center flex-row gap-2 font-semibold rounded-t-lg p-3">
              <span>
                <svg
                  fill="#ffffff"
                  version="1.1"
                  width={"20"}
                  height={"20"}
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 611.998 611.998"
                  stroke="#ffffff"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path d="M598.007,109.07l-73.663-92.419c-6.958-8.729-17.515-13.815-28.679-13.815H169.943c-11.164,0-21.72,5.085-28.678,13.815 L67.601,109.07c-7.696,9.655-13.992,27.656-13.992,40.002v152.604c28.321-47.217,79.863-78.988,138.814-78.988 c89.294,0,161.934,72.641,161.934,161.926c0,52.824-25.536,99.674-64.787,129.25h276.585c25.318,0,45.843-20.523,45.843-45.842 V149.072C611.999,136.726,605.703,118.725,598.007,109.07z M91.675,108.287l24.395-30.605h120.598l16.425-27.005H137.594 l18.011-22.596c3.499-4.391,8.726-6.908,14.339-6.908h325.722c5.614,0,10.841,2.518,14.34,6.908l18.011,22.596H409.643 l9.169,27.005h130.729l24.394,30.605H91.675z M192.429,250.195c-74.24,0-134.423,60.184-134.423,134.424 c0,31.756,11.076,60.889,29.489,83.893L8.52,554.762c-12.106,13.227-11.204,33.768,2.023,45.881 c6.236,5.701,14.086,8.52,21.922,8.52c8.788,0,17.554-3.551,23.959-10.541l82.888-90.523c16.298,7.021,34.244,10.945,53.117,10.945 c74.24,0,134.423-60.184,134.423-134.424C326.853,310.381,266.668,250.195,192.429,250.195z M192.429,473.137 c-48.888,0-88.519-39.631-88.519-88.518c0-48.889,39.631-88.52,88.519-88.52s88.519,39.631,88.519,88.52 C280.948,433.506,241.316,473.137,192.429,473.137z"></path>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </span>
              Filtro de Busqueda
            </p>
            <div className="w-full border border-gray-300 dark:border-gray-600 dark:bg-gray-700 rounded-b-lg p-6 pl-8 pr-8">
              <div className="grid lg:grid-cols-4 text-[12px] w-full gap-4">
                <InputGlobal
                  type={"text"}
                  isPlaceHolder={"Digite el nombre del Producto"}
                  title={"Nombre / Còdigo"}
                  name={"codigo_Nombre"}
                  isHook={true}
                />
                <SelectGlobal title={"Estado"} name={"isActive"}>
                  <option value="" selected hidden>
                    Seleccione el Estado del Producto
                  </option>
                  <option value={""}>Todos</option>
                  <option value={"Activo"}>Activo</option>
                  <option value={"Inactivo"}>Inactivo</option>
                </SelectGlobal>
                <SelectGlobal title={"Uso del Producto"} name={"usoProducto"}>
                  {UsoProducto.length > 0 && (
                    <>
                      <option value="" selected>
                        Todos
                      </option>
                      {UsoProducto.map((option) => (
                        <option
                          key={option.idUsoProducto}
                          value={option.idUsoProducto}
                        >
                          {option.nombre}
                        </option>
                      ))}
                    </>
                  )}
                  {UsoProducto.length === 0 && (
                    <option value="" selected hidden>
                      Cargando Opciones....
                    </option>
                  )}
                </SelectGlobal>
                <button type="submit" className={SearchActive}>
                  <span>
                    <SearchIcon />
                  </span>
                  Buscar
                </button>
              </div>
            </div>
            <div className="flex flex-row gap-1 mt-4 text-white font-semibold text-[12px]">
              <NavLink
                exact="true"
                to={"/inventario/productos/crear/nuevoProducto"}
                className="p-3 rounded-lg bg-blue-600 h-fit hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center"
              >
                <span>
                  <AddIcon />
                </span>
                Agregar
              </NavLink>

              <div className="h-fit w-fit">
                <label
                  htmlFor="importInput"
                  className="p-[12px] cursor-pointer rounded-lg bg-blue-600 hover:bg-blue-700 pl-5 pr-5 flex flex-row gap-2 items-center"
                >
                  <span>
                    <UploadIcon />
                  </span>
                  Importar
                </label>
                <input
                  key={fileInputKey}
                  className="absolute -left-[9999px]"
                  id="importInput"
                  accept=".xlsx"
                  onChange={handleFileImportProducto}
                  type="file"
                />
              </div>

              <button
                type="button"
                onClick={handleFileExportProducto}
                className="p-3 md:pl-8 md:pr-8 rounded-lg bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center"
              >
                {" "}
                <span>
                  <DownloadIcon />
                </span>
                <span className="hide-on-small">Descargar datos</span>
              </button>
              {productosChecked.length > 0 && productos.length > 0 && (
                <button
                  type="button"
                  onClick={() => desactiveProducto()}
                  className="p-[11px] md:pl-8 md:pr-8 rounded-lg bg-gray-600 hover:bg-gray-700 h-fit flex flex-row gap-2 items-center"
                >
                  {" "}
                  <span>
                    <OffIcon w={"20"} h={"20"} />
                  </span>
                  <span className="hide-on-small">Deshabilitar</span>
                </button>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
      <ModalSubirExcel
        isVisible={isUploading}
        progress={progress}
        elemento={"Productos"}
      />
    </>
  );
};
