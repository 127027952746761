import ConsultaLayout from "./Components/ConsultaLayout";
import { ConsultaProvider } from "./Provider/ConsultaProvider";

export default function IndexConsulta() {
  return (
    <ConsultaProvider>
      <ConsultaLayout />
    </ConsultaProvider>
  );
}
