import { useContext } from "react";
import { EgresoProviderContext } from "./Context/EgresoProvider";
import { WarningIcon } from "../../../../../Icons/WarningIcon";
import { ModalBase } from "../../../Shared/ModalBase";

export default function ModalEgresoAlertaStock() {
  const {
    setIsOpen,
    alertaStockProducts,
    setDataToConfirm,
    setAlertaStockProducts,
    dataToConfirm,
    handleEgreso,
  } = useContext(EgresoProviderContext);

  const handleConfirm = async () => {
    setIsOpen(false);
    setAlertaStockProducts([]);
    await handleEgreso(dataToConfirm);
    setDataToConfirm(null);
  };

  const handleCancel = () => {
    setIsOpen(false);
    setDataToConfirm(null);
    setAlertaStockProducts([]);
  };

  return (
    <>
      <ModalBase onClose={() => handleCancel()}>
        <section
          style={{
            padding: "25px",
            height: "fit-content",
            position: "relative",
          }}
          className="shadow-xl rounded-lg flex bg-white dark:bg-gray-900 flex-col gap-4 lg:w-[500px] md:w-[450px] w-[375px]"
        >
          <div className="flex  flex-col gap-4 w-full items-center justify-center">
            <div className=" text-[#003B5B] ">
              <WarningIcon />
            </div>

            <p className="text-md font-semibold text-center">
              Si realizas esta transacción, los siguientes productos quedaran
              por debajo de su minimo de stock.{" "}
            </p>
            <div className="flex flex-col gap-1 items-center">
              {" "}
              {alertaStockProducts.map((p, index) => (
                <span
                  key={index}
                  className="text-sm text-red-600 font-semibold"
                >
                  {p.producto} - Minimo de Stock: {p.alertaStock}
                </span>
              ))}
            </div>

            <div className="flex flex-row gap-4 items-center text-[12px]">
              {" "}
              <button
                onClick={() => handleConfirm()}
                className="bg-[#003B5B] text-white hover:bg-[#112935] p-8 pt-3 pb-3 h-8 text-[12px] w-28 flex items-center justify-center rounded-lg whitespace-nowrap"
              >
                Si, continuar
              </button>
              <button
                onClick={() => handleCancel()}
                className="bg-gray-600 p-8 pt-2 pb-2 h-8 text-[12px]  text-white hover:bg-gray-700 flex items-center justify-center w-28 rounded-lg whitespace-nowrap"
              >
                No, cancelar
              </button>
            </div>
          </div>
        </section>
      </ModalBase>
    </>
  );
}
