import { FormProvider, useForm } from "react-hook-form";
import { SearchIcon } from "../../../../Icons/ButtonIcons";
import { SelectGlobal } from "../../context/selectContext";
import { InputGlobal } from "../../context/inputContext";
import { useContext, useEffect, useState } from "react";

import AutoCompleteInput from "../../context/AutoCompleteSelect";
import { ObtenerTiposTransaccion } from "../../../../services/InventarioService";
import { TransaccionesContext } from "./context/TransaccionesContext";
import { useSelector } from "react-redux";
import { ObtenerSeccionesTransaccion } from "../../../../services/InventarioService";

export const TransaccionesEmitidasForm = ({ CheckedHookTransacciones }) => {
  const methods = useForm({
    defaultValues: {
      fechaFin: new Date().toISOString().split("T")[0],
      fechaInicio: new Date().toISOString().split("T")[0],
    },
  });

  const [tiposTransacciones, setTiposTransacciones] = useState([]);
  const { setFiltros } = useContext(TransaccionesContext);
  const [listaSecciones, setListaSecciones] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);

  const setFiltrosBusqueda = (data) => {
    const filtros = {
      fechaInicio: data.fechaInicio,
      fechaFin: data.fechaFin,
      idTipoTransaccion: data.tipoOperacion,
      idSeccion: data.seccion?.idSeccion ? data.seccion?.idSeccion : "",
    };
    setFiltros(filtros);
  };

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerSeccionesTransaccion(
        "/api/transacciones/obtener-secciones",
        empresa.idEmpresa
      ).then((response) => setListaSecciones(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    ObtenerTiposTransaccion().then((res) => {
      setTiposTransacciones(res.data);
    });
  }, []);

  const searchSecciones = async (inputText) => {
    return listaSecciones.filter(
      (seccion) =>
        String(seccion.nombreBodega)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(seccion.nombreSeccion)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(seccion.establecimiento)
          .padStart(3, "0")
          .toLowerCase()
          .includes(String(inputText).toLowerCase())
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(setFiltrosBusqueda)}
        autoComplete="off"
        className="w-full bg-white dark:bg-gray-900 rounded-lg p-4 shadow-md flex flex-col gap-4"
      >
        <h1 className="font-semibold text-xl p-2 pr-0 pl-0 border-b dark:text-white border-gray-200">
          Reporte de Transacciones Emitidas
        </h1>
        <div>
          <p className="bg-[#003B5B] text-white flex items-center flex-row gap-1 font-semibold rounded-t-lg p-3">
            <span>
              <svg
                viewBox="0 0 24 24"
                version="1.1"
                width={"22"}
                height={"22"}
                fill="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <style type="text/css"> </style> <g id="Layer_1"></g>{" "}
                  <g id="Layer_2">
                    {" "}
                    <g>
                      {" "}
                      <path d="M12,20H8c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h3v3c0,2.2,1.8,4,4,4h3v1.9c0,0.6,0.4,1,1,1s1-0.4,1-1V10c0,0,0,0,0-0.1 c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0l-7-7c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1,0-0.1,0 c-0.1,0-0.2,0-0.3-0.1c0,0,0,0-0.1,0H8C5.8,2,4,3.8,4,6v12c0,2.2,1.8,4,4,4h4c0.6,0,1-0.4,1-1S12.6,20,12,20z M13,5.4L16.6,9H15 c-1.1,0-2-0.9-2-2V5.4z"></path>{" "}
                      <path d="M20.7,20.3l-1-1c0,0-0.1-0.1-0.2-0.1c0.3-0.5,0.5-1.1,0.5-1.7c0-1.9-1.6-3.5-3.5-3.5S13,15.6,13,17.5s1.6,3.5,3.5,3.5 c0.6,0,1.2-0.2,1.7-0.5c0,0.1,0.1,0.1,0.1,0.2l1,1c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3C21.1,21.3,21.1,20.7,20.7,20.3z M16.5,19c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S17.3,19,16.5,19z"></path>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>
              </svg>
            </span>
            Filtro de Busqueda
          </p>
          <div className="w-full border border-gray-300 dark:border-gray-600 dark:bg-gray-700 rounded-b-lg p-6 pl-8 pr-8">
            <div className="grid lg:grid-cols-4 w-full gap-4">
              <InputGlobal
                type={"date"}
                title={"Fecha de Inicio"}
                name={"fechaInicio"}
              />
              <InputGlobal
                type={"date"}
                title={"Fecha de Fin"}
                name={"fechaFin"}
              />
              <AutoCompleteInput
                title={"Sección"}
                name={"seccion"}
                array={listaSecciones}
                parametro={"nombre"}
                identificador={"idSeccion"}
                searchFn={searchSecciones}
                option={(e) => `${String(e["bodegaSeccion"])}`}
              />
              <SelectGlobal title={"Tipo"} name={"tipoOperacion"}>
                {tiposTransacciones.length > 0 && (
                  <>
                    <option value="" selected>
                      Todos
                    </option>
                    {tiposTransacciones.map((option) => (
                      <option
                        key={option.idTipoTransaccion}
                        value={option.idTipoTransaccion}
                      >
                        {option.nombre}
                      </option>
                    ))}
                  </>
                )}
                {tiposTransacciones.length === 0 && (
                  <option value="" selected hidden>
                    Cargando Opciones....
                  </option>
                )}
              </SelectGlobal>
            </div>
          </div>
          <div className="flex flex-row gap-1 mt-4 text-white font-semibold text-[12px]">
            <button
              type="submit"
              className="p-3 rounded-lg bg-blue-600 hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center"
            >
              <span>
                <SearchIcon />
              </span>
              Buscar
            </button>
            {/* {CheckedHookTransacciones.elementosChecked.length > 0 && (
              <button
                type="button"
                className="p-3 pl-8 pr-8 rounded-lg bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center"
              >
                <span>
                  <PDFIcon w={"15"} h={"15"} />
                </span>
                Generar PDF
              </button>
            )} */}
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
