import React, { useContext } from "react";
import MesageError from "../../../features/components/MessageError";
import {
  StyleSelect,
  StyleInput,
  StyleLabel,
} from "../../../features/Constantes";
import { ReporteListaEmpresasContext } from "./ListaEmpresasProvider";
import { Link } from "react-router-dom";
import { SectionFilter } from "../../../features/components/SectionFilter";
import { ButtonDefault } from "../../../features/components/ButtonDefault";
const FormListaEmpresas = () => {
  const {
    formik,
    cargandoData,
    setActivarModalAdd,
    setPaginacion,
    initialStatePaginacion,
    setStart,
  } = useContext(ReporteListaEmpresasContext);

  const handleClickAdd = () => {
    setActivarModalAdd(true);
  };

  const handleChangeText = (e, length) => {
    if (e.target.value.length > length) {
      return e.target.value;
    }
    formik.handleChange(e);
  };

  return (
    <>
      <h2 className="my-4 pl-4 text-2xl  font-semibold text-gray-700 dark:text-gray-200">
        Lista empresas
      </h2>
      <hr className="border-1 lg:mx-4 md:mx-4 py-1 dark:border-gray-600 " />
      <div className="lg:px-4 md:px-4    ">
        <form
          onSubmit={(e) => {
            setStart(0);
            setPaginacion(initialStatePaginacion);
            formik.handleSubmit(e);
          }}
        >
          <SectionFilter title="Busqueda">
            <div className="grid md:grid-cols-5 md:gap-6 bg-[#D7D7D7] dark:bg-gray-700 rounded-lg mx-2 p-2 ">
              <div className="relative z-0 w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                >
                  Razón social
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="razonSocial"
                  name="razonSocial"
                  value={formik.values.razonSocial}
                  onChange={(e) => handleChangeText(e, 40)}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.razonSocial && formik.errors.razonSocial ? (
                  <MesageError message={formik.errors.razonSocial} />
                ) : null}
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                >
                  Nombre comercial
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="nombreComercial"
                  name="nombreComercial"
                  value={formik.values.nombreComercial}
                  onChange={(e) => handleChangeText(e, 100)}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.nombreComercial &&
                formik.errors.nombreComercial ? (
                  <MesageError message={formik.errors.nombreComercial} />
                ) : null}
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                >
                  RUC
                </label>
                <input
                  type="text"
                  className={StyleInput}
                  placeholder=" "
                  id="RUC"
                  name="RUC"
                  value={formik.values.RUC}
                  onChange={(e) => handleChangeText(e, 13)}
                  onBlur={formik.handleBlur}
                />

                {formik.touched.RUC && formik.errors.RUC ? (
                  <MesageError message={formik.errors.RUC} />
                ) : null}
              </div>

              <div className="relative z-0  w-full group">
                <label
                  htmlFor="fechaInicio"
                  className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                >
                  Estado
                </label>
                <select
                  type="text"
                  name="estado"
                  id="estado"
                  className={StyleSelect}
                  placeholder=" "
                  defaultValue={formik.values.estado}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Todos</option>
                  <option value="True">Activo</option>
                  <option value="False">Inactivo</option>
                </select>
              </div>
            </div>
            <div className="mt-4 mx-2 flex-col space-y-1">
              <ButtonDefault
                color={"primary"}
                type="submit"
                cargando={cargandoData}
                title="Buscar"
                svg={
                  cargandoData ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline mr-3 w-4 h-4 animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="-ml-0.5 mr-2 h-5 w-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </>
                  )
                }
              />

              <Link
                to="/emision/reporte/edit-empresa"
                className="text-white bg-[#04BC53] hover:bg-green-600 w-full  focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700 mr-2 mb-2  duration-150
            ease-in-out"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline mr-2 -ml-1 w-5 h-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <p>Añadir</p>
              </Link>
            </div>
          </SectionFilter>
          <div className="lg:grid md:grid md:grid-cols-5  sm:hidden md:gap-6 bg-[#D7D7D7] dark:bg-gray-800 rounded-lg p-4 ">
            <div className="relative z-0 w-full group">
              <label
                htmlFor="fechaInicio"
                className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
              >
                Razón social
              </label>
              <input
                type="text"
                className={StyleInput}
                placeholder=" "
                id="razonSocial"
                name="razonSocial"
                value={formik.values.razonSocial}
                onChange={(e) => handleChangeText(e, 40)}
                onBlur={formik.handleBlur}
              />

              {formik.touched.razonSocial && formik.errors.razonSocial ? (
                <MesageError message={formik.errors.razonSocial} />
              ) : null}
            </div>

            <div className="relative z-0  w-full group">
              <label
                htmlFor="fechaInicio"
                className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
              >
                Nombre comercial
              </label>
              <input
                type="text"
                className={StyleInput}
                placeholder=" "
                id="nombreComercial"
                name="nombreComercial"
                value={formik.values.nombreComercial}
                onChange={(e) => handleChangeText(e, 100)}
                onBlur={formik.handleBlur}
              />

              {formik.touched.nombreComercial &&
              formik.errors.nombreComercial ? (
                <MesageError message={formik.errors.nombreComercial} />
              ) : null}
            </div>

            <div className="relative z-0  w-full group">
              <label
                htmlFor="fechaInicio"
                className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
              >
                RUC
              </label>
              <input
                type="text"
                className={StyleInput}
                placeholder=" "
                id="RUC"
                name="RUC"
                value={formik.values.RUC}
                onChange={(e) => handleChangeText(e, 13)}
                onBlur={formik.handleBlur}
              />

              {formik.touched.RUC && formik.errors.RUC ? (
                <MesageError message={formik.errors.RUC} />
              ) : null}
            </div>

            <div className="relative z-0  w-full group">
              <label
                htmlFor="fechaInicio"
                className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
              >
                Estado
              </label>
              <select
                type="text"
                name="estado"
                id="estado"
                className={StyleSelect}
                placeholder=" "
                defaultValue={formik.values.estado}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Todos</option>
                <option value="True">Activo</option>
                <option value="False">Inactivo</option>
              </select>
            </div>
          </div>
          <div className="mt-4 mx-2 lg:flex md:flex hidden space-x-1">
            <ButtonDefault
              color={"primary"}
              type="submit"
              cargando={cargandoData}
              title="Buscar"
              svg={
                cargandoData ? (
                  <>
                    <svg
                      aria-hidden="true"
                      role="status"
                      className=" w-4 h-4 "
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  </>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className=" h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </>
                )
              }
            />
            <Link
              to="/emision/reporte/edit-empresa"
              className="text-white bg-[#04BC53] hover:bg-green-600 w-auto  focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-600 dark:focus:ring-green-800 dark:hover:bg-green-700 mr-2   duration-150
            ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline mr-2 -ml-1 w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <p>Añadir</p>
            </Link>
          </div>
        </form>
      </div>
      <hr className="border-1 dark:border-gray-800 " />
    </>
  );
};

export default FormListaEmpresas;
