import { CategoriaProvider } from "../../Categorias/context/CategoriaContext";
import { ProductoProvider } from "../context/ProductContext";
import { NuevoProductoSection } from "./NuevoProductoSection";

export const IndexNuevoProducto = () => {
  return (
      <CategoriaProvider>
        <ProductoProvider>
          <NuevoProductoSection />
        </ProductoProvider>
      </CategoriaProvider>
  );
};
