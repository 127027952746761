import { useContext, useState, useEffect } from "react";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../../../features/Constantes";
import * as XLSX from "xlsx";
import { AutoCompleteProduct } from "../Shared/AutoCompleteProduct";
import { handleKeyPress } from "../Ingreso/IngresoDetalle";
import {
  AddIcon,
  DeleteIcon,
  DownloadIcon,
  UploadIcon,
} from "../../../../../Icons/ButtonIcons";
import { AjusteInventarioProviderContext } from "./Context/AjusteInventarioProvider";
import { ThreeDotsIcon } from "../../../../../Icons/ButtonIcons";
import useGlobalToastify from "../../../../../features/hooks/GlobalToastify/useGlobalToastify";

export default function AjusteInventarioDetalle({ methods }) {
  const { setRows, rows, rowBase, ChangeRow, productosPerSecciones } =
    useContext(AjusteInventarioProviderContext);
  const handleAddRow = () => {
    setRows([...rows, { ...rowBase }]);
  };
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [openModal, setOpenModal] = useState(false);

  const { WarningToast } = useGlobalToastify();

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    if (value) {
      newRows[index]["costo"] = value?.costo;
    }
    setRows(newRows);
  };

  const handleChangeProduct = (index, value) => {
    const newRows = [...rows];
    newRows[index]["codigo"] = value;
    setRows(newRows);
  };

  useEffect(() => {
    const newRows = rows.map((row) => {
      row.codigo = "";
      return row;
    });
    setRows(newRows);
  }, [methods.watch("ubicacion")]);

  const exportRows = () => {
    const templatePath = "/Plantilla_AjusteInventario.xlsx";
    fetch(templatePath)
      .then((response) => response.blob())
      .then((templateBlob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const templateData = reader.result;
          const wb = XLSX.read(templateData, { type: "array" });

          const wsName = wb.SheetNames[0];
          const ws = wb.Sheets[wsName];
          const rowsWithProducts = rows.filter((row) =>
            productosPerSecciones.includes(row.codigo)
          );
          const jsonData =
            rowsWithProducts.length === 0
              ? [
                  {
                    "Codigo / Producto": "",
                    "Cuenta Analitica": "",
                    Stock: "",
                    Ajuste: "",
                  },
                ]
              : rowsWithProducts.map((row) => {
                  return {
                    "Codigo / Producto": row.codigo.nombre,
                    "Cuenta Analitica": row.cuenta,
                    Stock: row.codigo.stock,
                    Ajuste: row.ajuste,
                  };
                });
          XLSX.utils.sheet_add_json(ws, jsonData, { origin: "A1" });

          XLSX.writeFile(wb, `Detalles_AjusteInventario.xlsx`);
        };
        reader.readAsArrayBuffer(templateBlob);
      })
      .catch((error) => console.error("Error al cargar la plantilla:", error));
  };

  const importRows = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const xlData = XLSX.utils.sheet_to_json(worksheet, { header: 0 });
      let registrosNoUploaded = [];
      for (const row of xlData) {
        if (Object.values(row).every((value) => value === "")) continue;
        const existingProduct = productosPerSecciones.find(
          (producto) =>
            String(producto.codigo) === String(row["Codigo / Producto"]) ||
            producto.nombre.toLowerCase() ===
              String(row["Codigo / Producto"]).toLowerCase()
        );

        if (!existingProduct) {
          registrosNoUploaded.push(row);
          continue;
        }

        const newRow = {
          codigo: existingProduct,
          cuenta: row["Cuenta Analitica"],
          ajuste: row.Ajuste,
        };

        try {
          setRows((prevState) => [
            ...prevState.filter((row) =>
              Object.values(row).some(
                (value) => value !== "" && value !== null && value !== undefined
              )
            ),
            newRow,
          ]);
        } catch (err) {
          console.log(err);
        }
      }
      if (registrosNoUploaded.length > 0) {
        WarningToast(
          "No se subieron algunos registros que hacen referencia a productos no existentes en la ubicación seleccionada."
        );
      }
    };
    reader.readAsArrayBuffer(file);
    setFileInputKey(Date.now());
  };

  return (
    <>
      <div>
        <div
          name="InformeGeneral"
          className="border border-gray-300 dark:border-gray-600 rounded-lg"
        >
          <div className="bg-[#003B5B] text-white flex flex-row justify-between font-semibold relative rounded-t-lg p-3">
            <h1>Detalle de la Transacción</h1>
            <button
              type="button"
              onClick={() => setOpenModal(!openModal)}
              className=""
            >
              <ThreeDotsIcon />
            </button>
            {openModal && (
              <div className="absolute p-8 flex flex-col shadow-xl bg-white text-black dark:bg-gray-700 dark:text-white z-20 gap-4 right-10 top-0">
                <label
                  className="flex items-center flex-row gap-2"
                  htmlFor="Stock"
                >
                  <input
                    type="checkbox"
                    id="Stock"
                    checked={Object.keys(rowBase).includes("stock")}
                    onChange={() => ChangeRow("stock")}
                  />
                  Stock
                </label>
              </div>
            )}
          </div>
          <div className="p-4">
            <div className="flex flex-row gap-1 text-white font-semibold text-[12px]">
              <button
                type="button"
                onClick={handleAddRow}
                className="p-[13px] rounded-lg bg-blue-600 hover:bg-blue-700 pr-5 pl-5 h-fit flex flex-row gap-2 items-center"
              >
                <span>
                  <AddIcon />
                </span>
                Agregar
              </button>
              <div className="h-fit w-fit">
                <label
                  htmlFor="importInput"
                  className={`p-[13px] rounded-lg ${
                    methods.watch("ubicacion")
                      ? "bg-blue-600 hover:bg-blue-700 cursor-pointer"
                      : "bg-gray-500 cursor-not-allowed"
                  }  pl-5 pr-5 flex flex-row gap-2 items-center`}
                >
                  <span>
                    <UploadIcon />
                  </span>
                  Importar
                </label>
                <input
                  key={fileInputKey}
                  disabled={!methods.watch("ubicacion")}
                  className="absolute -left-[9999px]"
                  id="importInput"
                  onChange={importRows}
                  accept=".xlsx"
                  type="file"
                />
              </div>
              <button
                type="button"
                onClick={exportRows}
                className="p-3 md:pl-8 md:pr-8 pr-5 pl-5 rounded-lg bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center"
              >
                {" "}
                <span>
                  <DownloadIcon />
                </span>
                <span className="hide-on-small">Descargar datos</span>
              </button>
            </div>

            <div className="px-4 pt-4 sm:px-6 lg:px-8">
              <div className=" flex flex-col">
                <div className="-my-2 -mx-4  sm:-mx-6 lg:-mx-8 overflow-x-auto overflow-y-visible">
                  <div className="inline-block min-w-full py-2 align-middle px-4">
                    <div
                      className={
                        TableStyleDiv + " whitespace-nowrap rounded-lg"
                      }
                    >
                      <table className={TableStyle}>
                        <thead
                          className={
                            TableStyleTHead + " bg-gray-200 text-sm divide-x"
                          }
                        >
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 border-2 dark:text-white border-gray-300  dark:border-gray-600"
                            >
                              Codigo / Producto
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 border-2 border-gray-300 dark:text-white dark:border-gray-600"
                            >
                              Cuenta Analitica
                            </th>
                            {Object.keys(rowBase).includes("stock") && (
                              <th
                                scope="col"
                                className="px-3 py-3.5 border-2 border-gray-300 dark:text-white dark:border-gray-600"
                              >
                                Stock
                              </th>
                            )}
                            <th
                              scope="col"
                              className="px-3 py-3.5 border-2 border-gray-300 dark:text-white dark:border-gray-600"
                            >
                              Ajuste
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 border-2 border-gray-300 dark:text-white dark:border-gray-600"
                            >
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody className={TableStyleTBody}>
                          {rows.map((row, index) => (
                            <tr key={index} className="text-center">
                              <td className="p-2 relative border-2 border-gray-300  dark:border-gray-600">
                                <AutoCompleteProduct
                                  value={row.codigo}
                                  onChange={handleChangeProduct}
                                  noOption={"No hay existencias en la sección"}
                                  index={index}
                                  productos={productosPerSecciones}
                                />
                              </td>
                              <td className="border-2 border-gray-300  dark:border-gray-600">
                                <input
                                  type="text"
                                  name="cuenta"
                                  value={row.cuenta}
                                  onChange={(e) => handleChange(index, e)}
                                  className=" dark:bg-gray-800 border-gray-300 dark:text-white  text-center p-1 w-full focus:outline-none focus:border-none"
                                />
                              </td>
                              {Object.keys(rowBase).includes("stock") && (
                                <td className=" border-2 border-gray-300  dark:border-gray-600">
                                  <input
                                    readOnly
                                    type="text"
                                    name="stock"
                                    value={
                                      row.codigo?.stock !== undefined
                                        ? row.codigo?.stock
                                        : ""
                                    }
                                    onChange={(e) => handleChange(index, e)}
                                    className=" opacity-50 border-gray-300 dark:text-white dark:bg-gray-800 text-center  p-1 w-full focus:outline-none focus:border-none"
                                  />
                                </td>
                              )}

                              <td className="border-2 border-gray-300  dark:border-gray-600">
                                <input
                                  type="number"
                                  name="ajuste"
                                  required
                                  min={0}
                                  onKeyPress={handleKeyPress}
                                  value={row.ajuste}
                                  onChange={(e) => handleChange(index, e)}
                                  className=" dark:bg-gray-800 dark:text-white text-center  p-1 w-full focus:outline-none focus:border-none"
                                />
                              </td>
                              <td className="border-2 border-gray-300  dark:border-gray-600">
                                <button
                                  type="button"
                                  onClick={() => {
                                    const newRows = rows.filter(
                                      (_, i) => i !== index
                                    );
                                    setRows(newRows);
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
