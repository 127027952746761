import { useContext } from "react";
import { FormProvider } from "react-hook-form";
import EgresoGeneral from "./EgresoGeneral";
import EgresoDetalle from "./EgresoDetalle";
import { EgresoProviderContext } from "./Context/EgresoProvider";
import { TransaccionButtons } from "../../../Shared/TransaccionButtons";
import ModalEgresoAlertaStock from "./ModalEgresoAlertaStock";

export default function EgresoForm() {
  const {
    methods,
    setRows,
    validateEgreso,
    secuencial,
    rowBase,
    isSending,
    isOpen,
  } = useContext(EgresoProviderContext);

  const DeleteData = () => {
    setRows([{ ...rowBase }]);
    methods.reset();
    methods.setValue("ubicacion", "");
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(validateEgreso)}
          autoComplete="off"
          className="flex flex-col gap-2"
        >
          <div className="bg-white dark:bg-gray-900 p-4 rounded-lg pb-0">
            <div className="bg-gray-200 rounded-lg p-4 flex dark:bg-gray-700 justify-between items-center">
              <p className="font-semibold text-xl ml-3 dark:text-white">
                Transacción
              </p>
              <div className="flex items-center space-x-3">
                <p className="hide-on-small dark:text-white">Egreso</p>
                <input
                  type="text"
                  placeholder="Número de transacción"
                  {...methods.register("numeroTransaccion")}
                  className="border rounded-md border-gray-400 bg-white p-1 w-full text-center"
                  value={secuencial.transaccion ?? ""}
                />
              </div>
            </div>
          </div>
          <div className="p-4 bg-white dark:bg-gray-900 rounded-lg">
            <div className="flex flex-col gap-4">
              <EgresoGeneral />
              <EgresoDetalle methods={methods} />
            </div>
            <TransaccionButtons DeleteData={DeleteData} isSending={isSending} />
          </div>
        </form>
      </FormProvider>
      {isOpen && <ModalEgresoAlertaStock />}
    </>
  );
}
