import { useContext, useState, useEffect } from "react";
import { InputGlobal } from "../../context/inputContext";
import AutoCompleteInput from "../../context/AutoCompleteSelect";
import { ProductoContext } from "../context/ProductContext";
import { AddIcon } from "../../../../Icons/ButtonIcons";
import { useFormContext } from "react-hook-form";
import { ProductoCompuestoItem } from "./ProductoCompuestoItem";
import { v4 as uuidv } from "uuid";
import { handleKeyPress } from "../../TiposOperaciones/Transacciones/Ingreso/IngresoDetalle";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { useSelector } from "react-redux";
import { ObtenerProductosSimples } from "../../../../services/InventarioService";

export const NuevoProductoCompuestoForm = ({
  productosCompuestos,
  setProductosCompuestos,
}) => {
  const [cantidad, setCantidad] = useState();
  const { watch, setValue } = useFormContext();
  const { WarningToast } = useGlobalToastify();
  const [productosSimples, setProductosSimples] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);

  const dataForm = watch();

  useEffect(() => {
    setValue("producto", "");
    setCantidad("");
  }, []);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerProductosSimples(
        "/api/producto/obtener-productos-simples",
        empresa.idEmpresa
      ).then((res) => setProductosSimples(res.data));
    }
  }, [empresa]);

  const searchProducto = async (inputText) => {
    return productosSimples.filter(
      (producto) =>
        String(producto.codigo).toLowerCase() ===
          String(inputText).toLowerCase() ||
        String(producto.nombre).toLowerCase() ===
          String(inputText).toLowerCase() ||
        String(producto.codigo)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(producto.nombre)
          .toLowerCase()
          .includes(String(inputText).toLowerCase())
    );
  };

  const saveProductoIndividual = () => {
    if (!dataForm.producto || !cantidad) {
      WarningToast(
        "Debe rellenar todos los campos para agregar un producto simple."
      );
      return;
    }
    setValue("producto", "");
    setCantidad("");
    if (
      productosCompuestos
        .map((producto) => producto.idProducto)
        .includes(dataForm.producto.idProducto)
    ) {
      setProductosCompuestos((prevState) => [
        ...prevState.map((producto) => {
          if (producto.idProducto === dataForm.producto.idProducto) {
            return {
              ...producto,
              cantidad: Number(cantidad),
            };
          } else return producto;
        }),
      ]);
    } else {
      setProductosCompuestos((prevState) => [
        ...prevState,
        {
          ...dataForm.producto,
          cantidad: Number(cantidad),
        },
      ]);
    }
  };

  const deleteSubProduct = (id) => {
    setProductosCompuestos((prevState) =>
      prevState.filter((producto) => producto.idProducto !== id)
    );
  };

  return (
    <section>
      {" "}
      <div className="flex flex-col h-fit border-gray-200 dark:border-gray-600 border rounded-t-lg rounded-b-lg ">
        <p className="bg-[#003B5B] text-white flex items-center flex-row gap-2 font-semibold rounded-t-lg p-3">
          Producto Compuesto
        </p>
        <div className="w-full flex md:flex-row flex-col p-4 gap-4 items-center">
          <div className="flex md:w-1/2 w-full lg:flex-row flex-col md:gap-3 gap-1 items-center p-4 pl-8 pr-8 text-[12px]">
            <div className="w-full grid grid-cols-1 gap-6 p-5 items-center h-fit">
              <AutoCompleteInput
                title={"Producto"}
                searchFn={searchProducto}
                option={(e) =>
                  `${String(e["codigo"])} - ${String(e["nombre"])}`
                }
                array={productosSimples}
                identificador={"idProducto"}
                parametro={"nombre"}
              />
              <InputGlobal
                title={"Cantidad"}
                min={1}
                pressfn={handleKeyPress}
                type={"number"}
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
                name={"cantidadCompuesto"}
              />
            </div>
            <button
              type="button"
              onClick={saveProductoIndividual}
              className="p-2 h-11 md:mt-5 mt-2 rounded-lg w-fit text-[12px] text-white bg-blue-600 hover:bg-blue-700 pr-6 pl-6 flex flex-row gap-2 whitespace-nowrap  items-center"
            >
              <span>
                <AddIcon />
              </span>
              Agregar P.C
            </button>
          </div>
          {productosCompuestos.length > 0 && (
            <div className="flex-1 md:w-1/2 w-full mr-6 sm:ml-6">
              <table className="self-center w-full border-2 border-gray-300 dark:border-gray-600 text-[12px]">
                <thead>
                  <tr className="bg-gray-200 dark:bg-gray-700 dark:text-white text-center">
                    <th style={{ padding: "15px" }}>Codigo</th>
                    <th>Producto</th>
                    <th>Cantidad</th>
                    <th style={{ padding: "15px" }}>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {productosCompuestos.map((producto) => (
                    <ProductoCompuestoItem
                      key={producto.idProducto}
                      producto={producto}
                      deleteFn={deleteSubProduct}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
