import { useFormik } from "formik";
import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { getList } from "../../../services";

export const EstablecimientosContext = createContext();

const EstablecimientosProvider = (props) => {
  const [EditEstablecimiento, setEditEstablecimiento] = useState({});
  const [PuntosEmisionList, setPuntosEmisionList] = useState([]);
  const [EstablecimientoIdPtoEmision, setEstablecimientoIdPtoEmision] =
    useState("");
  const [cargandoData, setCargandoData] = useState(false);
  const [idPTESecuencial, setidPTESecuencial] = useState("");

  const empresa = useSelector((store) => store.empresa.empresa);
  const [start, setStart] = useState(0);

  const initialStatePaginacion = {
    pagina: 1,
    _totalpaginas: 1,
    _totalelementos: 0,
    _links: [],
    _inicioRegistro: 0,
    _totalEnPagina: 0,
  };

  const [Paginacion, setPaginacion] = useState(initialStatePaginacion);
  const [documentos, setDocumentos] = useState([]);

  const formik = useFormik({
    initialValues: {
      codigoPrincipal: "",
      descripcion: "",
      estado: "",
      cantidad: 50,
      tipo: 0,
      establecimiento: "",
    },
    validationSchema: Yup.object({
      codigoPrincipal: Yup.string(),
      descripcion: Yup.string(),
    }),
    onSubmit: async (obj) => {
      try {
        await fetchData(
          obj.codigoPrincipal,
          obj.descripcion,
          obj.estado,
          obj.cantidad,
          obj.tipo,
          obj.establecimiento
        );
      } catch (error) {
        //   console.log(error);
      }
    },
  });

  useEffect(() => {
    (async () => await fetchData())();
  }, [formik.values.cantidad, start]);

  const fetchData = async (
    codigoPrincipal = "",
    descripcion = "",
    estado = "",
    cantidad = formik.values.cantidad,
    tipo = 0,
    establecimiento
  ) => {
    try {
      setCargandoData(true);
      let res = await getList(
        `api/Establecimientos/ObtenerTodosEstablecimientosPorEmpresa/${empresa.idEmpresa}?&Establecimiento=&Pagina=${Paginacion.pagina}&start=${start}&Cantidad=${cantidad}`
      );

      if (!(res === undefined)) {
        setPaginacion({
          ...Paginacion,
          _totalpaginas: res._totalpaginas,
          _totalelementos: res._totalelementos,
          _links: res._links,
          _inicioRegistro: res._inicioRegistro,
          _totalEnPagina: res._totalEnPagina,
        });
        setDocumentos(res._embedded);
      }
    } catch (error) {
      setPaginacion(initialStatePaginacion);
      setDocumentos([]);
      setCargandoData(false);

      // console.log(error);
    }
    setCargandoData(false);
  };

  return (
    <EstablecimientosContext.Provider
      value={{
        EditEstablecimiento,
        setEditEstablecimiento,
        PuntosEmisionList,
        setPuntosEmisionList,
        EstablecimientoIdPtoEmision,
        setEstablecimientoIdPtoEmision,
        Paginacion,
        setPaginacion,
        cargandoData,
        setCargandoData,
        documentos,
        setDocumentos,
        formik,
        start,
        setStart,
        fetchData,
        idPTESecuencial,
        setidPTESecuencial,
      }}
    >
      {props.children}
    </EstablecimientosContext.Provider>
  );
};

export default EstablecimientosProvider;
