import { Menu, Transition } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const EmisionElectronica = (props) => {
  const [isPagesMenuOpen, setIsPagesMenuOpen] = useState(false);

  const [isPagesMenuOpen2, setIsPagesMenuOpen2] = useState(false);
  let activeClassName =
    "  w-full inline-block px-12 bg-[#5F7F93] truncate text-white py-3 transition-colors duration-300   dark:hover:text-gray-200";

  const permisos = useSelector((store) => store.permisosEmpresa.permisos);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // useEffect(() => {
  //   console.log(props.items);
  // }, []);

  const [isPagesSubMenu1, setIsPagesSubMenu1] = useState(false);
  const [isPagesSubMenu2, setIsPagesSubMenu2] = useState(false);

  const AbrirSubMenu1 = () => {
    setIsPagesSubMenu1(!isPagesSubMenu1);
  };

  const AbrirSubMenu2 = () => {
    setIsPagesSubMenu2(!isPagesSubMenu2);
  };

  const menus = (codigo, index) => {
    switch (codigo) {
      case "04":
        return (
          <NavLink
            key={codigo}
            title="Liquidación de compra"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/generar/liquidacion"
          >
            Liquidación de compra
          </NavLink>
        );
      case "05":
        return (
          <NavLink
            key={codigo}
            title="Guía remisión"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white  truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/generar/guiaRemision"
          >
            Guía remisión
          </NavLink>
        );
      case "13":
        return (
          <NavLink
            key={codigo}
            title="Factura"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white  truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/generar/factura"
          >
            Factura
          </NavLink>
        );
      case "19":
        return (
          <NavLink
            key={codigo}
            title="Nota de crédito"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white  truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/generar/notaCredito"
          >
            Nota de crédito
          </NavLink>
        );

      case "17":
        return (
          <NavLink
            key={codigo}
            title="Nota de débito"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white  truncate hover:  w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/generar/notadDebito"
          >
            Nota de débito
          </NavLink>
        );
      case "21":
        return (
          <NavLink
            key={codigo}
            title="Retención"
            className={({ isActive }) =>
              isActive
                ? activeClassName
                : "  py-3 px-12 transition-colors hover:bg-[#5F7F93] hover:text-white  truncate hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
            }
            exact="true"
            to="/emision/generar/retencion"
          >
            Retención
          </NavLink>


        );
      default:
        return null;
    }
  };

  // const hanldeclick = () => {
  //   setIsPagesMenuOpen(!isPagesMenuOpen);
  // };

  const hanldeclick2 = () => {
    setIsPagesMenuOpen2(!isPagesMenuOpen2);
  };

  return (
    <ul
      className="pt-2 text-white"
      title={!props.OpenMenu ? "Emision Documentos" : ""}
    >
      {props.OpenMenu ? (
        <Menu>
          <Menu.Button
            className={"w-full"}
            onClick={props.OpenMenu ? () => props.toggleItem("item1") : <></>}
          >
            <li className=" text-sm flex justify-between items-center  hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <div className="flex gap-x-4 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                  />
                </svg>

                <span
                  className={`${!props.OpenMenu && "hidden"
                    } origin-left duration-200`}
                >
                  {props.modulo}
                </span>
              </div>

              <span
                className={`${!props.OpenMenu && "hidden"
                  } origin-left duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-4 h-4 ${isPagesMenuOpen
                    ? "rotate-180 duration-300"
                    : "rotate-0 duration-300"
                    }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </li>
          </Menu.Button>
        </Menu>
      ) : (
        <Menu>
          <Menu.Button className={"w-full lg:table-cell hidden"}>
            <li className="  text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                />
              </svg>

              <span
                className={`${!props.OpenMenu && "hidden"
                  } origin-left duration-200`}
              >
                {props.modulo}
              </span>
              <span
                className={`${!props.OpenMenu && "hidden"
                  } origin-left duration-200`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`w-4 h-4 ${isPagesMenuOpen
                    ? "rotate-180 duration-300"
                    : "rotate-0 duration-300"
                    }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </span>
            </li>
          </Menu.Button>
     
        </Menu>
      )}

      {props.openMobil ? (
        <ul className="" onClick={hanldeclick2}>
          <li className=" text-sm flex items-center text-gray-300 hover:text-black gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
              />
            </svg>

            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              {props.modulo}
            </span>
            <span
              className={`${!props.OpenMenu && ""} origin-left duration-200`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className={`w-4 h-4 ${isPagesMenuOpen2
                  ? "rotate-180 duration-300"
                  : "rotate-0 duration-300"
                  }`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </li>
        </ul>
      ) : null}

      {props.openItem === "item1" && (
        <ul
          className={` mt-2 bg-[#003B5B] w-full    overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900 ${!props.OpenMenu && "hidden"
            }`}
          aria-label="submenu"
        >
          {props.items.map((item, index) => menus(item.codigo, index))}

        </ul>
      )}

      {isPagesMenuOpen2 ? (
        <ul
          className={`p-2 mt-2 bg-[#003B5B] space-y-2 overflow-hidden text-sm font-medium text-white duration-1000 delay-300 rounded-md shadow-inner  dark:text-gray-400 dark:bg-gray-900
          `}
          aria-label="submenu"
        >
          {props.items.map((item, index) => menus(item.codigo, index))}
        </ul>
      ) : null}
    </ul>
  );
};

export default EmisionElectronica;
