import { useContext, useState, useEffect } from "react";

import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../../../features/Constantes";
import * as XLSX from "xlsx";
import {
  AddIcon,
  DeleteIcon,
  DownloadIcon,
  UploadIcon,
} from "../../../../../Icons/ButtonIcons";
import { TransferenciasProviderContext } from "./Context/TransferenciasProvider";
import { ThreeDotsIcon } from "../../../../../Icons/ButtonIcons";
import { AutoCompleteProduct } from "../Shared/AutoCompleteProduct";
import { handleKeyPress } from "../Ingreso/IngresoDetalle";
import useGlobalToastify from "../../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { useSelector } from "react-redux";
import { ObtenerConfiguracionInventarioEmpresa } from "../../../../../services/InventarioService";

export default function TransferenciasDetalle({ methods }) {
  const { setRows, rows, rowBase, ChangeRow, productosPerSecciones } =
    useContext(TransferenciasProviderContext);
  const handleAddRow = () => {
    setRows([...rows, { ...rowBase }]);
  };
  const [openModal, setOpenModal] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const empresa = useSelector((state) => state.empresa.empresa);

  const [trazabilidadDisponible, setTrazabilidadDisponible] = useState([]);
  const { WarningToast } = useGlobalToastify();

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };
  const handleChangeProduct = (index, value) => {
    const newRows = [...rows];
    newRows[index]["codigo"] = value;
    if (value) {
      newRows[index]["costo"] = value?.costo;
    }
    setRows(newRows);
  };

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerConfiguracionInventarioEmpresa(
        "/api/trazabilidad/obtener-trazabilidad-empresa",
        empresa.idEmpresa
      ).then((response) => setTrazabilidadDisponible(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    const newRows = rows.map((row) => {
      row.codigo = "";
      return row;
    });
    setRows(newRows);
  }, [methods.watch("ubicacionEnvia")]);

  const exportRows = () => {
    const templatePath = "/Plantilla_Transaccion.xlsx";
    fetch(templatePath)
      .then((response) => response.blob())
      .then((templateBlob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const templateData = reader.result;
          const wb = XLSX.read(templateData, { type: "array" });

          const wsName = wb.SheetNames[0];
          const ws = wb.Sheets[wsName];
          const rowsWithProducts = rows.filter((row) =>
            productosPerSecciones.includes(row.codigo)
          );
          const jsonData =
            rowsWithProducts.length === 0
              ? [
                  {
                    "Codigo / Producto": "",
                    "Cuenta Analitica": "",
                    Cantidad: "",
                    Costo: "",
                    Lote: "",
                    "Fecha de Expiración": "",
                  },
                ]
              : rowsWithProducts.map((row) => {
                  return {
                    "Codigo / Producto": row.codigo.nombre,
                    "Cuenta Analitica": row.cuenta,
                    Cantidad: row.cantidad,
                    Costo: row.codigo.costoPromedio,
                    Lote: row.lote,
                    "Fecha de Expiración": row.fechaCaducidad,
                  };
                });
          XLSX.utils.sheet_add_json(ws, jsonData, { origin: "A1" });

          XLSX.writeFile(wb, `Detalles_Transferencia.xlsx`);
        };
        reader.readAsArrayBuffer(templateBlob);
      })
      .catch((error) => console.error("Error al cargar la plantilla:", error));
  };

  const importRows = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const xlData = XLSX.utils.sheet_to_json(worksheet, { header: 0 });
      let registrosNoUploaded = [];
      for (const row of xlData) {
        if (Object.values(row).every((value) => value === "")) continue;
        const existingProduct = productosPerSecciones.find(
          (producto) =>
            String(producto.codigo) === String(row["Codigo / Producto"]) ||
            producto.nombre.toLowerCase() ===
              String(row["Codigo / Producto"]).toLowerCase()
        );
        if (!existingProduct) {
          registrosNoUploaded.push(row);
          continue;
        }

        const newRow = {
          codigo: existingProduct,
          cuenta: row["Cuenta Analitica"],
          cantidad: row["Cantidad"],
          costo: existingProduct.costo,
          lote: row.Lote ? row.Lote : "",
          fechaCaducidad: row["Fecha de Expiración"]
            ? row["Fecha de Expiración"]
            : "",
        };

        try {
          setRows((prevState) => [
            ...prevState.filter((row) =>
              Object.values(row).some(
                (value) => value !== "" && value !== null && value !== undefined
              )
            ),
            newRow,
          ]);
        } catch (err) {
          console.log(err);
        }
      }
      if (registrosNoUploaded.length > 0) {
        WarningToast(
          "No se subieron algunos registros que hacen referencia a productos no existentes o con stock vacio en la ubicación que envía seleccionada."
        );
      }
    };
    reader.readAsArrayBuffer(file);
    setFileInputKey(Date.now());
  };

  return (
    <>
      <div>
        <div
          name="InformeGeneral"
          className="border border-gray-300 dark:border-gray-600 rounded-lg"
        >
          <div className="bg-[#003B5B] text-white flex flex-row justify-between font-semibold relative rounded-t-lg p-3">
            <h1>Detalle de la Transacción</h1>
            <button
              type="button"
              onClick={() => setOpenModal(!openModal)}
              className=""
            >
              <ThreeDotsIcon />
            </button>
            {openModal && (
              <div className="absolute p-8 flex flex-col shadow-xl bg-white dark:bg-gray-700 dark:text-white text-black z-20 gap-4 right-10 top-0">
                <label
                  className="flex items-center flex-row gap-2"
                  htmlFor="Costo"
                >
                  <input
                    type="checkbox"
                    id="Costo"
                    checked={Object.keys(rowBase).includes("costo")}
                    onChange={() => ChangeRow("costo")}
                  />
                  Costo Promedio
                </label>
                <label
                  className="flex items-center flex-row gap-2"
                  htmlFor="Lote"
                >
                  <input
                    type="checkbox"
                    id="Lote"
                    disabled={
                      !trazabilidadDisponible.includes(
                        "dc1bb646-0470-4eac-95cb-8f0fb4a3e558"
                      )
                    }
                    checked={Object.keys(rowBase).includes("lote")}
                    onChange={() => ChangeRow("lote")}
                  />
                  Lote
                </label>
                <label
                  className="flex items-center flex-row gap-2"
                  htmlFor="Fecha de Caducidad"
                >
                  <input
                    type="checkbox"
                    id="Fecha de Caducidad"
                    disabled={
                      !trazabilidadDisponible.includes(
                        "db8ef384-8263-4831-be1e-3dd5a4bcba90"
                      )
                    }
                    checked={Object.keys(rowBase).includes("fechaCaducidad")}
                    onChange={() => ChangeRow("fechaCaducidad")}
                  />
                  Fecha de Expiración
                </label>
              </div>
            )}
          </div>
          <div className="p-4">
            <div className="flex flex-row gap-1 text-white font-semibold text-[12px]">
              <button
                type="button"
                onClick={handleAddRow}
                className="p-[13px] rounded-lg bg-blue-600 hover:bg-blue-700 pr-5 pl-5 h-fit flex flex-row gap-2 items-center"
              >
                <span>
                  <AddIcon />
                </span>
                Agregar
              </button>
              <div className="h-fit w-fit">
                <label
                  htmlFor="importInput"
                  className={`p-[13px] rounded-lg ${
                    methods.watch("ubicacionEnvia")
                      ? "bg-blue-600 hover:bg-blue-700 cursor-pointer"
                      : "bg-gray-500 cursor-not-allowed"
                  }  pl-5 pr-5 flex flex-row gap-2 items-center`}
                >
                  <span>
                    <UploadIcon />
                  </span>
                  Importar
                </label>
                <input
                  key={fileInputKey}
                  disabled={!methods.watch("ubicacionEnvia")}
                  className="absolute -left-[9999px]"
                  id="importInput"
                  onChange={importRows}
                  accept=".xlsx"
                  type="file"
                />
              </div>
              <button
                type="button"
                onClick={exportRows}
                className="p-3 md:pl-8 md:pr-8 pr-5 pl-5 rounded-lg bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center"
              >
                {" "}
                <span>
                  <DownloadIcon />
                </span>
                <span className="hide-on-small">Descargar datos</span>
              </button>
            </div>

            <div className="px-4 pt-4 sm:px-6 lg:px-8">
              <div className=" flex flex-col">
                <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-auto overflow-y-visible">
                  <div className="inline-block min-w-full py-2 align-middle px-4 overflow-x-auto overflow-y-visible">
                    <div
                      className={
                        TableStyleDiv + " whitespace-nowrap rounded-lg"
                      }
                    >
                      <table className={TableStyle}>
                        <thead
                          className={
                            TableStyleTHead + " bg-gray-200 text-sm divide-x"
                          }
                        >
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                            >
                              Codigo / Producto
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                            >
                              Cuenta Analitica
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                            >
                              Cantidad
                            </th>
                            {Object.keys(rowBase).includes("costo") && (
                              <th
                                scope="col"
                                className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                              >
                                Costo Promedio
                              </th>
                            )}
                            {trazabilidadDisponible.includes(
                              "dc1bb646-0470-4eac-95cb-8f0fb4a3e558"
                            ) &&
                              Object.keys(rowBase).includes("lote") && (
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                                >
                                  Lote
                                </th>
                              )}
                            {trazabilidadDisponible.includes(
                              "db8ef384-8263-4831-be1e-3dd5a4bcba90"
                            ) &&
                              Object.keys(rowBase).includes(
                                "fechaCaducidad"
                              ) && (
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                                >
                                  Fecha de Expiración
                                </th>
                              )}
                            <th
                              scope="col"
                              className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                            >
                              Eliminar
                            </th>
                          </tr>
                        </thead>
                        <tbody className={TableStyleTBody}>
                          {rows.map((row, index) => (
                            <tr key={index} className="text-center">
                              <td className="p-2 relative border-2 border-gray-300 dark:border-gray-600">
                                <AutoCompleteProduct
                                  productos={productosPerSecciones}
                                  value={row.codigo}
                                  noOption={"No hay existencias en la sección"}
                                  onChange={handleChangeProduct}
                                  index={index}
                                />
                              </td>
                              <td className="border-2 border-gray-300  dark:border-gray-600">
                                <input
                                  type="text"
                                  name="cuenta"
                                  value={row.cuenta}
                                  onChange={(e) => handleChange(index, e)}
                                  className=" border-gray-300 dark:bg-gray-800 dark:text-white  text-center p-1 w-full focus:outline-none focus:border-none"
                                />
                              </td>
                              <td
                                className={`border-2 border-gray-300  dark:border-gray-600 relative`}
                              >
                                <input
                                  required
                                  type="number"
                                  name="cantidad"
                                  min={1}
                                  max={
                                    row.codigo?.stock
                                      ? row.codigo?.stock
                                      : undefined
                                  }
                                  onKeyPress={handleKeyPress}
                                  value={
                                    row.cantidad
                                      ? Math.abs(parseFloat(row.cantidad))
                                      : ""
                                  }
                                  className={`border-gray-300 dark:bg-gray-800 dark:text-white text-center p-1 w-[200px] focus:outline-none focus:border-none`}
                                  onInvalid={(e) => {
                                    if (e.target.value > row.codigo?.stock) {
                                      e.target.setCustomValidity(
                                        `La cantidad no puede exceder el stock disponible (${row.codigo?.stock})`
                                      );
                                    } else {
                                      e.target.setCustomValidity("");
                                    }
                                  }}
                                  onInput={(e) =>
                                    e.target.setCustomValidity("")
                                  }
                                  onChange={(e) => handleChange(index, e)}
                                />
                                {row.codigo?.stock && (
                                  <span
                                    className={`absolute text-[10px] select-none ${
                                      row.codigo?.isCompuesto === false
                                        ? rows.reduce((accumulator, value) => {
                                            if (
                                              value.codigo?.idProducto ===
                                              row.codigo?.idProducto
                                            ) {
                                              return (
                                                accumulator +
                                                Number(value.cantidad)
                                              );
                                            } else if (
                                              value.codigo?.subProductos
                                                ?.length > 0 &&
                                              value.codigo?.subProductos
                                                .map((sp) => sp.idSubProducto)
                                                .includes(
                                                  row.codigo?.idProducto
                                                )
                                            ) {
                                              const subProducto =
                                                value.codigo?.subProductos.find(
                                                  (sp) =>
                                                    sp.idSubProducto ===
                                                    row.codigo?.idProducto
                                                );
                                              return subProducto
                                                ? accumulator +
                                                    Number(value.cantidad) *
                                                      Number(
                                                        subProducto.cantidad
                                                      )
                                                : accumulator;
                                            }
                                            return accumulator;
                                          }, 0) > row?.codigo?.stock
                                          ? "text-red-600"
                                          : "text-gray-500"
                                        : Math.min(
                                            ...row.codigo.subProductos.map(
                                              (sp) => {
                                                const subProductoStock =
                                                  productosPerSecciones.find(
                                                    (r) =>
                                                      r.idProducto ===
                                                      sp.idSubProducto
                                                  )?.stock || 0;
                                                const cantidadUsada =
                                                  rows.reduce((acc, value) => {
                                                    if (
                                                      value.codigo
                                                        ?.idProducto ===
                                                      sp.idSubProducto
                                                    ) {
                                                      return (
                                                        acc +
                                                        Number(value.cantidad)
                                                      );
                                                    }
                                                    return acc;
                                                  }, 0);

                                                const stockDisponible =
                                                  Number(subProductoStock) -
                                                  Number(cantidadUsada);

                                                if (stockDisponible < 0)
                                                  return 0;

                                                return Math.floor(
                                                  Number(
                                                    stockDisponible /
                                                      sp.cantidad
                                                  )
                                                );
                                              }
                                            )
                                          ) -
                                            rows.reduce(
                                              (accumulator, value) => {
                                                if (
                                                  value.codigo?.idProducto ===
                                                  row.codigo?.idProducto
                                                ) {
                                                  return (
                                                    accumulator +
                                                    Number(value.cantidad)
                                                  );
                                                }
                                                return accumulator;
                                              },
                                              0
                                            ) <
                                          0
                                        ? "text-red-600"
                                        : "text-gray-500"
                                    }  bottom-1 left-1.5`}
                                  >
                                    Stock Disponible:{" "}
                                    {row.codigo?.isCompuesto === true
                                      ? Math.max(
                                          Math.min(
                                            ...row.codigo.subProductos.map(
                                              (sp) => {
                                                const subProductoStock =
                                                  productosPerSecciones.find(
                                                    (r) =>
                                                      r.idProducto ===
                                                      sp.idSubProducto
                                                  )?.stock || 0;
                                                const cantidadUsada =
                                                  rows.reduce((acc, value) => {
                                                    if (
                                                      value.codigo
                                                        ?.idProducto ===
                                                      sp.idSubProducto
                                                    ) {
                                                      return (
                                                        acc +
                                                        Number(value.cantidad)
                                                      );
                                                    }
                                                    return acc;
                                                  }, 0);

                                                const stockDisponible =
                                                  Number(subProductoStock) -
                                                  Number(cantidadUsada);

                                                if (stockDisponible < 0)
                                                  return 0;

                                                return Math.floor(
                                                  Number(
                                                    stockDisponible /
                                                      sp.cantidad
                                                  )
                                                );
                                              }
                                            )
                                          ) -
                                            rows.reduce(
                                              (accumulator, value) => {
                                                if (
                                                  value.codigo?.idProducto ===
                                                  row.codigo?.idProducto
                                                ) {
                                                  return (
                                                    accumulator +
                                                    Number(value.cantidad)
                                                  );
                                                }
                                                return accumulator;
                                              },
                                              0
                                            ),
                                          0
                                        )
                                      : Math.max(
                                          row?.codigo?.stock -
                                            rows.reduce(
                                              (accumulator, value) => {
                                                if (
                                                  value.codigo?.idProducto ===
                                                  row.codigo?.idProducto
                                                ) {
                                                  return (
                                                    accumulator +
                                                    Number(value.cantidad)
                                                  );
                                                } else if (
                                                  value.codigo?.subProductos
                                                    ?.length > 0 &&
                                                  value.codigo?.subProductos
                                                    .map(
                                                      (sp) => sp.idSubProducto
                                                    )
                                                    .includes(
                                                      row.codigo?.idProducto
                                                    )
                                                ) {
                                                  const subProducto =
                                                    value.codigo?.subProductos.find(
                                                      (sp) =>
                                                        sp.idSubProducto ===
                                                        row.codigo?.idProducto
                                                    );
                                                  return subProducto
                                                    ? accumulator +
                                                        Number(value.cantidad) *
                                                          Number(
                                                            subProducto.cantidad
                                                          )
                                                    : accumulator;
                                                }
                                                return accumulator;
                                              },
                                              0
                                            ),
                                          0
                                        )}
                                  </span>
                                )}
                              </td>
                              {Object.keys(rowBase).includes("costo") && (
                                <td className=" border-2 border-gray-300  dark:border-gray-600">
                                  <input
                                    type="text"
                                    name="costo"
                                    required
                                    readOnly
                                    value={
                                      row.codigo?.costoPromedio
                                        ? row.codigo?.costoPromedio
                                        : ""
                                    }
                                    onChange={(e) => handleChange(index, e)}
                                    className=" opacity-50 dark:bg-gray-800 dark:text-white  border-gray-300 text-center  p-1 w-full focus:outline-none focus:border-none"
                                  />
                                </td>
                              )}
                              {trazabilidadDisponible.includes(
                                "dc1bb646-0470-4eac-95cb-8f0fb4a3e558"
                              ) &&
                                Object.keys(rowBase).includes("lote") && (
                                  <td className=" border-2 border-gray-300  dark:border-gray-600">
                                    <input
                                      type="text"
                                      name="lote"
                                      required={
                                        row.codigo?.isLote
                                          ? row.codigo?.isLote
                                          : false
                                      }
                                      onInvalid={(e) => {
                                        if (
                                          row.codigo.isLote === true &&
                                          e.target.value === ""
                                        ) {
                                          e.target.setCustomValidity(
                                            "Este producto requiere control de Lote"
                                          );
                                        }
                                      }}
                                      onInput={(e) =>
                                        e.target.setCustomValidity("")
                                      }
                                      value={row.lote}
                                      onChange={(e) => handleChange(index, e)}
                                      className=" dark:bg-gray-800 border-gray-300 dark:text-white text-center  p-1 w-full focus:outline-none focus:border-none"
                                    />
                                  </td>
                                )}
                              {trazabilidadDisponible.includes(
                                "db8ef384-8263-4831-be1e-3dd5a4bcba90"
                              ) &&
                                Object.keys(rowBase).includes(
                                  "fechaCaducidad"
                                ) && (
                                  <td className=" border-2 border-gray-300  dark:border-gray-600">
                                    <input
                                      type="date"
                                      name="fechaCaducidad"
                                      required={
                                        row.codigo?.isFechaCaducidad
                                          ? row.codigo?.isFechaCaducidad
                                          : false
                                      }
                                      onInvalid={(e) => {
                                        if (
                                          row.codigo.isFechaCaducidad ===
                                            true &&
                                          e.target.value === ""
                                        ) {
                                          e.target.setCustomValidity(
                                            "Este producto requiere control de Fecha de Expiración"
                                          );
                                        }
                                      }}
                                      onInput={(e) =>
                                        e.target.setCustomValidity("")
                                      }
                                      value={row.fechaCaducidad}
                                      onChange={(e) => handleChange(index, e)}
                                      className=" dark:bg-gray-800 border-gray-300 text-center dark:text-white  p-1 w-full focus:outline-none focus:border-none"
                                    />
                                  </td>
                                )}
                              <td className="border-2 border-gray-300 dark:border-gray-600">
                                <button
                                  type="button"
                                  onClick={() => {
                                    const newRows = rows.filter(
                                      (_, i) => i !== index
                                    );
                                    setRows(newRows);
                                  }}
                                >
                                  <DeleteIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
