import EgresoProvider from "./Context/EgresoProvider";
import EgresoForm from "./EgresoForm";
import { ProductoProvider } from "../../../Productos/context/ProductContext";
import { TransaccionesProvider } from "../../TransaccionesEmitidas/context/TransaccionesContext";

export default function Egreso() {
  return (
    <>
        <TransaccionesProvider>
          <EgresoProvider>
            <EgresoForm />
          </EgresoProvider>
        </TransaccionesProvider>
    </>
  );
}
