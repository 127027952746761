import { Transition } from "@headlessui/react";
import { useState } from "react";

export const SectionFilter = ({
  title,
  children,
  consumer,
  ConsumidorFinalToggle,
  setConsumidorFinalToggle,
}) => {
  const [open, setOpen] = useState(true);
  return (
    <div className="lg:hidden md:hidden mx-2">
      <div
        className="bg-[#00314D] dark:bg-gray-900 px-2 py-2 rounded-t-lg flex items-center justify-between"
        onClick={() => setOpen(!open)}
      >
        <p className="text-white dark:text-slate-200 text-sm">{title}</p>
        <div className="flex space-x-2 items-center">
          {consumer && (
            <>
              <p className="text-white text-sm">Consumidor Final</p>
              <input
                className=""
                type="checkbox"
                name="consumidorFinal"
                checked={ConsumidorFinalToggle}
                onChange={(e) => setConsumidorFinalToggle(e.target.checked)}
                id="consumidorFinal"
              />
            </>
          )}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`w-4 h-4 text-white ${open ? "transform rotate-180" : ""}`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </div>
      {/* {open && <div className="bg-[#DBDBDB]">{children}</div>} */}
      <Transition
        show={open}
        enter="transition ease-out duration-300" // Aumenta la duración de entrada
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300" // Aumenta la duración de salida
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="bg-[#DBDBDB] border rounded-b-lg dark:border-gray-600 dark:bg-gray-700 pb-2" ref={ref}>
            {children}
          </div>
        )}
      </Transition>
    </div>
  );
};
