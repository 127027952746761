import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyleInput, StyleSelect } from "../../../features/Constantes";

//import { useFormikContext } from 'formik';

import { getListV2 } from "../../../services";
import { EmitirLiquidacionContext } from "./EmitirLiquidacionProvider";
import {
  getFechaV1,
  getNoventaDiasAtras,
  rellenarCeros,
  roundToTwo,
} from "../../../services/Utilitario";
import Contribuyente from "../../../features/components/Contribuyente";
import { TotalInicial } from "../../../features/bl/DocumentSharedBL";
import useReembolso from "../../../features/hooks/Reembolso/useReembolso";
import useVistaPrevia from "../hooks/useVistaPrevia";
import MobileButtonOptionsLiq from "./MobileButtonOptionsLiq";
import { toast } from "react-toastify";
import { CalcularImpuestosList } from "../EmitirFactura/services/impuestos";
import { ModalHours } from "../../../features/hooks/ModalHours";
const  FormFactura = () => {
  const {
    formik,
    DetalleList,
    ValorTotal,
    setTotales,
    setDetalleList,
    setFormaPagoList,
    setInformacionAdicionalList,
    Totales,
    ActiveReembolso,
    ReembolsoList,
    InformacionAdicionalList,
    FormaPagoList,
    handleDeleteElement,
    isOpenModalHours,
    toggleModal,
  } = useContext(EmitirLiquidacionContext);

  const { ActiveReembolsoButton } = useReembolso();

  const empresa = useSelector((store) => store.empresa.empresa);

  const { GetVistaPreviaLiquidacion, IsLoading } = useVistaPrevia();

  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);

  const [estabTmp, setEstabTmp] = useState("###");
  const [pteTmp, setPteTmp] = useState("###");

  useEffect(() => {
    (async () => {
      //ObtenerEstablecimientosPorEmpresa
      const GetAllEstablecimientos = await getListV2(
        empresa,
        "ObtenerEstablecimientosPorEmpresa"
      );
      if (GetAllEstablecimientos._embedded.length > 0) {
        formik.setFieldValue(
          "establecimiento",
          GetAllEstablecimientos._embedded[0].codigo
        );
        setEstabTmp(GetAllEstablecimientos._embedded[0].codigo);
        setEstablecimientos(GetAllEstablecimientos._embedded);

        await getPuntosEmision(GetAllEstablecimientos._embedded[0]);
      }
    })();
  }, []);

  const handleChangeEstablecimiento = async (e) => {
    if (e.target.value) {
      const index = e.target.selectedOptions[0].getAttribute("data-index");
      setEstabTmp(e.target.value);
      formik.setFieldValue("puntoEmision", "");
      formik.setFieldValue("secuencial", "");

      const establecimiento = Establecimientos[index];

      await getPuntosEmision(establecimiento);

      formik.handleChange(e);
    }
  };

  const getPuntosEmision = async (establecimiento) => {
    const GetAllPuntosEmision = await getListV2(
      establecimiento,
      "GetListPuntosEmision",
      `?Estado=1`
    );

    if (GetAllPuntosEmision._embedded.length > 0) {
      setPteTmp(GetAllPuntosEmision._embedded[0].codigo);
      setPuntosEmision(GetAllPuntosEmision._embedded);
      formik.setFieldValue(
        "puntoEmision",
        GetAllPuntosEmision._embedded[0].codigo
      );
      await getSecuencial(GetAllPuntosEmision._embedded[0]);
    } else {
      setPteTmp("###");
      formik.setFieldValue("puntoEmision", "");
      setPuntosEmision([]);
      //try {
      // agregar codigo de buscar la secuencia por idPuntoMEision y el tipo de documento 01=factura

      //const GetAllPuntosEmision = await getListV2(GetAllPuntosEmision._embedded[0].codigo, 'getSecuencia-Factura')
      // set el secuencial en el form_secuencial y en el numero

      //setSecTmp(GetAllPuntosEmision._embedded.codigo);
      //formik.setFieldValue(
      //"secuencial",
      //GetAllPuntosEmision._embedded.codigo
      //);
      //} catch (error) {
      //console.log(error);
      //setSecTmp("#########");
      //formik.setFieldValue("secuencial", "");
      //}
    }
  };

  const getSecuencial = async (puntoEmisionObj) => {
    try {
      // agregar codigo de buscar la secuencia por idPuntoMEision y el tipo de documento 01=factura

      const GetAllPuntosEmision = await getListV2(
        puntoEmisionObj,
        "getSecuencia-Liquidacion"
      );
      // set el secuencial en el form_secuencial y en el numero

      formik.setFieldValue("secuencial", GetAllPuntosEmision._embedded.codigo);
    } catch (error) {
      // console.log(error);
      formik.setFieldValue("secuencial", "");
    }
  };

  const handleVistaPrevia = async () => {
    let parsedInfo = {
      identificacionProveedor: formik.values.clienteIdentificacion,
      razonSocialProveedor: formik.values.clienteRazonSocial,
      direccionProveedor: formik.values.clienteDireccion,
      TelefonoCelular: formik.values.clienteTelefono,
      Correo: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
      FechaEmision: getFechaV1(formik.values.fechaEmision),
      GuiaRemision: formik.values.GuiaRemision,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
      ImporteTotal: Totales.importeTotal,
      TotalSinImpuestos: Totales.totalSinImpuesto,
      TotalDescuento: Totales.totalDescuentos,

      // TotalTotalIva0: 0,
      // TotalTotalIva: roundToTwo(Totales.totalIva),
      // TotalNoObjetoImpuesto: 0,
      // TotalExentoIva: 0,
      // TotalBaseIva0: Totales.subTotalIva0,
      // TotalBaseIva: roundToTwo(Totales.subTotalIva),
      // TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
      // TotalBaseExentoIva: Totales.subTotalExentoIva,

      ListaImpuestoliq: await CalcularImpuestosList(DetalleList, empresa,Totales),

      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      IdEmpresa: empresa.idEmpresa,
      RucEmpresa: empresa.ruc,
      CodDoc: 3,
      Reembolsos: ActiveReembolso ? [...ReembolsoList] : [],
      InformacionAdicionalLiquidaciones: InformacionAdicionalList.map((x) => {
        return {
          Descripcion: x.nombre,
          Valor: x.valor,
        };
      }),
      FormaPagoLiquidaciones: FormaPagoList.map((x) => {
        return {
          UnidaDeTiempo: x.tiempoPago,
          Plazo: x.plazoPago,
          Total: x.valor,
          IdFormaPagoSri: x.tipoPago,
        };
      }),
      DetalleLiquidaciones: DetalleList.map((e) => {
        // console.log(e);
        return {
          Descripcion: e.descripcion,
          Cantidad: e.cantidad,
          CodigoPrincipal: e.codigoPrincipal,
          CodigoAuxiliar: e.codigoAuxiliar,
          PrecioUnitario: e.precioUnitario,
          Descuento: e.descuento,
          ImpuestoCodigoIva: 2,
          ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
          PrecioTotalSinImpuesto: roundToTwo(e.subTotal),
          ImpuestoBaseImponibleIva: roundToTwo(e.subTotal),
          ImpuestoValorIva:
            e.idImpuestoIvaNavigation.porcentaje === 0 ? 0 : roundToTwo(e.iva),
          Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
          InformacionAdicionalServicioLiquidaciones:
            e.servicioDetalle === undefined
              ? []
              : e.servicioDetalle === null
              ? []
              : e.servicioDetalle.map((x) => {
                  return {
                    Descripcion: x.nombre,
                    Valor: x.valor,
                  };
                }),
        };
      }),
      IdImpuestoNoObjectoImpuesto: DetalleList.find(
        (e) =>
          e.idImpuestoIva.toUpperCase() ===
          "8E7CF164-C66B-4312-945F-57AE08C86A33"
      )
        ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
        : null,
      IdImpuestoExentoIva: DetalleList.find(
        (e) =>
          e.idImpuestoIva.toUpperCase() ===
          "F03C7F10-2773-4505-ACCB-B2FA88D62049"
      )
        ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
        : null,
      IdImpuestoIVA12: DetalleList.find(
        (e) =>
          e.idImpuestoIva.toUpperCase() ===
          "A5B8F607-8D38-4150-97AF-C591F83678D8"
      )
        ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
        : null,
      IdImpuestoIVA0: DetalleList.find(
        (e) =>
          e.idImpuestoIva.toUpperCase() ===
          "81BAF871-E113-48CF-8AA8-670CB43494B6"
      )
        ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
        : null,
    };
    if (formik.values.clienteEmail !== null)
      if (formik.values.clienteEmail.trim() !== "")
        parsedInfo.InformacionAdicionalLiquidaciones.push({
          Valor: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
          Descripcion: "email",
        });

    let data = await GetVistaPreviaLiquidacion(parsedInfo);
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const handleChangePuntoEmision = (e) => {
    if (e.target.value) {
      setPteTmp(e.target.value);

      const index = e.target.selectedOptions[0].getAttribute("data-index");

      const puntoEmision = PuntosEmision[index];

      // console.log(`${puntoEmision.idPuntoEmision}`);

      getSecuencial(puntoEmision);

      formik.handleChange(e);
    }
  };

  const handleClickSubmitFactura = (e) => {
    validateForm(formik.values);
    if (ValorTotal !== 0) return e.preventDefault();
    if (DetalleList.length <= 0) return e.preventDefault();
  };

  const validateForm = (data) => {
    if (
      data.clienteIdentificacion !== "9999999999999" &&
      (data.clienteDireccion === "" ||
        data.clienteEmail === "" ||
        data.clienteIdentificacion === "" ||
        data.clienteRazonSocial === "")
    ) {
      toast.error("Falta informacion en el apartado de contribuyente!");
      throw new Error("Error de validacion");
    }
  };

  const handleResetDocument = () => {
    let oldPuntoEmision = formik.values.puntoEmision;
    let oldEstablecimiento = formik.values.establecimiento;
    //console.log(formik.values);
    // console.log(Number(formik.values.secuencial))
    handleDeleteElement();
    formik.resetForm();
    formik.setFieldValue("secuencial", Number(formik.values.secuencial) + 1);
    formik.setFieldValue("puntoEmision", oldPuntoEmision);
    formik.setFieldValue("establecimiento", oldEstablecimiento);
    setTotales({ ...TotalInicial });
    setDetalleList([]);
    setFormaPagoList([]);
    setInformacionAdicionalList([]);
  };



  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalHours isOpenModalHours={isOpenModalHours} toggleModal={toggleModal} />
      {IsLoading && (
        <div
          style={{ width: "100vw", height: "100vh", zIndex: "100" }}
          className="bg-opacity-70 fixed justify-center align-middle top-0 l-0 bg-white text-center"
        >
          <div className="w-full h-full flex justify-center items-center py-4">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"
              role="status"
            >
              <span className="hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      <div className=" rounded-xl   bg-white dark:bg-gray-900 p-2">
        <div className="flex justify-between border-b dark:border-b-gray-600 py-2 ">
          <div className="flex justify-between w-full ">
            <div className=" dark:text-white font-semibold text-xl">
              Liquidación de compra
            </div>

            <MobileButtonOptionsLiq />
          </div>

          <div className="my-auto lg:flex md:flex sm:hidden ">
            <div className="flex justify-between w-full mr-4">
              <ActiveReembolsoButton />
            </div>
   
   

            <div className=" group relative inline-block">
              <div className=" cursor-pointer w-10 h-10 rounded-full  inline-flex items-center justify-center">
                <button
                  type="button"
                  onClick={handleResetDocument}
                  className="inline-flex h-10 w-10 cursor-pointer  hover:shadow-md items-center justify-center rounded-xl  transition dark:bg-gray-900 dark:hover:bg-gray-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 dark:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>

                  <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white     rounded-md bg-gray-800 group-hover:flex text-center p-1">
                    <p className="text-white dark:text-black mx-auto text-sm ">
                      Limpiar
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className="bg-[#F7F7F7] dark:bg-gray-700 my-2 p-2 rounded-lg">
            <div className=" grid lg:grid-cols-4 gap-2 w-full max-w-2xl   px-2 text-center">
              <div className="relative z-0  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-[0.813rem] font-medium text-black dark:text-white text-left"
                >
                  {" "}
                  Establecimiento:{" "}
                </label>
                <select
                  onChange={handleChangeEstablecimiento}
                  type="text"
                  name="establecimiento"
                  id="establecimiento"
                  className={StyleSelect}
                  placeholder=" "
                  defaultValue={formik.values.establecimiento}
                  onBlur={formik.handleBlur}
                >
                  {/*data-id-establecimiento={el.idEstablecimiento}*/}
                  {Establecimientos.map((el, index) => {
                    return (
                      <option
                        key={el.idEstablecimiento}
                        data-index={index}
                        value={el.codigo}
                      >
                        {rellenarCeros(el.codigo)} {/*- {el.descripcion}*/}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="relative z-0  w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-[0.813rem] font-medium text-black dark:text-white text-left"
                >
                  {" "}
                  Punto de emisión:{" "}
                </label>
                <select
                  type="text"
                  className={StyleSelect}
                  placeholder=" "
                  name="puntoEmision"
                  id="puntoEmision"
                  defaultValue={formik.values.puntoEmision}
                  onChange={handleChangePuntoEmision}
                  onBlur={formik.handleBlur}
                >
                  {PuntosEmision.map((el, index) => {
                    return (
                      <option
                        key={el.idPuntoEmision}
                        value={el.codigo}
                        data-index={index}
                      >
                        {rellenarCeros(el.codigo)} {/*- {el.descripcion}*/}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="relative z-0 w-full group">
                <label
                  htmlFor="company-website"
                  className="block text-sm font-medium text-black dark:text-white text-left"
                >
                  Secuencial:
                </label>
                <input
                  type="text"
                  disabled
                  className={StyleSelect + " bg-block"}
                  value={rellenarCeros(formik.values.secuencial || "0", 9)}
                ></input>
              </div>

              <div className="relative z-0  w-full group">
              <label
                htmlFor="company-website"
                className="block text-[0.813rem] text-left font-medium text-black dark:text-white"
              >
                {" "}
                Fecha emisión{" "}
              </label>
              <input
                type="date"
                className={StyleInput}
                name="fechaEmision"
                id="fechaEmision"
                min={getNoventaDiasAtras()}
                value={formik.values.fechaEmision}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder=" "
              />
            </div>
            </div>{" "}
          </div>
        </>
      </div>

      <div className=" bg-white dark:bg-gray-900 rounded-t-lg px-2 mb-6 lg:px-4 md:px-4 lg:py-4 md:py-2  mt-2 pt-2">
        <Contribuyente
          IsShowConsumFinal={true}
          Totales={Totales}
          formik={formik}
        />
      </div>
    </form>
  );
};

export default FormFactura;
