import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Obtener,
  Agregar,
  EliminarCategoria,
  Editar,
  CambiarEstadoCategoria,
  SubirExcel,
  CambiarEstadoMasivoCategoria,
} from "../../../../services/InventarioService";

export const CategoriaContext = createContext();

export const CategoriaProvider = ({ children }) => {
  const empresa = useSelector((state) => state.empresa.empresa);
  const [isLoading, setIsLoading] = useState(false);
  const [categorias, setCategorias] = useState([]);
  const [infoPaginacion, setInfoPaginacion] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [parametroPaginacion, setParametroPaginacion] = useState({
    pageNumber: 1,
    pageSize: 50,
  });

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      getCategorias();
    }
  }, [parametroPaginacion, empresa]);

  const getCategorias = async () => {
    setIsLoading(true);
    try {
      const response = await Obtener(
        "/api/categoria/obtener-categorias",
        empresa.idEmpresa,
        parametroPaginacion.pageSize,
        parametroPaginacion.pageNumber
      );
      setCategorias(response.data.data);
      setInfoPaginacion({
        totalPages: response.data.totalPages,
        totalRecords: response.data.totalRecords,
      });
    } catch (err) {
      throw new Error(
        err,
        "No se pudo obtener la información de las categorias. Inténtelo de nuevo más tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addCategoria = async (newCategoria) => {
    const res = await Agregar("/api/categoria/crear-categoria", newCategoria);
    if (res.code === 200) {
      await getCategorias()
    } else {
      throw new Error("No se pudo guardar la categoria");
    }
  };

  const deleteCategoria = async (idCategoriaProducto) => {
    const res = await EliminarCategoria(
      "/api/categoria/eliminar-categoria",
      idCategoriaProducto
    );
    if (res.code === 200) {
      await getCategorias()
    } else {
      throw new Error("No se pudo eliminar la categoria");
    }
  };

  const updateCategoria = async (newCategoria) => {
    const res = await Editar("/api/categoria/editar-categoria", newCategoria);
    if (res.code === 200) {
      await getCategorias()
    } else {
      throw new Error("No se pudo editar la categoria");
    }
  };

  const changeStateCategoria = async (idCategoriaProducto) => {
    try {
      const res = await CambiarEstadoCategoria(
        "/api/categoria/cambiar-estado",
        idCategoriaProducto
      );
      if (res.code === 200) {
        setCategorias((prevCategorias) =>
          prevCategorias.map((categoria) =>
            categoria.idCategoriaProducto === idCategoriaProducto
              ? {
                  ...categoria,
                  estado: !categoria.estado,
                }
              : categoria
          )
        );
      } else {
        console.log("No se pudo cambiar el estado de la categoria");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const uploadExcelCategorias = async (categorias) => {
    const res = await SubirExcel(
      "/api/categoria/crear-categorias-excel",
      categorias
    );
    if (res.code === 200) {
    } else {
      throw new Error("No se pudo subir las categorias");
    }
  };

  const CancelCategorias = async (ids) => {
    const res = await CambiarEstadoMasivoCategoria(
      "/api/categoria/desactivar-categorias-masivo",
      ids
    );
    if (res.code === 200)
      setCategorias((prevCategorias) => {
        return prevCategorias.map((categoria) => {
          if (ids.includes(categoria.idCategoriaProducto)) {
            return {
              ...categoria,
              estado: false,
            };
          }
          return categoria;
        });
      });
  };

  return (
    <CategoriaContext.Provider
      value={{
        categorias,
        addCategoria,
        deleteCategoria,
        updateCategoria,
        CancelCategorias,
        uploadExcelCategorias,
        isLoading,
        setIsLoading,
        setParametroPaginacion,
        changeStateCategoria,
        getCategorias,
        infoPaginacion,
        parametroPaginacion,
      }}
    >
      {children}
    </CategoriaContext.Provider>
  );
};
