import React, { useContext } from "react";
import { Fragment } from "react";

import MesageWarningTable from "../../../features/components/MesageWarningTable";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../features/Constantes";
import { ReporteDocGeneradoContext } from "./ReporteDocGeneradoProvider";

const ListaReporteDocGenerado = () => {
  const { docGenerado } = useContext(ReporteDocGeneradoContext);

  return (
    <>
      {/*  <div className=" inline-flex z-0 mx-4 my-3  group overflow-x-auto">
        <h1 className="float-left  mr-2 dark:text-white text-black">Mostrar</h1>
        <select
          type="text"
          name="Cantidad"
          id="Cantidad"
          className="origin-left border-gray-300 border pr-2 pl-1  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          placeholder=" "
        >
          <option key="25" value="25">
            25
          </option>
          <option key="50" value="50">
            50
          </option>
          <option key="75" value="75">
            75
          </option>
          <option key="100" value="100">
            100
          </option>
        </select>
        <h1 className="float-left  ml-2 dark:text-white text-black">
          Registros
        </h1>
      </div> */}
<div className="my-4 bg-white border dark:border-gray-600 dark:bg-gray-700 p-8 rounded-lg">


      <div className="px-4 sm:px-6 lg:px-8">
        <div className=" flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-1">
              <div className={TableStyleDiv + " whitespace-nowrap"}>
                <table className={TableStyle}>
                  <thead className={TableStyleTHead}>
                    <tr >
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3  text-sm font-semibold  sm:pl-6 lg:table-cell hidden bg-gray-300 dark:bg-gray-700"
                      >
                        Fecha Consulta Inicio
                      </th>
                      <th
                        scope="col"
                        className=" px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden bg-gray-300 dark:bg-gray-700"
                      >
                        Fecha Consulta Fin
                      </th>
                      <th
                        scope="col"
                        className=" px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden  bg-gray-300 dark:bg-gray-700"
                      >
                        Identificación Emisor
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold  lg:table-cell hidden bg-gray-300 dark:bg-gray-700"
                      >
                        Razón Social
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold bg-gray-300 dark:bg-gray-700"
                      >
                        Correo Emisor
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold bg-gray-300 dark:bg-gray-700" 
                      >
                        Celular Emisor
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold bg-gray-300 dark:bg-gray-700"
                      >
                        Identificación Usuario
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5  text-sm font-semibold  bg-gray-300 dark:bg-gray-700"
                      >
                        Nombre de Usuario
                      </th>
                      {/* <th
                          scope="col"
                          className="px-3 py-3.5  text-sm font-semibold  "
                        >
                          Correo De Usuario
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5  text-sm font-semibold  lg:table-cell"
                        >
                          Celular De Usuario
                        </th> */}
                    </tr>
                  </thead>

                  <tbody className={TableStyleTBody}>
                    {docGenerado.length < 1 ? (
                      <MesageWarningTable
                        colSpan="11"
                        message="No se encontraron datos para la búsqueda."
                        className="py-1"
                      />
                    ) : null}

                    {docGenerado.map((obj, index) => {
                      return (
                        <tr key={index} className="">
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                            {obj.fechaInicio}
                          </td>
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                            {obj.fechaFin}
                          </td>
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                            {obj.identificacionEmisor}
                          </td>
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                            {obj.razonSocial}
                          </td>
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                            {obj.correoEmisor}
                          </td>
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                            {obj.celularEmisor}
                          </td>
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                            {obj.identificacionUsuario}
                          </td>
                          <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                            {obj.nombreUsuario}
                          </td>
                          {/*  <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                              {obj.correoUsuario === null
                                ? "Sin correo"
                                : obj.correoUsuario}
                            </td>
                            <td className="relative py-4 pl-4 sm:pl-6 pr-3 text-sm  lg:table-cell hidden">
                              {obj.celularUsuario === null
                                ? "Sin CELULAR"
                                : obj.celularUsuario}
                            </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default ListaReporteDocGenerado;
