export const NoPageWorking = () => {
  return (
    <section className="w-full h-full overflow-hidden ">
      <div className="flex flex-col items-center mt-[42px] font-[Tanker]">
        <span className="text-[125px] text-[#003B5B] font-bold">404</span>
        <h1 className="text-[75px] ">
          Estamos trabajando actualmente en esta pagina.....
        </h1>
        <img src="/404.png" alt="Working" className="NotFoundImage" />
      </div>
    </section>
  );
};
