import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    email: "",
    usuario: "",
    identificacion: "",
    nombre: "",
  },
  errors: {},
  loginIn: false,
  empresas: [],
  empresa: {},
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    initializeApp: () => initialState,
    loadUser: (state, action) => {
      if (action.payload != null) {
        state.loginIn = true;
        state.user = {
          ...state.user,
          usuario: action.payload.username,
          identificacion: action.payload.idUsuario,
          nombre: action.payload.nombre,
          esAdministradorSistema: action.payload.esAdministradorSistema,
          esActivo: action.payload.activo,
        };
        if (action.payload.empresas && action.payload.empresas.length > 0) {
          state.empresas = action.payload.empresas;
          state.empresa = action.payload.empresas[0];
        }
      }
    },
    logout: (state) => {
      state.user = {};
      state.loginIn = false;
      localStorage.clear();
    },
    endLoad: async (state) => {
      /*const user = await getUser();
      
      console.log(state)
      state = {
        ...state,
        user: { ...state.user, usuario: user.username, identificacion: user.idUsuario }
      };
      console.log(user)*/
    },
    startLoginIn: (state) => {
      state.loginIn = true;
    },
  },
});

export const {
  loadUser,
  logout,
  endLoad,
  initializeApp,
  startLoginIn,
  loginUser,
  setAmbiente,
} = slice.actions;

export default slice.reducer;
