import React, { useContext, useEffect, useRef, useState } from "react";
import { StyleInput, StyleSelect } from "../../../features/Constantes";
import { useFormik } from "formik";
import * as Yup from "yup";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import { getList, getListV2 } from "../../../services";
import { useSelector } from "react-redux";
import { EmitirDocContext } from "./EmitirDocProvider";
import debounce from "lodash.debounce";
import InputNumber from "../../../features/components/common/InputNumber";
import { toast } from "react-toastify";
import { Transition } from "@headlessui/react";
import ListaDetalle from "./ListaDetalle";

const FormBuscarDetalle = () => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const { ErrorToast } = useGlobalToastify();

  const [ItemDetalle, setItemDetalle] = useState({});

  const [TempDetalleItem, setTempDetalleItem] = useState({});
  const [ImpuestosList, setImpuestosList] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const { setDetalleList, DetalleList } = useContext(EmitirDocContext);

  const formik = useFormik({
    initialValues: {
      motivo: "",
      valor: "",
      porcentaje: "",
    },
    validationSchema: Yup.object({
      // codigo: Yup.string()
      //     .required('Campo Requerido'),
      // nombre: Yup.string()
      //     .required('Campo Requerido'),
      // cantidad: Yup.number()
      //   .min(1, "La cantidad no puede ser menor o igual a 0")
      //   .required("Campo Requerido"),
    }),
    onSubmit: async (obj) => {
      setIsLoading(true);
      try {
        if (
          obj.motivo !== "" &&
          obj.porcentaje !== "" &&
          obj.valor !== undefined
        ) {
          ItemDetalle.motivo = obj.motivo;
          ItemDetalle.valor = obj.valor;
          ItemDetalle.porcentaje = obj.porcentaje;
          ItemDetalle.idImpuestoIvaNavigation =
            TempDetalleItem.idImpuestoIvaNavigation;
            ItemDetalle.idImpuestoIva = TempDetalleItem.idImpuestoIvaNavigation.idImpuestoDetalle;
          setDetalleList([{ ...ItemDetalle }, ...DetalleList]);
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          return;
        } else {
          ErrorToast("No se puede agregar motivo si existen campos vacíos");
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        }
      } catch (error) {
        // console.log(error);
        ErrorToast("Error al agregar información. Contactar con sistema.");
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      }

      //setSaveData(false)
    },
  });

  useEffect(() => {
    formik.validateForm();
    (async () => {
      let data = await getListV2(
        empresa,
        "GetAllimpDetalles",
        "?activo=true&idImpuesto=1"
      );
      setImpuestosList(data._embedded);
    })();
  }, [ItemDetalle]);

  const handleChangeImpuesto = (e) => {
    try {
      const index = e.target.selectedOptions[0].getAttribute("data-index");

      const item = {
        ...TempDetalleItem,
        [e.target.name]: e.target.value,
        idImpuestoIvaNavigation: { ...ImpuestosList[index] },
      };

      setTempDetalleItem(item);

      formik.setFieldValue(
        "porcentaje",
        item.idImpuestoIvaNavigation.porcentaje
      );
    } catch (ex) {
      //  console.log(ex)
    }
  };

  const handleChangeValor = (e) => {
    if (e.target.value.length >= 10) {
      return e.target.value;
    }
    if (e.target.value.match(/^\d[0-9]{0,}(\.\d{0,4})?$/)) {
      formik.handleChange(e);
      return e.target.value;
    }
  };

  const [isOpen, setIsOpen] = useState(true);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-wrap justify-between flex-col  border dark:border-gray-600 rounded-t-lg  rounded-b-lg">
        <div className="text-sm py-1.5  px-2 text-left bg-[#003B5B] dark:text-gray-50 dark:bg-gray-700 border-transparent rounded-t-lg font-medium leading-6 text-white flex justify-between">
          <h3> Motivos</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setIsOpen(!isOpen)}
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`w-4 h-4 my-auto text-white lg:hidden md:hidden duration-150 ease-in-out ${isOpen ? 'transform rotate-180' : ''}`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
        <Transition
        show={isOpen}
        enter="transition ease-out duration-300" // Aumenta la duración de entrada
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300" // Aumenta la duración de salida
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >

        <div className="flex lg:flex-row my-2 flex-col gap-3 justify-center p-3 ">
          <div className="basis-[60%] my-auto ">
            <label className="block text-[0.813rem] mb-4 font-medium text-black dark:text-white"></label>

            <input
              type="text"
              className={StyleInput}
              placeholder="Digite el motivo"
              id="motivo"
              name="motivo"
              onChange={formik.handleChange}
              // value={formik.values.motivo}

              // onBlur={formik.handleBlur}
            />
          </div>
          <div className="basis-[15%] my-auto">
            <label className="block text-[0.813rem] font-medium text-black dark:text-white">
              {" "}
              Valor:{" "}
            </label>
            {/* <input
              type="Number"
              className={StyleInput}
              placeholder=" "
              id="valor"
              name="valor"
              value={formik.values.valor}
              onChange={handleChangeValor}
              onBlur={formik.handleBlur}
            /> */}

            <InputNumber
              id="valor"
              placeholder=""
              className={StyleInput}
              decimalMax={4}
              value={formik.values.valor}
              onChange={handleChangeValor}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="basis-[15%] my-auto">
            <label className="block text-[0.813rem] font-medium text-black dark:text-white">
              {" "}
              IVA:{" "}
            </label>

            <select
              type="text"
              className={StyleSelect}
              placeholder=" "
              name="porcentaje"
              id="porcentaje"
              onChange={handleChangeImpuesto}
              value={TempDetalleItem.idImpuestoIva}
            >
              <option selected disabled>
                --Seleccione IVA--
              </option>
              {ImpuestosList.map((el, index) => (
                <option
                  key={index}
                  data-index={index}
                  value={el.idImpuestoDetalle}
                >
                  {el.detalle}
                </option>
              ))}
            </select>
          </div>
          <div className="basis-1/4 mt-1 ">
            <button
              type="submit"
              className=" inline-block mt-3 text-white bg-blue-600 w-full lg:w-28  hover:bg-blue-700  font-semibold p-2 text-[0.813rem] border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:shadow-outline-grayduration-150
              ease-in-out"
              onClick={formik.handleSubmit}
              disabled={IsLoading}
            >
              {IsLoading ? (
                "..."
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="inline mr-1   -ml-1 w-5 h-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  AGREGAR
                </>
              )}
            </button>
          </div>
        </div> </Transition>
      </div>


      {DetalleList.length > 0 && (
      <Transition
      show={isOpen}
      enter="transition ease-out duration-300" // Aumenta la duración de entrada
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-300" // Aumenta la duración de salida
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >




    <ListaDetalle />
    
    
    </Transition>


      )}

    </form>
  );
};

export default FormBuscarDetalle;
