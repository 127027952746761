import { InputGlobal } from "../../context/inputContext";
import { FormBaseInventario } from "../../Shared/FormBaseInventario";
import { EstablecimientosContext } from "../../../Mantenimiento/Establecimiento/EstablecimientosProvider";
import { useContext, useState } from "react";
import { BodegaContext } from "./context/BodegasContext";
import { v4 as uuidv } from "uuid";
import * as XLSX from "xlsx";
import AutoCompleteInput from "../../context/AutoCompleteSelect";
import { AlfaNumerico, SoloLetras } from "../../regex.d";
import { useSelector } from "react-redux";
import useGlobalToastify from "../../../../features/hooks/GlobalToastify/useGlobalToastify";
import { toast } from "react-toastify";
import { ModalSubirExcel } from "../../Shared/ModalSubirExcel";
import { useProgressBar } from "../../hooks/useProgressBar";
import { GenerarExcelBodegas } from "../../../../services/InventarioService";

//Formulario de Creacion de Bodega

//Funciones para evitar el ingreso de caracteres en inputs. Experimental Ralentiza la escritura
export const handleKeyPressAlfaNumerico = (e) => {
  const pattern = AlfaNumerico;
  const inputChar = String.fromCharCode(e.charCode);

  if (!pattern.test(inputChar)) {
    e.preventDefault();
  }
};

export const handleKeyPressLetras = (e) => {
  const pattern = SoloLetras;
  const inputChar = String.fromCharCode(e.charCode);

  if (!pattern.test(inputChar)) {
    e.preventDefault();
  }
};

//////

export const CrearBodegaForm = () => {
  const empresa = useSelector((state) => state.empresa.empresa);
  const { addBodega, bodegas, uploadExcelBodegas, getBodegas } =
    useContext(BodegaContext);
  const { documentos } = useContext(EstablecimientosContext);
  const { WarningToast, ErrorToast } = useGlobalToastify();
  const [isSending, setIsSending] = useState(false);

  const {
    isUploading,
    setIsUploading,
    progress,
    updateProgressBar,
    setProgress,
  } = useProgressBar();

  const crearBodega = async (data) => {
    const newBodega = {
      idBodega: uuidv(),
      idEmpresa: empresa.idEmpresa,
      idEstablecimiento: data.establecimiento.idEstablecimiento,
      codigo: data.codigo.trimEnd().trimStart(),
      nombre: data.nombreBodega.trimEnd().trimStart(),
      direccion: data.direccion.trimEnd().trimStart(),
      codigoEstablecimiento: data.establecimiento.codigo,
      direccionEstablecimiento: data.establecimiento.direccion,
    };
    toast.dismiss();
    try {
      setIsSending(true);
      await toast.promise(addBodega(newBodega), {
        pending: "Guardando bodega...",
        success: "¡La bodega fue guardada con éxito!",
        error: {
          render({ data }) {
            return (
              data?.response?.data?.message ||
              "Hubo un error al registrar la bodega."
            );
          },
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsSending(false);
    }
  };

  //Funcion para importar las Bodegas de un excel
  const handleFileImportBodega = (e) => {
    const importedFile = e.target.files[0];
    const reader = new FileReader();
    const propiedadesRequeridas = [
      "Establecimiento",
      "Código",
      "Nombre de la Bodega",
      "Dirección",
    ];

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
      const xlData = XLSX.utils.sheet_to_json(worksheet, { header: 0 });
      let isValid = true;

      for (const prop of propiedadesRequeridas) {
        if (!headers.includes(prop)) {
          isValid = false;
        }
      }
      if (isValid === false) {
        ErrorToast(
          "La plantilla de excel no cumple con los campos requeridos."
        );
        return;
      }

      let percentageIncrement = 0;
      let newBodegas = [];
      let bodegasNoUploaded = [];
      const totalIteraciones =
        Math.round(xlData.length / 10) < 1 ? 1 : Math.round(xlData.length / 10);

      percentageIncrement = 100 / totalIteraciones;

      for (const bodega of xlData) {
        const hasAllRequiredValues = propiedadesRequeridas.every(
          (prop) =>
            bodega[prop] !== "" &&
            bodega[prop] !== null &&
            bodega[prop] !== undefined
        );

        if (!hasAllRequiredValues) {
          bodegasNoUploaded.push(bodega);
          continue;
        } else {
          const newBodega = {
            idBodega: uuidv(),
            idEmpresa: empresa.idEmpresa,
            codigo: bodega.Código,
            nombre: bodega["Nombre de la Bodega"],
            idEstablecimiento: documentos
              .filter((d) => d.activo === true)
              .find(
                (establecimiento) =>
                  String(establecimiento.codigo).padStart(3, "0") ===
                  String(bodega.Establecimiento).padStart(3, "0")
              )?.idEstablecimiento,
            direccion: bodega.Dirección,
          };
          if (newBodega.idEstablecimiento) {
            newBodegas.push(newBodega);
          } else {
            bodegasNoUploaded.push(newBodega);
          }
        }
      }
      setIsUploading(true);
      for (let i = 0; i < totalIteraciones; i++) {
        const batch = newBodegas.slice(i * 10, (i + 1) * 10);
        try {
          await uploadExcelBodegas(batch);
          updateProgressBar(percentageIncrement);
        } catch (err) {
          console.log(err);
        }
      }
      await getBodegas();
      setIsUploading(false);
      setProgress(0);

      if (bodegasNoUploaded.length > 0) {
        WarningToast("Algunas bodegas no cumplieron los requerimientos.");
      }
    };

    reader.readAsArrayBuffer(importedFile);
  };

  //Funcion para exportar las Bodegas registradas en un excel
  const handleFileExportBodegas = async () => {
    try {
      const excelBase64 = await GenerarExcelBodegas(empresa.idEmpresa);
      const excelBinary = atob(excelBase64);
      const byteNumbers = new Array(excelBinary.length);
      for (let i = 0; i < excelBinary.length; i++) {
        byteNumbers[i] = excelBinary.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Bodegas.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error("Error al procesar el archivo Excel:", err);
    }
  };

  //Funcion para filtrar establecimientos en el Select con Entrada
  const searchEstablecimiento = async (inputText) => {
    if (documentos.length < 10) {
      return documentos
        .filter((documento) => documento.activo === true)
        .filter((documento) =>
          String(documento.codigo)
            .padStart(3, "0")
            .toLowerCase()
            .includes(String(inputText).toLowerCase())
        );
    } else {
      return documentos
        .filter((documento) => documento.activo === true)
        .filter(
          (documento) =>
            String(documento.codigo)
              .padStart(3, "0")
              .toLowerCase()
              .includes(String(inputText).toLowerCase()) ||
            String(documento.direccion)
              .toLowerCase()
              .includes(String(inputText).toLowerCase())
        );
    }
  };

  return (
    <>
      <FormBaseInventario
        submitFn={crearBodega}
        isSending={isSending}
        subtitle={
          <>
            <span>
              <svg
                viewBox="0 0 16 16"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    fill="#ffffff"
                    d="M16 4l-8.060-4-7.94 4v1h1v11h2v-9h10v9h2v-11h1v-1zM4 6v-1h2v1h-2zM7 6v-1h2v1h-2zM10 6v-1h2v1h-2z"
                  ></path>{" "}
                  <path fill="#ffffff" d="M6 9h-1v-1h-1v3h3v-3h-1v1z"></path>{" "}
                  <path fill="#ffffff" d="M6 13h-1v-1h-1v3h3v-3h-1v1z"></path>{" "}
                  <path fill="#ffffff" d="M10 13h-1v-1h-1v3h3v-3h-1v1z"></path>{" "}
                </g>
              </svg>
            </span>
            Formulario de Creación{" "}
          </>
        }
        title={"Registro de Bodegas"}
        valueProblem={["establecimiento"]}
        exportFn={handleFileExportBodegas}
        importFn={handleFileImportBodega}
      >
        <InputGlobal
          title={"Código"}
          max={6}
          type={"text"}
          validations={{ required: true, maxLength: 6, pattern: AlfaNumerico }}
          isHook={true}
          name={"codigo"}
          isPlaceHolder={"Digite el codigo de la bodega"}
        />
        <InputGlobal
          max={45}
          title={"Nombre de la Bodega"}
          validations={{ required: true, maxLength: 45 }}
          type={"text"}
          name={"nombreBodega"}
          isHook={true}
          isPlaceHolder={"Digite el nombre de la bodega"}
        />
        <AutoCompleteInput
          identificador={"idEstablecimiento"}
          title={"Establecimiento"}
          option={
            documentos.filter(
              (establecimiento) => establecimiento.activo === true
            ).length > 10
              ? (e) =>
                  `${String(e["codigo"]).padStart(3, "0")} - ${e["direccion"]}`
              : (e) => `${String(e["codigo"]).padStart(3, "0")}`
          }
          validations={{ required: true }}
          array={documentos.filter(
            (establecimiento) => establecimiento.activo === true
          )}
          searchFn={searchEstablecimiento}
        />
        <InputGlobal
          title={"Dirección"}
          max={40}
          type={"text"}
          validations={{ required: true, maxLength: 40}}
          name={"direccion"}
          isPlaceHolder={"Digite el nombre de la dirección"}
          isHook={true}
        />
      </FormBaseInventario>
      <ModalSubirExcel
        isVisible={isUploading}
        progress={progress}
        elemento={"Bodegas"}
      />
    </>
  );
};
