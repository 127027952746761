//Componente para simular el fondo oscuro detras de un modal.
import { useDispatch } from "react-redux";

export const ModalBase = (props) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={
        props.useRedux ? () => dispatch(props.onClose()) : () => props.onClose()
      }
      className="fixed bg-black/25 flex items-center top-0 left-0 z-[100] justify-center w-full h-full" 
    >
      <div onClick={(e) => e.stopPropagation()}>{props.children}</div>
    </div>
  );
};

export function setModalBase(Component) {
  return (
    <ModalBase>
      <Component />
    </ModalBase>
  );
}
