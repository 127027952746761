import axios from "axios";

//EndPoints Base Inventario

export const Obtener = async (url, idEmpresa, pageSize, pageNumber) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
  return res.data;
};

export const SubirExcel = async (url, bodegas) => {
  const res = await axios.post(url.replace("http://", "https://"), bodegas);

  return res.data;
};

export const Agregar = async (url, nuevoElemento) => {
  const res = await axios.post(
    url.replaceAll("http://", "https://"),
    nuevoElemento
  );

  return res.data;
};

export const Editar = async (url, editedElemento) => {
  const res = await axios.put(
    url.replace("http://", "https://"),
    editedElemento
  );

  return res.data;
};

//Endpoins Configuracion Inventario

export const ObtenerElementoConfiguracion = async (url) => {
  const res = await axios.get(url.replace("http://", "https://"));

  return res.data;
};

export const ObtenerConfiguracionInventarioEmpresa = async (url, idEmpresa) => {
  const res = await axios.get(url.replace("http://", "https://"), {
    headers: {
      idEmpresa: idEmpresa,
    },
  });
  return res.data;
};

export const GuardarConfiguracion = async (url, changes) => {
  const res = await axios.post(url.replace("http://", "https://"), changes);
  return res.data;
};

//Endpoints Bodega

export const EliminarBodega = async (url, idBodega) => {
  const res = await axios.delete(url.replace("http://", "https://"), {
    headers: {
      idBodega: idBodega,
    },
  });
  return res.data;
};

export const ObtenerTodasBodegas = async (url, idEmpresa) => {
  const res = await axios.get(
    `${url.replaceAll("http://", "https://")}?idEmpresa=${idEmpresa}`
  );
  return res.data;
};

export const GenerarExcelBodegas = async (idEmpresa) => {
  const res = await axios.post(
    `${"/api/bodega/generar-bodega-excel".replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}`
  );
  return res.data;
};

//Endpoints Seccion

export const EliminarSeccion = async (url, idSeccion) => {
  const res = await axios.delete(url.replace("http://", "https://"), {
    headers: {
      idSeccion: idSeccion,
    },
  });
  return res.data;
};

export const GenerarExcelSecciones = async (idEmpresa) => {
  const res = await axios.post(
    `${"/api/secciones/generar-seccion-excel".replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}`
  );
  return res.data;
};

//Endpoints Categoria

export const ObtenerCategoriasActivas = async (url, idEmpresa) => {
  const res = await axios.get(
    `${url.replaceAll("http://", "https://")}?idEmpresa=${idEmpresa}`
  );
  return res.data;
};

export const EliminarCategoria = async (url, idCategoriaProducto) => {
  const res = await axios.delete(url.replace("http://", "https://"), {
    headers: {
      idCategoria: idCategoriaProducto,
    },
  });
  return res.data;
};

export const CambiarEstadoCategoria = async (url, idCategoriaProducto) => {
  const res = await axios.put(
    url.replace("http://", "https://"),
    {},
    {
      headers: {
        idCategoria: idCategoriaProducto,
      },
    }
  );
  return res.data;
};

export const CambiarEstadoMasivoCategoria = async (url, idsCategoria) => {
  const res = await axios.put(url.replace("http://", "https://"), idsCategoria);

  return res.data;
};

export const GenerarExcelCategorias = async (idEmpresa) => {
  const res = await axios.post(
    `${"/api/categoria/generar-categoria-excel".replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}`
  );
  return res.data;
};

//Endpoints Producto

export const ObtenerProductos = async (
  url,
  idEmpresa,
  pageSize,
  pageNumber,
  nombre,
  estado,
  usoProducto
) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&pageNumber=${pageNumber}&pageSize=${pageSize}&nombre=${nombre}&estado=${estado}&usoProducto=${usoProducto}`
  );
  return res.data;
};

export const ObtenerIDsProductos = async (
  url,
  idEmpresa,
  nombre,
  estado,
  usoProducto
) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&nombre=${nombre}&estado=${estado}&usoProducto=${usoProducto}`
  );

  return res.data;
};

export const ObtenerTerminoProducto = async () => {
  const res = await axios.get("/api/termino-producto/obtener-termino-producto");

  return res.data;
};

export const ObtenerTipoProducto = async () => {
  const res = await axios.get("/api/tipo-producto/obtener-tipo-producto");

  return res.data;
};

export const ObtenerUsoProducto = async () => {
  const res = await axios.get("/api/uso-producto/obtener-uso-producto");

  return res.data;
};

export const ObtenerImagenesProducto = async (imagenes) => {
  const res = await axios.post(
    "/api/producto/obtener-imagenes-productos",
    imagenes
  );

  return res.data;
};

export const EliminarProducto = async (url, idProducto) => {
  console.log(idProducto);
  const res = await axios.delete(url.replace("http://", "https://"), {
    headers: {
      idProducto: idProducto,
    },
  });
  return res.data;
};

export const GenerarExcelProductos = async (idEmpresa) => {
  const res = await axios.post(
    `${"/api/producto/generar-producto-excel".replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}`
  );
  return res.data;
};

export const EditarProducto = async (url, editedElemento) => {
  const formData = new FormData();
  Object.keys(editedElemento).forEach((key) => {
    if (key === "imagenes" && Array.isArray(editedElemento[key])) {
      editedElemento[key].forEach((imagen, index) => {
        formData.append(
          `imagenes[${index}].IdImagenProducto`,
          imagen.idImagenProducto
        );
        formData.append(`imagenes[${index}].Url`, imagen.url || "");
        formData.append(`imagenes[${index}].Archivo`, imagen.archivo);
        formData.append(`imagenes[${index}].IsPrincipal`, imagen.isPrincipal);
      });
    } else if (key === "imagenesDelete" && Array.isArray(editedElemento[key])) {
      editedElemento[key].forEach((imagen, index) => {
        formData.append(
          `imagenesDelete[${index}].IdImagenProducto`,
          imagen.idImagenProducto
        );
        formData.append(
          `imagenesDelete[${index}].Extension`,
          imagen.extension || ""
        );
      });
    } else if (key === "subProductos" && Array.isArray(editedElemento[key])) {
      editedElemento[key].forEach((subProducto, index) => {
        formData.append(
          `subProductos[${index}].IdProducto`,
          subProducto.idProducto
        );
        formData.append(
          `subProductos[${index}].Cantidad`,
          subProducto.cantidad || ""
        );
        formData.append(`subProductos[${index}].Nombre`, subProducto.nombre);
        formData.append(
          `subProductos[${index}].Costo`,
          subProducto.costo || ""
        );
        formData.append(
          `subProductos[${index}].Codigo`,
          subProducto.codigo || ""
        );
      });
    } else {
      formData.append(key, editedElemento[key]);
    }
  });

  const res = await axios.put(url.replaceAll("http://", "https://"), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
};

export const CambiarEstadoProducto = async (url, idProducto) => {
  const res = await axios.put(
    url.replace("http://", "https://"),
    {},
    {
      headers: {
        idProducto: idProducto,
      },
    }
  );
  return res.data;
};

export const ObtenerProductosSimples = async (url, idEmpresa) => {
  const res = await axios.get(
    `${url.replaceAll("http://", "https://")}?idEmpresa=${idEmpresa}`
  );

  return res.data;
};

export const AgregarProducto = async (url, nuevoElemento) => {
  const formData = new FormData();

  Object.keys(nuevoElemento).forEach((key) => {
    if (key === "imagenes" && Array.isArray(nuevoElemento[key])) {
      nuevoElemento[key].forEach((imagen, index) => {
        formData.append(
          `imagenes[${index}].IdImagenProducto`,
          imagen.idImagenProducto
        );
        formData.append(`imagenes[${index}].Url`, imagen.url || "");
        formData.append(`imagenes[${index}].Archivo`, imagen.archivo);
        formData.append(`imagenes[${index}].IsPrincipal`, imagen.isPrincipal);
      });
    } else if (key === "subProductos" && Array.isArray(nuevoElemento[key])) {
      nuevoElemento[key].forEach((subProducto, index) => {
        formData.append(
          `subProductos[${index}].IdProducto`,
          subProducto.idProducto
        );
        formData.append(
          `subProductos[${index}].Cantidad`,
          subProducto.cantidad || ""
        );
        formData.append(`subProductos[${index}].Nombre`, subProducto.nombre);
        formData.append(
          `subProductos[${index}].Costo`,
          subProducto.costo || ""
        );
        formData.append(
          `subProductos[${index}].Codigo`,
          subProducto.codigo || ""
        );
      });
    } else {
      formData.append(key, nuevoElemento[key]);
    }
  });

  const res = await axios.post(
    url.replaceAll("http://", "https://"),
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return res.data;
};

export const CambiarEstadoMasivoProducto = async (url, idsProductos) => {
  const res = await axios.put(url.replace("http://", "https://"), idsProductos);

  return res.data;
};

//EndPoints Transacciones
export const ObtenerSeccionesTransaccion = async (url, idEmpresa) => {
  const res = await axios.get(
    `${url.replaceAll("http://", "https://")}?idEmpresa=${idEmpresa}
    `
  );

  return res.data;
};

export const ObtenerTiposTransaccion = async () => {
  const res = await axios.get("/api/transacciones/obtener-tipos-transacciones");

  return res.data;
};

export const ObtenerSecuencialPerTransaccion = async (
  idEmpresa,
  idTipoTransaccion
) => {
  const res = await axios.get("/api/transacciones/obtener-numero-transaccion", {
    headers: {
      idEmpresa: idEmpresa,
      idTipoTransaccion: idTipoTransaccion,
    },
  });

  return res.data;
};

export const ObtenerTransaccionesEmpresa = async (
  url,
  idEmpresa,
  pageSize,
  pageNumber,
  fechaInicio,
  fechaFin,
  idTipoTransaccion,
  idSeccion
) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&pageNumber=${pageNumber}&pageSize=${pageSize}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&idTipoTransaccion=${idTipoTransaccion}&idSeccion=${idSeccion}`
  );
  return res.data;
};


export const DescargarPdfTransaccion = async (selectedTransaccion) => {
  const res = await axios.post(
    `${"/api/transacciones/generar-pdf-transaccion".replaceAll(
      "http://",
      "https://"
    )}`, selectedTransaccion, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return res.data;
};

export const GuardarTransaccion = async (url, transaccion) => {
  const res = await axios.post(
    `${url.replaceAll("http://", "https://")}`,
    transaccion
  );

  return res.data;
};

export const ObtenerProductosIngreso = async (url, idEmpresa) => {
  const res = await axios.get(
    `${url.replaceAll("http://", "https://")}?idEmpresa=${idEmpresa}`
  );

  return res.data;
};

export const ObtenerProductosTransaccion = async (
  url,
  idEmpresa,
  idSeccion
) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&idSeccion=${idSeccion}
    `
  );

  return res.data;
};

export const ObtenerProductosAjuste = async (url, idEmpresa, idSeccion) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&idSeccion=${idSeccion}&isAjuste=${true}
    `
  );

  return res.data;
};

export const ObtenerProductosEgreso = async (url, idEmpresa, idSeccion) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&idSeccion=${idSeccion}&isEgreso=${true}
    `
  );

  return res.data;
};

// Endpoints Kardex Valorado

export const ObtenerKardex = async (
  url,
  idEmpresa,
  fechaInicio,
  fechaFinal,
  idCategoria,
  idProducto,
  idSeccion
) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&fechaInicio=${fechaInicio}&fechaFinal=${fechaFinal}&idCategoria=${idCategoria}&idProducto=${idProducto}&idSeccion=${idSeccion}
    `
  );

  return res.data;
};

export const GenerarExcelKardex = async (idEmpresa, filtros) => {
  const res = await axios.post(
    `${"/api/kardex/generar-kardex-excel".replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&fechaInicio=${filtros.fechaInicio}&fechaFinal=${filtros.fechaFinal
    }&idCategoria=${filtros.idCategoria}&idProducto=${filtros.idProducto
    }&idSeccion=${filtros.idSeccion}`
  );
  return res.data;
};


export const GenerarPdfKardex = async (idEmpresa, filtros) => {
  const res = await axios.post(
    `${"/api/kardex/generar-kardex-pdf".replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&fechaInicio=${filtros.fechaInicio}&fechaFinal=${filtros.fechaFinal
    }&idCategoria=${filtros.idCategoria}&idProducto=${filtros.idProducto
    }&idSeccion=${filtros.idSeccion}`
  );
  return res.data;
};

//Endpoints Valoracion Inventario


export const ObtenerValoracionInventario = async (url, idEmpresa, idCategoria, idSeccion, periodo) => {
  const res = await axios.get(
    `${url.replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&idCategoria=${idCategoria}&idSeccion=${idSeccion}&periodo=${periodo}
    `
  );

  return res.data;
}

export const GenerarExcelValoracionInventario = async (idEmpresa, filtros) => {
  const res = await axios.post(
    `${"/api/valoracionInventario/generar-valoracionInventario-excel".replaceAll(
      "http://",
      "https://"
    )}?idEmpresa=${idEmpresa}&idCategoria=${filtros.idCategoria}&idSeccion=${filtros.idSeccion}&periodo=${filtros.periodo}`
  );
  return res.data;
};