import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { StyleInput2 } from "../../../features/Constantes";
import { Switch } from "@headlessui/react";

export const PersonalizacionEmpresa = ({
  register,
  getValues,
  setValue,
  setEnabledCheck,
  enabledCheck,
  watch,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSwitchActivoMessage = () => {
    setEnabledCheck(!enabledCheck);
    setValue("vigencia", enabledCheck ? false : true);
  };

  return (
    <div className="my-3">
      <div className="bg-[#00314D] flex justify-between items-center p-3 rounded-t-lg  text-white">
        <p>Opciones de Personalización</p>
        <svg
          onClick={() => setIsOpen(!isOpen)}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`cursor-pointer w-4 h-4 ${
            isOpen ? "rotate-180" : ""
          } transition-all duration-200`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m19.5 8.25-7.5 7.5-7.5-7.5"
          />
        </svg>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="bg-white border dark:border-gray-600 dark:bg-gray-700 px-4 py-4 rounded-b-lg flex flex-row items-center gap-4">
          <div className="relative z-0 lg:col-span-3 w-full">
            <label
              htmlFor="company-website"
              className="flex flex-row gap-6 items-center text-sm font-medium text-black dark:text-white"
            >
              {" "}
              Mensaje Personalizado{" "}
              {watch("mensajePersonalizado") && (
                <div className="flex space-x-2 gap-1 items-center">
                  <div className="">
                    <Switch
                      checked={getValues().vigencia}
                      onChange={handleSwitchActivoMessage}
                      className={`${
                        enabledCheck ? "bg-blue-600" : "bg-gray-400"
                      } relative inline-flex flex-shrink-0 md:h-6 md:w-11 w-5 h-3  border-2 my-auto border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    >
                      <span
                        aria-hidden="true"
                        className={`${
                          enabledCheck ? "translate-x-5" : "translate-x-0"
                        } pointer-events-none inline-block md:h-5 md:w-5 w-2 h-2 transform rounded-full bg-white  shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                  </div>
                  <label
                    htmlFor="company-website"
                    className="block text-sm  font-medium text-gray-700 dark:text-white"
                  >
                    Activo
                  </label>
                </div>
              )}
            </label>
            <div className="mt-2 flex rounded-md shadow-sm">
              <span className="inline-flex items-center dark:bg-gray-700 dark:border-gray-600  px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  height={"25"}
                  width={"25"}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  s
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M8 11H8.01M12 11H12.01M16 11H16.01M21 20L17.6757 18.3378C17.4237 18.2118 17.2977 18.1488 17.1656 18.1044C17.0484 18.065 16.9277 18.0365 16.8052 18.0193C16.6672 18 16.5263 18 16.2446 18H6.2C5.07989 18 4.51984 18 4.09202 17.782C3.71569 17.5903 3.40973 17.2843 3.21799 16.908C3 16.4802 3 15.9201 3 14.8V7.2C3 6.07989 3 5.51984 3.21799 5.09202C3.40973 4.71569 3.71569 4.40973 4.09202 4.21799C4.51984 4 5.0799 4 6.2 4H17.8C18.9201 4 19.4802 4 19.908 4.21799C20.2843 4.40973 20.5903 4.71569 20.782 5.09202C21 5.51984 21 6.0799 21 7.2V20Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </span>
              <textarea
                id="mensajePersonalizado"
                name="mensajePersonalizado"
                className={StyleInput2 + " h-[150px]"}
                {...register("mensajePersonalizado")}
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};
