import { create } from 'zustand'

export const ModalCategoriasStatus = {
    off: "off",
    editar: "editar",
    eliminar: "eliminar",
    desabilitar: "desabilitar"
}

export const useModalCategoriaStore = create((set) => ({
  ModalCategoria: ModalCategoriasStatus.off,
  CategoriaAfectada: null,
  changeCategoriaModal: (status, categoria) => set({ModalCategoria: status, CategoriaAfectada: categoria}),
  turnCategoriaModalOff: () => set({ModalCategoria: ModalCategoriasStatus.off, CategoriaAfectada: null}),
}))