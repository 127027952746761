import { CategoriaProvider } from "../context/CategoriaContext";
import { CrearCategoria } from "./CrearCategoria";

export const IndexCategoria = () => {
  return (
    <CategoriaProvider>
      <CrearCategoria />
    </CategoriaProvider>
  );
};
