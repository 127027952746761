import { KardexValoradoBody } from "./KardexValoradoBody";
import { KardexValoradoHeader } from "./KardexValoradoHeader";

export const KardexValorado = () => {
  return (
    <section className="w-full h-full flex flex-col gap-5">
      <KardexValoradoHeader />
      <KardexValoradoBody />
    </section>
  );
};
