export const KardexRow = [
  "Fecha",
  "Documento",
  "Empresa",
  "Descripción",
  "Cuenta Analitica",
  "Ingreso",
  "Egreso",
  "Stock",
  "Costo Unitario",
  "Costo Promedio",
  "Debe",
  "Haber",
  "Saldo",
];

export const KardexCompuestoRow = [
  "Fecha",
  "Documento",
  "Empresa",
  "Descripción",
  "Cuenta Analitica",
  "Egreso",
]
