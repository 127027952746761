import ProgressBar from "./ProgressBar";

export const ModalSubirExcel = ({ isVisible, progress, elemento }) =>
  isVisible ? (
    <div
      className={`fixed inset-0 bg-black/25 bg-opacity-50 flex items-center justify-center z-50`}
    >
      <div className="relative w-1/2 max-w-3xl bg-white rounded-lg overflow-hidden">
        <div className="relative flex flex-col w-full max-h-screen overflow-y-auto overflow-x-hidden border-none shadow-lg rounded-md outline-none text-current">
          <div className="p-8">
            <h2 className="text-lg text-center py-4">Subiendo {elemento}..</h2>
            <ProgressBar progress={progress} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
