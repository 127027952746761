import { FileIcon } from "../../../../Icons/FileIcon";
import { ValoracionInventarioForm } from "./ValoracionInventarioForm";

export const ValoracionInventarioHeader = () => {
  return (
    <section className="w-full bg-white dark:bg-gray-900 rounded-lg p-4 shadow-md flex flex-col gap-4">
      <h1  className="font-semibold ml-3 text-xl flex flex-row gap-2 p-2 pr-0 pl-0 dark:text-white border-b border-gray-200 items-center">
        <span>
          <FileIcon />
        </span>
        Informe de Valoración de Inventario
      </h1>
      <div>
        <ValoracionInventarioForm />
      </div>
    </section>
  );
};
