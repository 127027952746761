import { useContext, useEffect, useState } from "react";
import { IngresoProviderContext } from "./Context/IngresoProvider";
import * as XLSX from "xlsx";
import {
  TableStyle,
  TableStyleDiv,
  TableStyleTBody,
  TableStyleTHead,
} from "../../../../../features/Constantes";
import {
  AddIcon,
  DeleteIcon,
  DownloadIcon,
  UploadIcon,
} from "../../../../../Icons/ButtonIcons";
import { AutoCompleteProduct } from "../Shared/AutoCompleteProduct";
import useGlobalToastify from "../../../../../features/hooks/GlobalToastify/useGlobalToastify";
import {
  ObtenerProductosIngreso,
  ObtenerConfiguracionInventarioEmpresa,
  ObtenerProductosAjuste,
} from "../../../../../services/InventarioService";
import { useSelector } from "react-redux";

export const handleKeyPress = (e) => {
  const pattern = /[0-9]/;
  const inputChar = String.fromCharCode(e.charCode);

  if (!pattern.test(inputChar)) {
    e.preventDefault();
  }
};

export default function IngresoDetalle({ methods }) {
  const { setRows, rows } = useContext(IngresoProviderContext);
  const { WarningToast } = useGlobalToastify();
  const [trazabilidadDisponible, setTrazabilidadDisponible] = useState([]);
  const [productosPerSecciones, setproductosPerSecciones] = useState([]);
  const empresa = useSelector((state) => state.empresa.empresa);
  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerConfiguracionInventarioEmpresa(
        "/api/trazabilidad/obtener-trazabilidad-empresa",
        empresa.idEmpresa
      ).then((response) => setTrazabilidadDisponible(response.data));
    }
  }, [empresa]);

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        codigo: "",
        cuenta: "",
        cantidad: "",
        costo: "",
        lote: "",
        fechaCaducidad: "",
      },
    ]);
  };

  useEffect(() => {
    if (empresa.idEmpresa && methods.watch("ubicacion")) {
      ObtenerProductosAjuste(
        "/api/producto/obtener-productos-transaccion",
        empresa.idEmpresa,
        methods.watch("ubicacion").idSeccion
      ).then((response) => {
        setproductosPerSecciones(response.data);
      });
    } else {
      setproductosPerSecciones([]);
    }
  }, [methods.watch("ubicacion"), empresa]);

  const [fileInputKey, setFileInputKey] = useState(Date.now());

  useEffect(() => {
    const newRows = rows.map((row) => {
      row.codigo = "";
      row.costo = "";
      return row;
    });
    setRows(newRows);
  }, [methods.watch("ubicacion")]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
  };

  const handleChangeProduct = (index, value) => {
    const newRows = [...rows];
    newRows[index]["codigo"] = value;
    newRows[index]["costo"] = value?.costo ? value?.costo.toFixed(2) : "";
    setRows(newRows);
  };

  const exportRows = () => {
    const templatePath = "/Plantilla_Transaccion.xlsx";
    fetch(templatePath)
      .then((response) => response.blob())
      .then((templateBlob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const templateData = reader.result;
          const wb = XLSX.read(templateData, { type: "array" });

          const wsName = wb.SheetNames[0];
          const ws = wb.Sheets[wsName];

          const rowsWithProducts = rows.filter((row) =>
            productosPerSecciones.includes(row.codigo)
          );
          const jsonData =
            rowsWithProducts.length === 0
              ? [
                  {
                    "Codigo / Producto": "",
                    "Cuenta Analitica": "",
                    Cantidad: "",
                    Costo: "",
                    Lote: "",
                    "Fecha de Expiración": "",
                  },
                ]
              : rowsWithProducts.map((row) => {
                  return {
                    "Codigo / Producto": row.codigo.nombre,
                    "Cuenta Analitica": row.cuenta,
                    Cantidad: row.cantidad,
                    Costo: row.costo,
                    Lote: row.lote,
                    "Fecha de Expiración": row.fechaCaducidad,
                  };
                });
          XLSX.utils.sheet_add_json(ws, jsonData, { origin: "A1" });

          XLSX.writeFile(wb, `Detalles_Ingreso.xlsx`);
        };
        reader.readAsArrayBuffer(templateBlob);
      })
      .catch((error) => console.error("Error al cargar la plantilla:", error));
  };

  const importRows = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const xlData = XLSX.utils.sheet_to_json(worksheet, { header: 0 });
      let registrosNoUploaded = [];
      for (const row of xlData) {
        if (Object.values(row).every((value) => value === "")) continue;
        const existingProduct = productosPerSecciones.find(
          (producto) =>
            String(producto.codigo) === String(row["Codigo / Producto"]) ||
            producto.nombre.toLowerCase() ===
              String(row["Codigo / Producto"]).toLowerCase()
        );
        if (!existingProduct) {
          registrosNoUploaded.push(row);
          continue;
        }
        

        const newRow = {
          codigo: existingProduct,
          cuenta: row["Cuenta Analitica"],
          cantidad: row["Cantidad"],
          costo: existingProduct.costo,
          lote: row.Lote ? row.Lote : "",
          fechaCaducidad: row["Fecha de Expiración"]
            ? row["Fecha de Expiración"]
            : "",
        };
        try {
          setRows((prevState) => [
            ...prevState.filter((row) =>
              Object.values(row).some(
                (value) => value !== "" && value !== null && value !== undefined
              )
            ),
            newRow,
          ]);
        } catch (err) {
          console.log(err);
        }
      }
      if (registrosNoUploaded.length > 0) {
        WarningToast(
          "No se subieron algunos registros que hacen referencia a productos no existentes."
        );
      }
    };
    reader.readAsArrayBuffer(file);
    setFileInputKey(Date.now());
  };

  return (
    <>
      <div>
        <div
          name="InformeGeneral"
          className="border border-gray-300 dark:border-gray-600 rounded-lg"
        >
          <p className="bg-[#003B5B] text-white font-semibold rounded-t-lg p-3">
            Detalle de la Transacción
          </p>
          <div className="p-4 flex flex-col gap-3">
            <div className="flex flex-row gap-1 text-white font-semibold text-[12px]">
              <button
                type="button"
                onClick={handleAddRow}
                className="p-[13px] rounded-lg bg-blue-600 hover:bg-blue-700 pr-5 pl-5 h-fit flex flex-row gap-2 items-center"
              >
                <span>
                  <AddIcon />
                </span>
                Agregar
              </button>
              <div className="h-fit w-fit">
                <label
                  htmlFor="importInput"
                  className="p-[13px] cursor-pointer rounded-lg bg-blue-600 hover:bg-blue-700 pl-5 pr-5 flex flex-row gap-2 items-center"
                >
                  <span>
                    <UploadIcon />
                  </span>
                  Importar
                </label>
                <input
                  key={fileInputKey}
                  className="absolute -left-[9999px]"
                  id="importInput"
                  onChange={importRows}
                  accept=".xlsx"
                  type="file"
                />
              </div>
              <button
                type="button"
                onClick={exportRows}
                className="p-3 md:pl-8 md:pr-8 pr-5 pl-5 rounded-lg bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center"
              >
                {" "}
                <span>
                  <DownloadIcon />
                </span>
                <span className="hide-on-small">Descargar datos</span>
              </button>
            </div>
            <div className="overflow-x-auto overflow-y-visible pb-4 px-4 pt-2">
              <div className="inline-block min-w-full align-middle  ">
                <div
                  className={TableStyleDiv + " whitespace-nowrap rounded-lg"}
                >
                  <table className={TableStyle}>
                    <thead
                      className={
                        TableStyleTHead + " bg-gray-200  text-sm divide-x"
                      }
                    >
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Codigo / Producto
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Cuenta Analitica
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Cantidad
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Costo
                        </th>
                        {trazabilidadDisponible.includes(
                          "dc1bb646-0470-4eac-95cb-8f0fb4a3e558"
                        ) && (
                          <th
                            scope="col"
                            className="px-3 py-3.5  border-2 dark:text-white border-gray-300 dark:border-gray-600"
                          >
                            Lote
                          </th>
                        )}
                        {trazabilidadDisponible.includes(
                          "db8ef384-8263-4831-be1e-3dd5a4bcba90"
                        ) && (
                          <th
                            scope="col"
                            className="px-3 py-3.5  border-2 dark:text-white border-gray-300 dark:border-gray-600"
                          >
                            Fecha de Expiración
                          </th>
                        )}
                        <th
                          scope="col"
                          className="px-3 py-3.5  border-2 dark:text-white border-gray-300 dark:border-gray-600"
                        >
                          Eliminar
                        </th>
                      </tr>
                    </thead>
                    <tbody className={TableStyleTBody}>
                      {rows.map((row, index) => (
                        <tr key={index} className="text-center">
                          <td className="p-2 border-2 border-gray-300  dark:border-gray-600">
                            <AutoCompleteProduct
                              value={row.codigo}
                              onChange={handleChangeProduct}
                              noOption={"No hay productos disponibles"}
                              index={index}
                              productos={productosPerSecciones}
                            />
                          </td>
                          <td className="border-2 border-gray-300 dark:border-gray-600">
                            <input
                              type="text"
                              name="cuenta"
                              value={row.cuenta}
                              onChange={(e) => handleChange(index, e)}
                              className="text-center p-1 w-full dark:text-white dark:bg-gray-800 focus:outline-none focus:border-none"
                            />
                          </td>
                          <td className="border-2 border-gray-300 dark:border-gray-600">
                            <input
                              type="number"
                              name="cantidad"
                              required
                              min={1}
                              onKeyPress={handleKeyPress}
                              value={
                                row.cantidad
                                  ? Math.abs(parseFloat(row.cantidad))
                                  : ""
                              }
                              onChange={(e) => handleChange(index, e)}
                              className="text-center p-1 w-full dark:text-white dark:bg-gray-800 focus:outline-none focus:border-none"
                            />
                          </td>
                          <td className="border-2 border-gray-300 dark:border-gray-600">
                            <input
                              readOnly={
                                row.codigo?.isCompuesto
                                  ? row.codigo?.isCompuesto
                                  : false
                              }
                              type="number"
                              name="costo"
                              min={0.000001}
                              step={0.000001}
                              required
                              value={row.costo}
                              onChange={(e) => handleChange(index, e)}
                              className="text-center p-1 w-full dark:text-white dark:bg-gray-800 focus:outline-none focus:border-none"
                            />
                          </td>
                          {trazabilidadDisponible.includes(
                            "dc1bb646-0470-4eac-95cb-8f0fb4a3e558"
                          ) && (
                            <td className="border-2 border-gray-300 dark:border-gray-600">
                              <input
                                type="text"
                                required={
                                  row.codigo?.isLote
                                    ? row.codigo?.isLote
                                    : false
                                }
                                onInvalid={(e) => {
                                  if (
                                    row.codigo.isLote === true &&
                                    e.target.value === ""
                                  ) {
                                    e.target.setCustomValidity(
                                      "Este producto requiere control de Lote"
                                    );
                                  }
                                }}
                                name="lote"
                                value={row.lote}
                                onInput={(e) => e.target.setCustomValidity("")}
                                onChange={(e) => handleChange(index, e)}
                                className=" text-center  p-1 w-full dark:text-white dark:bg-gray-800 focus:outline-none focus:border-none"
                              />
                            </td>
                          )}
                          {trazabilidadDisponible.includes(
                            "db8ef384-8263-4831-be1e-3dd5a4bcba90"
                          ) && (
                            <td className="border-2 border-gray-300 dark:border-gray-600">
                              <input
                                type="date"
                                required={
                                  row.codigo?.isFechaCaducidad
                                    ? row.codigo?.isFechaCaducidad
                                    : false
                                }
                                onInvalid={(e) => {
                                  if (
                                    row.codigo.isFechaCaducidad === true &&
                                    e.target.value === ""
                                  ) {
                                    e.target.setCustomValidity(
                                      "Este producto requiere control de Fecha de Expiración"
                                    );
                                  }
                                }}
                                onInput={(e) => e.target.setCustomValidity("")}
                                name="fechaCaducidad"
                                value={row.fechaCaducidad}
                                onChange={(e) => handleChange(index, e)}
                                className="text-center  p-1 w-full dark:text-white dark:bg-gray-800 focus:outline-none focus:border-none"
                              />
                            </td>
                          )}
                          <td className="border-2 border-gray-300 dark:border-gray-600">
                            <button
                              type="button"
                              onClick={() => {
                                const newRows = rows.filter(
                                  (_, i) => i !== index
                                );
                                setRows(newRows);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
