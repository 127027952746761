import { useState } from "react";
export const useCheckInventario = (elementos, id) => {
  const [IsCheckedAll, setIsCheckedAll] = useState(false);
  const [elementosChecked, setElementosChecked] = useState([]);

  const handleSelectAll = () => {
    if (!IsCheckedAll) {
      const allIDs = elementos.map((el) => el[id]);
      const AllItems = new Set([...allIDs, ...elementosChecked]);
      setElementosChecked([...AllItems]);
    } else {
      setElementosChecked([]);
    }
  };

  const handleClick = (id) => {
    const isChecked = elementosChecked.find((el) => el === id);
    if (isChecked) {
      const itemsWithoutElement = elementosChecked.filter((el) => el !== id);
      setIsCheckedAll(false);
      setElementosChecked([...itemsWithoutElement]);
    } else {
      setElementosChecked((prevState) => [...prevState, id]);
    }
  };

  const handleSubmitChecked = () => {
    return [...elementosChecked];
  };

  const CleanChecks = () => {
    setElementosChecked([]);
    setIsCheckedAll(false);
  };

  return {
    handleSelectAll,
    handleClick,
    handleSubmitChecked,
    IsCheckedAll,
    setIsCheckedAll,
    elementosChecked,
    setElementosChecked,
    CleanChecks,
  };
};
