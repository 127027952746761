import { useContext } from "react";
import { FormProvider } from "react-hook-form";
import { TransferenciasProviderContext } from "./Context/TransferenciasProvider";
import TransferenciasGeneral from "./TransferenciasGeneral";
import TransferenciasDetalle from "./TransferenciasDetalle";
import { TransaccionButtons } from "../../../Shared/TransaccionButtons";
import ModalTransferenciaAlertaStock from "./ModalTransferenciasAlertaStock";

export default function TransferenciasForm() {
  const {
    methods,
    setRows,
    secuencial,
    rowBase,
    isSending,
    isOpen,
    validateTransferencia,
  } = useContext(TransferenciasProviderContext);

  const DeleteData = () => {
    setRows([{ ...rowBase }]);
    methods.reset();
    methods.setValue("ubicacionRecibe", "");
    methods.setValue("ubicacionEnvia", "");
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(validateTransferencia)}
          autoComplete="off"
          className="flex flex-col gap-2"
        >
          <div className="bg-white dark:bg-gray-900 p-4 pb-0 rounded-lg">
            <div className="bg-gray-200 dark:bg-gray-700 rounded-lg p-4 flex justify-between items-center">
              <p className="font-semibold text-xl ml-3 dark:text-white">
                Transacción
              </p>
              <div className="flex items-center space-x-3">
                <p className="hide-on-small dark:text-white">Transferencia</p>
                <input
                  type="text"
                  placeholder="Número de transacción"
                  {...methods.register("numeroTransaccion")}
                  className="border border-gray-400 bg-white rounded-md text-center p-1 w-full"
                  value={secuencial.transaccion}
                />
              </div>
            </div>
          </div>
          <div className="p-4 bg-white dark:bg-gray-900 rounded-lg">
            <div className="flex flex-col gap-4">
              <TransferenciasGeneral methods={methods} />
              <TransferenciasDetalle methods={methods} />
            </div>
            <TransaccionButtons DeleteData={DeleteData} isSending={isSending} />
          </div>
        </form>
      </FormProvider>
      {isOpen && <ModalTransferenciaAlertaStock />}
    </>
  );
}
