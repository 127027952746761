import { useSelector } from "react-redux";

export const KardexValoradoItem = ({ registro, isCompuesto }) => {
  const empresa = useSelector((state) => state.empresa.empresa);

  return (
    <tr className="bg-white dark:bg-gray-700 dark:text-white font-normal border-y-2 border-gray-300 dark:border-gray-600">
      <th style={{ padding: "15px" }} className="whitespace-nowrap font-normal">
        {registro.fecha}
      </th>
      <th className="font-normal">{registro.documento}</th>
      <th className="font-normal">{empresa.razonSocial}</th>
      <th className="font-normal">{registro?.detalle}</th>
      <th className="font-normal">
        {registro.cuentaAnalitica ? registro.cuentaAnalitica : "-"}
      </th>
      {!isCompuesto && <th className="font-normal">
        {registro?.ingreso ? `${Number(registro?.ingreso)}` : "-"}
      </th>}
      <th className="font-normal">
        {registro?.egreso ? `${Number(registro?.egreso)}` : "-"}
      </th>
      {!isCompuesto && <th className="font-normal">{registro.stock}</th>}
      {!isCompuesto && <th className="font-normal">
        ${" "}
        {Number(registro.costoUnitario).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </th>}
     {!isCompuesto && <th className="font-normal">
        ${" "}
        {Number(registro.costoPromedio).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </th>}
      {!isCompuesto && <th className="font-normal">
        {registro?.debe
          ? `$ ${Number(registro?.debe).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "-"}
      </th>}
      {!isCompuesto && <th className="font-normal">
        {registro?.haber
          ? `$ ${Number(registro?.haber).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "-"}
      </th>}
      {!isCompuesto && <th className="font-normal" style={{ padding: "15px" }}>
        ${" "}
        {Number(registro.saldo).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </th>}
    </tr>
  );
};
