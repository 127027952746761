import { CrearCategoriaList } from "./CrearCategoriaList";
import { CrearCategoriaForm } from "./CrearCategoriaForm";
import { useStore } from "zustand";
import {
  ModalCategoriasStatus,
  useModalCategoriaStore,
} from "./ModalCategoriaContext";
import { ModalEliminarCategoria } from "./ModalEliminarCategoria";
import { setModalBase } from "../../Shared/ModalBase";
import { useContext, useEffect } from "react";
import { CategoriaContext } from "../context/CategoriaContext";
import { useCheckInventario } from "../../hooks/useCheck";
import { ModalEditarCategoria } from "./ModalEditarCategoria";
import { ModalDesabilitarCategoria } from "./ModalDesabilitarCategoria";

export const CrearCategoria = () => {
  const { CategoriaAfectada, ModalCategoria } = useStore(
    useModalCategoriaStore
  );
  const { categorias } = useContext(CategoriaContext);
  const CheckedHookCategoria = useCheckInventario(
    categorias,
    "idCategoriaProducto"
  );

  return (
    <>
      <section className="w-full h-full flex flex-col gap-5">
        <CrearCategoriaForm
          CheckedHookCategoria={CheckedHookCategoria}
          categorias={categorias}
        />
        <CrearCategoriaList
          categorias={categorias}
          CheckedHookCategoria={CheckedHookCategoria}
        />
      </section>
      {CategoriaAfectada &&
        ModalCategoria === ModalCategoriasStatus.eliminar && (
          <ModalEliminarCategoria />
        )}
      {CategoriaAfectada && ModalCategoria === ModalCategoriasStatus.editar && (
        <ModalEditarCategoria />
      )}
      {ModalCategoria === ModalCategoriasStatus.desabilitar && (
        <ModalDesabilitarCategoria
          CheckedHookCategoria={CheckedHookCategoria}
        />
      )}
    </>
  );
};
