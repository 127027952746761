import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListV2 } from "../../../services";

import { EmitirLiquidacionContext } from "./EmitirLiquidacionProvider";

import { redondear } from "../../../services/Utilitario";
import {
  StyleInput,
  StyleInputError,
  StyleLabel,
  StyleLabelError,
  StyleSelect,
} from "../../../features/Constantes";
import MesageWarningTable from "../../../features/components/MesageWarningTable";
import { LargeModalForm } from "../../../features/components/LargeModalForm";
import NumberFormat from "react-number-format";
import InputNumber from "../../../features/components/common/InputNumber";
import LengthCounter from "../../../features/components/LengthCounter";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Modals = () => {
  const {
    DetalleItem,
    setDetalleItem,
    setActivarModalDetalle,
    DetalleList,
    setDetalleList,
  } = useContext(EmitirLiquidacionContext);

  const [TempDetalleItem, setTempDetalleItem] = useState({});
  const [ImpuestosList, setImpuestosList] = useState([]);
  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [inputNombre, setInputNombre] = useState("");
  const [inputValor, setInputValor] = useState("");

  const {
    register,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      descripcion: "",
    },
  });

  const empresa = useSelector((store) => store.empresa.empresa);

  useEffect(() => {
    // console.log(DetalleItem);
    setTempDetalleItem({ ...DetalleItem });
    if (DetalleItem.servicioDetalle) {
      let listItems = [];
      DetalleItem?.servicioDetalle?.forEach((el) => {
        listItems.push(el);
      });
      setInformacionAdicionalList(listItems);
    }

    (async () => {
      let data = await getListV2(
        empresa,
        "GetAllimpDetalles",
        "?activo=true&idImpuesto=1"
      );
      setImpuestosList(data._embedded);
    })();
  }, [DetalleItem]);

  const handleChangeImpuesto = (e) => {
    const index = e.target.selectedOptions[0].getAttribute("data-index");

    const item = {
      ...TempDetalleItem,
      [e.target.name]: e.target.value,
      idImpuestoIvaNavigation: { ...ImpuestosList[index] },
    };
    setTempDetalleItem(item);
  };

  const calcularValorNeto = (item) => item.precioUnitario * item.cantidad;

  const calcularValorConDescuento = (item) =>
    calcularValorNeto(item) - item.descuento;

  const calcularTotal = (item) => {
    if (item.descuento === undefined) return 0;
    if (item.descuento === null) return 0;
    let valorTotal = item.idImpuestoIvaNavigation
      ? calcularValorConDescuento(item) +
        calcularValorConDescuento(item) *
          item.idImpuestoIvaNavigation.porcentaje
      : calcularValorConDescuento(item);
    return redondear(valorTotal);
  };

  const handleSubmitModal = () => {
    if (calcularTotal(TempDetalleItem) >= 0) {
      DetalleList[TempDetalleItem.index] = {
        ...TempDetalleItem,
        servicioDetalle: [...InformacionAdicionalList],
      };
      setDetalleList([...DetalleList]);
      document.getElementById("editDetalleFacturaModalclose").click();
      setDetalleItem({});
      setTempDetalleItem({});
      setActivarModalDetalle(false);
    }
  };

  const handleDeleteElement = (index) => {
    InformacionAdicionalList.splice(index, 1);
    setInformacionAdicionalList([...InformacionAdicionalList]);
  };

  const onBlurHandlerNombre = (event) => {
    setInputNombre(event.target.value);
  };

  const onBlurHandlerValor = (event) => {
    setInputValor(event.target.value);
  };

  const onClickHandlerInfoAdicional = (event) => {
    setIsLoading(true);
    if (inputNombre.trim().length < 1 || inputValor.trim().length < 1)
      return "";

    if (InformacionAdicionalList.length <= 2) {
      setInformacionAdicionalList([
        { nombre: inputNombre, valor: inputValor },
        ...InformacionAdicionalList,
      ]);
      setInputNombre("");
      setInputValor("");
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      toast.error(
        "No puedes agregar más de 3 detalles adicionales a un producto!"
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };
  const [Open, SetOpen] = useState(false);

  //<div className="p-4 max-w-xl mx-auto relative  left-0 right-0 overflow-hidden mt-24">
  //<div className="p-4 max-w-xl mx-auto  absolute left-0 right-0 overflow-hidden mt-24">
  return (
    <LargeModalForm
      buttonModal={"editDetalleFacturaModal"}
      submitText={"Guardar"}
      title={`EDITAR DETALLE | ${TempDetalleItem.codigoPrincipal}`}
      submitModal={handleSubmitModal}
    >
      <div className="w-full  mb-2 md:mb-0 my-2">
        <div className="relative z-0 mb-2 w-full group">
          <label
            htmlFor="descripcion"
            className={StyleLabel + " -translate-x-0 -translate-y-0"}
          >
            DESCRIPCIÓN{" "}
            <LengthCounter
              itemLength={
                watch("descripcion") === undefined
                  ? "hola"
                  : watch("descripcion")
              }
            />
          </label>
          <input
            type="text"
            placeholder=" "
            //id="guiaRemision"
            name="descripcion"
            className={errors["descripcion"] ? StyleInputError : StyleInput}
            {...register("descripcion", {
              required: {
                value: true,
                message: "Descripción es requerido",
              },
              maxLength: {
                value: 300,
                message: "Descripción no debe tener más de 300 carácteres!",
              },
              minLength: {
                value: 1,
                message: "Descripción debe tener mínimo 1 carácter",
              },
            })}
            maxLength="300"
            value={`${TempDetalleItem.descripcion}`}
            onChange={(e) =>
              setTempDetalleItem({
                ...TempDetalleItem,
                descripcion: e.target.value,
              })
            }
          />
        </div>
        <div className="grid grid-cols-4  gap-2">
          <div>
            <InputNumber
              identifier="cantidad"
              name="CANTIDAD"
              decimalMax={6}
              value={TempDetalleItem.cantidad}
              onChange={(e) =>
                setTempDetalleItem({
                  ...TempDetalleItem,
                  cantidad: e.target.value,
                })
              }
            />
          </div>
          <div>
            <InputNumber
              identifier="precioUnitario"
              name="PVP UNITARIO"
              decimalMax={6}
              value={TempDetalleItem.precioUnitario}
              onChange={(e) =>
                setTempDetalleItem({
                  ...TempDetalleItem,
                  precioUnitario: e.target.value,
                })
              }
            />
          </div>

          <div>
            <p className={"dark:text-gray-300 text-sm font-medium text-slate-700"}>PVP * CANTIDAD</p>
            <NumberFormat
              decimalScale={2}
              displayType={"text"}
              thousandSeparator={true}
              placeholder=" "
              className={StyleInput + " bg-[#EBEBEB]"}
              value={calcularValorNeto(TempDetalleItem)}
              name="pvpCantidad"
              id="pvpCantidad"
              readOnly
            />
          </div>
          <div>
            <InputNumber
              identifier="descuento"
              name="DESCUENTO $"
              decimalMax={2}
              value={TempDetalleItem.descuento}
              onChange={(e) =>
                setTempDetalleItem({
                  ...TempDetalleItem,
                  descuento: e.target.value,
                })
              }
            />
          </div>
          <div></div>
          <div></div>
          <div>
            <label className={StyleLabel + " -translate-x-0 -translate-y-0"}>
              IMPUESTO
            </label>
            <select
              type="text"
              className={StyleSelect}
              placeholder=" "
              name="idImpuestoIva"
              id="idImpuestoIva"
              onChange={handleChangeImpuesto}
              value={TempDetalleItem.idImpuestoIva}
            >
              {ImpuestosList.map((el, index) => (
                <option
                  key={index}
                  data-index={index}
                  value={el.idImpuestoDetalle}
                >
                  {el.detalle}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label
              htmlFor="precioUnitario"
              className={StyleLabel + " -translate-x-0 -translate-y-0"}
            >
              TOTAL
            </label>
            <NumberFormat
              className={
                calcularTotal(TempDetalleItem) >= 0
                  ? StyleInput + " bg-[#EBEBEB]"
                  : StyleInputError
              }
              decimalScale={2}
              displayType={"text"}
              thousandSeparator={true}
              placeholder=" "
              id="subTotal"
              name="subTotal"
              value={calcularTotal(TempDetalleItem)}
              readOnly
            />
          </div>
        </div>
      </div>

      <div className="py-0  flex-wrap justify-between mb-0">
        <div>
          <div>
            <p
              onClick={() => SetOpen(!Open)}
              className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
            >
              Información adicional (Opcional){" "}
              {!Open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              )}
            </p>
            {Open ? (
              <>
                <div className="w-full pb-3 my-2 grid md:grid-cols-6 md:gap-2 mb-2 md:mb-0">
                  <div className="md:col-span-2 relative z-0 mb-2 w-full group">
                    <label
                      htmlFor="codigo"
                      className={StyleLabel + " -translate-x-0 -translate-y-0"}
                    >
                      NOMBRE
                    </label>
                    <input
                      type="text"
                      className={StyleInput}
                      placeholder=" "
                      id="nombre"
                      name="nombre"
                      onChange={onBlurHandlerNombre}
                      value={inputNombre}
                    />
                  </div>

                  <div className="md:col-span-3 relative z-0 mb-2 w-full group">
                    <label
                      className={StyleLabel + " -translate-x-0 -translate-y-0"}
                    >
                      DETALLE
                    </label>
                    <input
                      type="text"
                      className={StyleInput}
                      placeholder=" "
                      id="valor"
                      name="valor"
                      onChange={onBlurHandlerValor}
                      value={inputValor}
                    />
                  </div>

                  <div className="relative z-0 mb-2 w-full group">
                    <button
                      onClick={onClickHandlerInfoAdicional}
                      type="button"
                      className=" text-white  absolute bottom-0 bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-base px-6 py-2.5 text-center md:mr-5 mb-3 md:mb-0 inline-flex items-center justify-center"
                    >
                      {IsLoading ? (
                        "Cargando..."
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className=" w-5 h-5 dark:stroke-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </>
                      )}
                    </button>
                  </div>
                </div>

                {InformacionAdicionalList.length > 0 && (
                  <div className="overflow-x-auto  relative overflow-hidden shadow ring-1 ring-black  ring-opacity-5 md:rounded-lg">
                    <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase divide-y bg-gray-100 dark:bg-gray-600 dark:text-gray-400">
                        <tr>
                          <th
                            scope="col"
                            className="py-1 px-6 dark:text-white "
                          >
                            NOMBRE
                          </th>
                          <th
                            scope="col"
                            className="py-1 px-6 dark:text-white  "
                          >
                            DETALLE
                          </th>
                          <th scope="col" className="py-1 px-6"></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-600 bg-white">
                        {InformacionAdicionalList.length === 0 ? (
                          <MesageWarningTable
                            colSpan="3"
                            message="No se ha ingresado información"
                          />
                        ) : null}
                        {InformacionAdicionalList.map((el, index) => (
                          <tr
                            key={index}
                            className="bg-white dark:bg-gray-800 dark:hover:bg-gray-900 hover:bg-gray-200  border-b-gray-600  "
                          >
                            <td className="py-1 px-6">{el.nombre}</td>
                            <th
                              scope="row"
                              className="py-0 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {el.valor}
                            </th>
                            <td className="py-1 px-6 text-red-500 hover:text-red-600 ">
                              <button
                                onClick={() => handleDeleteElement(index)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default Modals;
