import React from "react";
import {
  StyleLabel,
  StyleLabelError,
  StyleSelect,
  StyleSelectError,
} from "../../Constantes";
import { useFormContext } from "react-hook-form";

const SelectForm = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <label
        htmlFor={props.name}
        className={
          errors[props.name]
            ? StyleLabelError
            : StyleLabel + ""
        }
      >
        {props.title || props.name}
      </label>
        <select
          className={errors[props.name] ? StyleSelectError : StyleSelect}
          {...register(props.name, props.validations)}
          {...props}
          id={props.id}
        >
          {props.options.map((el) => {
            return <option value={props.id === "Paises" ? el.value : el.value}>{el.name}</option>;
          })}
        </select>
    </>
  );
};

export default SelectForm;
