import { ConfiguracionModalOff } from "./modalConfiguracion.Slice";
import { useDispatch } from "react-redux";
import { ModalButtons } from "../Shared/ModalButtons";
import { useContext, useEffect, useState } from "react";
import { ValoracionContext } from "./context/ValoracionContext";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

export const ValoracionModal = () => {
  const dispatch = useDispatch();
  const {
    valoracion,
    valoracionEmpresa,
    saveChangesValoracion,
    setValoracionEmpresa,
    isLoading,
  } = useContext(ValoracionContext);

  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    toast.dismiss();
  }, []);

  const setValoracion = async (e) => {
    e.preventDefault();
    try {
      setIsSending(true);
      await toast.promise(saveChangesValoracion(), {
        pending: "Guardando cambios...",
        success: "¡Configuración de valoración guardada con éxito!",
        error: "Hubo un error al guardar la configuración de valoración.",
      });
      dispatch(ConfiguracionModalOff());
    } catch (err) {
      console.log(err);
      dispatch(ConfiguracionModalOff());
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex h-[250px] flex-row items-center w-full justify-center">
          <ClipLoader color="#d8d4d4" />
        </div>
      ) : (
        <>
          <form
            className="w-full flex flex-col gap-6 mt-6 items-center justify-center"
            onSubmit={(e) => setValoracion(e)}
          >
            <table className="w-[90%] border-1 text-sm border-gray-300 dark:border-gray-600">
              <thead>
                <tr className=" dark:text-white p-2 font-semibold">
                  <th
                    style={{ marginBottom: "10px" }}
                    className="text-center w-full flex justify-between items-center"
                  >
                    <span>Valor</span>
                    <span>Activo</span>
                  </th>
                </tr>
              </thead>
              <tbody className="text-black dark:text-white">
                {valoracion &&
                  valoracion.length > 0 &&
                  valoracion.map((valoracion) => {
                    return (
                      <tr
                        className="text-center p-2 border-gray-300 dark:border-gray-600"
                        key={valoracion.idValoracion}
                      >
                        <td
                          style={{ paddingTop: "6px", paddingBottom: "6px" }}
                          className="text-center pr-3 w-full flex justify-between items-center"
                        >
                          <label htmlFor={valoracion.idValoracion}>
                            {valoracion.nombre}
                          </label>
                          <input
                            key={valoracion.idValoracion}
                            checked={valoracionEmpresa.includes(
                              valoracion.idValoracion
                            )}
                            disabled
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setValoracionEmpresa((prevState) => [
                                  ...prevState,
                                  valoracion.idValoracion,
                                ]);
                              } else {
                                setValoracionEmpresa((prevState) =>
                                  prevState.filter(
                                    (id) => id !== valoracion.idValoracion
                                  )
                                );
                              }
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {/* <ModalButtons
              closeFn={ConfiguracionModalOff}
              setIsSending={isSending}
              useRedux={true}
            /> */}
          </form>
        </>
      )}
    </>
  );
};
