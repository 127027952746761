import { useContext, useEffect } from "react";
import { KardexValoradoList } from "./KardexValoradoList";
import { KardexContext } from "./context/KardexValoradoContext";
import { SyncLoader } from "react-spinners";

export const KardexValoradoBody = () => {
  const {
    Kardex,
    currentKardex,
    isLoading,
    GoNextKardex,
    GoBackKardex,
    setCurrentKardex,
  } = useContext(KardexContext);

  return (
    <>
      <div className="w-full bg-white dark:bg-gray-900 h-fit p-6 flex flex-col shadow-md rounded-lg">
        {Kardex[currentKardex]?.listado?.length > 0 && isLoading === false && (
          <>
            <KardexValoradoList kardex={Kardex[currentKardex]} />
            <div className="bg-white dark:bg-gray-700 dark:text-gray-400 px-4 py-3 my-2 flex items-center justify-between border-t border-gray-200 dark:border-gray-700 sm:px-6">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <span className="text-sm font-normal ml-2 text-gray-400 dark:text-gray-400">
                  Mostrando el N°{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {Kardex.length > 0 ? currentKardex + 1 : 0}{" "}
                  </span>
                  de{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {Kardex?.length}{" "}
                  </span>
                  Kardex
                </span>
                <div>
                  <nav
                    className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                    aria-label="Pagination"
                  >
                    <button
                      type="button"
                      onClick={() => setCurrentKardex(0)}
                      className="relative inline-flex items-center dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                    >
                      <span className="sr-only">First</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={() => GoBackKardex()}
                      className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                    >
                      <span className="sr-only">Previous</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>

                    <button
                      type="button"
                      onClick={() => GoNextKardex()}
                      className="bg-white border-gray-300 text-gray-400 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  relative inline-flex items-center px-2 py-2 border text-sm font-medium"
                    >
                      <span className="sr-only">Next</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <button
                      type="button"
                      onClick={() => setCurrentKardex(Kardex.length - 1)}
                      className="relative inline-flex items-center px-2 py-2 rounded-r-md border dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600  border-gray-300 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50"
                    >
                      <span className="sr-only">Last</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="h-5 w-5"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                        <path
                          fillRule="evenodd"
                          d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </nav>
                </div>
              </div>
            </div>{" "}
          </>
        )}
        {Kardex.length === 0 && isLoading === false && (
          <div className="bg-white dark:bg-gray-900 dark:text-white w-full flex gap-2 items-center justify-center p-36 text-[14px] text-center font-normal self-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span className="whitespace-nowrap">
              No se encontraron datos para la búsqueda.
            </span>
          </div>
        )}
        {isLoading && (
          <div className="bg-white dark:bg-gray-900 w-full flex items-center justify-center p-36 text-[14px] text-center font-normal self-center">
            <span>
              <SyncLoader color="#d8d4d4" />
            </span>
          </div>
        )}
      </div>
    </>
  );
};
