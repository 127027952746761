import { DeleteIcon } from "../../../../Icons/ButtonIcons";

export const ProductoCompuestoItem = ({ producto, deleteFn }) => {
  return (
    <tr className="bg-white dark:bg-gray-700 dark:text-white text-center font-normal border-y-2 border-gray-300 dark:border-gray-600">
      <th style={{ padding: "15px" }} className="font-normal">
        {`${producto.codigo}`}
      </th>
      <th className="font-normal">{producto.nombre}</th>
      <th className="font-normal">{producto.cantidad}</th>
      <th
        style={{ padding: "15px" }}
        className="flex justify-center flex-row gap-2"
      >
        <button onClick={() => deleteFn(producto.idProducto)} type="button">
          <DeleteIcon />
        </button>
      </th>
    </tr>
  );
};
