import { TransaccionesEmitidasForm } from "./TransaccionesEmitidasForm";
import { TransaccionesEmitidasList } from "./TransaccionesEmitidasList";
import { useContext } from "react";
import { TransaccionesContext } from "./context/TransaccionesContext";
import { useCheckInventario } from "../../hooks/useCheck";
import { TransaccionInfoModal } from "./TransaccionInfoModal";
import { BodegaProvider } from "../../Bodegas/Crear/context/BodegasContext";

export const TransaccionesEmitidas = () => {
  const { transacciones, selectedTransaccion } =
    useContext(TransaccionesContext);
  const CheckedHookTransacciones = useCheckInventario(
    transacciones,
    "idTransaccion"
  );

  return (
    <BodegaProvider>
      <section className="w-full h-full flex flex-col gap-5">
        <TransaccionesEmitidasForm
          CheckedHookTransacciones={CheckedHookTransacciones}
        />
        <TransaccionesEmitidasList
          CheckedHookTransacciones={CheckedHookTransacciones}
        />
      </section>
      {selectedTransaccion !== null && <TransaccionInfoModal />}
    </BodegaProvider>
  );
};
