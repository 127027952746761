import { useStore } from "zustand";
import { EditIcon } from "../../../../Icons/ButtonIcons";
import { DeleteIcon } from "../../../../Icons/ButtonIcons";
import {
  ModalProductosStatus,
  useModalProductoStore,
} from "./ModalProductoContext";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { ProductoContext } from "../context/ProductContext";
import { TransaccionesContext } from "../../TiposOperaciones/TransaccionesEmitidas/context/TransaccionesContext";

export const CrearProductoItem = ({
  Producto,
  ProductosChecked,
  handleClick,
}) => {
  const navigate = useNavigate();
  const { changeStateProducto } = useContext(ProductoContext);
  const { changeProductoModal, setProductoOnly } = useStore(
    useModalProductoStore
  );
  const goToEdit = () => {
    setProductoOnly(Producto);
    navigate("/inventario/productos/crear/editarProducto");
  };

  const ChangeActive = (id) => {
    changeStateProducto(id);
  };

  return (
    <tr
      className={`bg-white dark:bg-gray-700 dark:text-white text-center ${
        Producto.estado ? "" : "opacity-60"
      } font-normal  border-y-2 border-gray-300 dark:border-gray-600`}
    >
      <th style={{ padding: "15px" }}>
        <input
          type="checkbox"
          checked={
            ProductosChecked.find(
              (producto) => producto === Producto.idProducto
            )
              ? true
              : false
          }
          onChange={() => handleClick(Producto.idProducto)}
        />
      </th>
      <th className="font-normal">{Producto.codigo}</th>
      <th className="font-normal">{Producto.nombre}</th>
      <th className="font-normal">{Producto.nombreTipoProducto}</th>
      <th className="font-normal">{Producto.nombreCategoria}</th>
      <th className="font-normal">
        ${" "}
        {Number(Producto.precioVenta).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </th>
      <th>
        <input
          type="checkbox"
          checked={Producto.estado}
          onChange={(e) => ChangeActive(Producto.idProducto)}
        />
      </th>
      <th
        style={{ padding: "15px" }}
        className="flex justify-center flex-row gap-2"
      >
        <button onClick={() => goToEdit()}>
          <EditIcon />
        </button>

        <button
          onClick={() =>
            changeProductoModal(ModalProductosStatus.eliminar, Producto)
          }
        >
          <DeleteIcon />
        </button>
      </th>
    </tr>
  );
};
