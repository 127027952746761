import { NavLink } from "react-router-dom";
import { CancelIcon, SaveIcon } from "../../../Icons/ButtonIcons";
import { useNavigate } from "react-router-dom";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";

// Componente de Botones Guardar / Cancelar y Borrar Detalles en una transaccion

export const TransaccionButtons = ({ DeleteData, isSending }) => {
  const navigate = useNavigate();
  const { Toast } = useGlobalToastify();

  const goBack = () => {
    navigate("/inventario/operaciones/transacciones");
    Toast.dismiss();
  };

  return (
    <>
      <div className="flex justify-end mt-4 h-12 space-x-3 text-[12px]">
        <button
          type="button"
          onClick={() => DeleteData()}
          className="border p-2 px-3 border-gray-500 dark:text-white rounded-lg"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
        <button
          onClick={() => goBack()}
          className="p-3 pl-5 pr-5 rounded-lg text-white bg-gray-600 hover:bg-gray-700 flex flex-row gap-1 items-center"
        >
          Cancelar
          <span>
            <CancelIcon />
          </span>
        </button>{" "}
        <button
          type="submit"
          disabled={isSending}
          className="p-3 rounded-lg text-white bg-[#04BC53] hover:bg-green-500 pr-5 pl-5 flex flex-row gap-3 items-center"
        >
          Guardar
          <span>
            <SaveIcon />
          </span>
        </button>
      </div>
    </>
  );
};
