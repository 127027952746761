import React, { useContext, Fragment } from "react";
import useVistaPrevia from "../hooks/useVistaPrevia";
import { Menu, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { EmitirDocContext } from "./EmitirDocProvider";
import {
  getFechaV1,
  getNoventaDiasAtras,
  rellenarCeros,
} from "../../../services/Utilitario";
const MobileButtonOptionsRt = () => {
  const empresa = useSelector((store) => store.empresa.empresa);
  const { GetVistaPreviaRetencion, IsLoading } = useVistaPrevia();
  const { formik, setIsEdit, resetForm, DetalleList,Totales, DataFromAdd, InformacionAdicionalList, setgenxml, genxml } =
  useContext(EmitirDocContext);

  const handleVistaPrevia = async () => {
    let parsedInfo = {
      IdentificacionContribuyente: formik.values.clienteIdentificacion,
      RazonSocialContribuyente: formik.values.clienteRazonSocial,
      Direccion: formik.values.clienteDireccion,
      Correo: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
      TelefonoCelular: formik.values.clienteTelefono,
      CodigoComprobanteModificar: formik.values.codigoComprobanteModificar,
      IdContribuyente: formik.values.idContribuyente,
      IsPartRel:formik.values.IsPartRel==="1"?"True":"False",
      Ambiente: process.env.REACT_APP_ENVIRONMENT,
      IdEmpresa: empresa.idEmpresa,

      FechaEmision: getFechaV1(formik.values.fechaEmision),
      PeriodoFiscal: formik.values.periodoFiscal,
      ImporteTotal: Totales.importeTotal,
      TotalSinImpuestos: Totales.totalSinImpuesto,
      TotalDescuento: Totales.totalDescuentos,
      DocsSustento: DataFromAdd,
      infoAdicional: InformacionAdicionalList.map((x) => {
        return {
          Descripcion: x.nombre,
          Valor: x.valor,
        };
      }),
      IdImpuestoNoObjectoImpuesto:
        Totales.subTotalNoObjetoIva !== 0
          ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
          : "",
      IdImpuestoExentoIva:
        Totales.subTotalExentoIva !== 0
          ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
          : "",
      IdImpuestoIVA12:
        Totales.subTotalIva !== 0
          ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
          : "",
      IdImpuestoIVA0:
        Totales.subTotalIva0 !== 0
          ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
          : "",
      TotalTotalIva0: 0,
      TotalTotalIva: Totales.totalIva,
      TotalNoObjetoImpuesto: 0,
      TotalExentoIva: 0,
      TotalBaseIva0: Totales.subTotalIva0,
      TotalBaseIva: Totales.subTotalIva,
      TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
      TotalBaseExentoIva: Totales.subTotalExentoIva,
      Establecimiento: formik.values.establecimiento,
      PuntoEmision: formik.values.puntoEmision,
    };
    if (formik.values.clienteEmail !== null)
      if (formik.values.clienteEmail.trim() !== "")
        parsedInfo.infoAdicional.push({
          Valor: formik.values.clienteEmail.replace(/,/g, ";").replace(/\s+/g, ""),
          Descripcion: "email",
        });

    let data = await GetVistaPreviaRetencion(parsedInfo);
    let byteCharacters = atob(data);
    let byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    let byteArray = new Uint8Array(byteNumbers);
    let file = new Blob([byteArray], { type: "application/pdf;base64" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }


  return (
    <div className=" my-auto lg:hidden md:hidden ">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center dark:text-white  px-2 py-1.5 text-sm font-medium text-gray-700 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z"
              />
            </svg>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
            
              <Menu.Item>
                {({ active }) => (
                  <button
                  type="button"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-5 w-5 mr-1 text-gray-400 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                    Limpiar
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default MobileButtonOptionsRt;
