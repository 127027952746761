import React, { useState } from "react";
import { SmallModalInfo } from "../components/SmallModalInfo";
import preguntas from "../../img/preguntas.png";
import { ModalSoporte } from "./ModalSoport";
export const CentroAyudaModal = () => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleAccordionChange = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  const Modaltuto = () => {
    document.getElementById("centroModalclose").click();
  };

  const Modaltutow = () => {
    document.getElementById("centroModalclose").click();
    setIsOpen(!isOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ModalSoporte isOpen={isOpen} toggleModal={toggleModal} />

      <SmallModalInfo buttonModal={"centroModal"}>
        <div>
          <p className="text-white bg-blue-900 p-5  text-center">
            Centro de ayuda
          </p>
          <div className="grid grid-cols-2 my-4 ">
            <div className="flex justify-center flex-col mx-auto">
              <button
                onClick={() => Modaltuto()}
                data-bs-toggle="modal"
                data-bs-target="#tutorialesModal"
                className="w-28 h-28 flex items-center justify-center bg-blue-900 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-12 h-12 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                  />
                </svg>
              </button>
              <p className="text-center my-2">Tutoriales</p>
            </div>
            <div className="flex justify-center flex-col mx-auto">
              <button
                onClick={() => Modaltuto()}
                data-bs-toggle="modal"
                data-bs-target="#preguntasModal"
                className="w-28 h-28 flex items-center justify-center bg-blue-900 rounded-full"
              >
                <img
                  src={preguntas}
                  alt="preguntas"
                  className="w-12 h-12 text-white"
                />
              </button>
              <p className="text-center my-2">Preguntas frecuentes</p>
            </div>
          </div>

          <p className="text-center text-xl font-semibold">
            ¿Necesitas comunicarte con nosotros?
          </p>
          <div className="grid grid-cols-2 my-4">
            <div className="flex justify-center flex-col mx-auto">
              <button
                onClick={() => Modaltutow()}
                className="w-28 h-28 flex items-center justify-center bg-blue-900 rounded-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-12 h-12 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                  />
                </svg>
              </button>
              <p className="text-center my-2">Soporte</p>
            </div>
            <div className="flex justify-center flex-col mx-auto">
              <a href={`https://wa.me/${process.env.REACT_APP_CELLPHONE_SUPPORT}?text=Hola%20deseo%20solicitar%20más%20información`} target="_blank" className="w-28 h-28 flex items-center text-white justify-center bg-blue-900 rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  fill="currentColor"
                  className="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
                </svg>
              </a>
              <p className="text-center my-2">WhatsApp</p>
            </div>

          </div>
        </div>
      </SmallModalInfo>
    </>
  );
};