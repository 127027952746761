import { useContext, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DownArrowIcon, SearchIcon } from "../../../../Icons/ButtonIcons";
import { InputGlobal } from "../../context/inputContext";
import AutoCompleteInput from "../../context/AutoCompleteSelect";
import { ExcelIcon, PDFIcon } from "../../../../Icons/FileIcon";
import { useSelector } from "react-redux";
import { GenerarExcelValoracionInventario, ObtenerSeccionesTransaccion } from "../../../../services/InventarioService";
import { Transition } from "@headlessui/react";
import { toast } from "react-toastify";
import { ObtenerCategoriasActivas } from "../../../../services/InventarioService";
import { ValoracionInventarioContext } from "./context/ValoracionInventarioContext";
import { ClipLoader } from "react-spinners";

export const ValoracionInventarioForm = () => {
  const [openForm, setOpenForm] = useState(true);
  const [categorias, setCategorias] = useState([])
  const { getValoracionInventario, valoracionInventario } = useContext(ValoracionInventarioContext);
  const empresa = useSelector((state) => state.empresa.empresa);
  const [isDownloadingExcel, setIsDownloadingExcel] = useState(false);
  const [filtros, setFiltros] = useState(null);

  const [listaSecciones, setListaSecciones] = useState([]);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerSeccionesTransaccion(
        "/api/transacciones/obtener-secciones",
        empresa.idEmpresa
      ).then((response) => setListaSecciones(response.data));
    }
  }, [empresa]);

  useEffect(() => {
    if (empresa && empresa.idEmpresa) {
      ObtenerCategoriasActivas(
        "/api/categoria/obtener-categorias-activas",
        empresa.idEmpresa
      ).then((response) => setCategorias(response.data));
    }
  }, [empresa]);

  const methods = useForm({
    defaultValues: {
      periodo: new Date().toISOString().slice(0, 7),
    },
  });

  const saveFilter = async (data) => {
    if (!data.seccion || !data.periodo) {
      toast.info("Debe seleccionar una sección y un periodo");
      return
    }
    setFiltros({
      idCategoria: data.categoria?.idCategoriaProducto ?? null,
      idSeccion: data.seccion.idSeccion,
      periodo: data.periodo
    });
    await getValoracionInventario(data);
  };

  const exportValoracionInventarioExcel = async () => {
    try {
      setIsDownloadingExcel(true);
      const excelBase64 = await GenerarExcelValoracionInventario(empresa.idEmpresa, filtros);
      const excelBinary = atob(excelBase64);
      const byteNumbers = new Array(excelBinary.length);
      for (let i = 0; i < excelBinary.length; i++) {
        byteNumbers[i] = excelBinary.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const blobUrl = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "ValoracionInventario.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error("Error al procesar el archivo Excel:", err);
    } finally {
      setIsDownloadingExcel(false);
    }
  };


  const searchSecciones = async (inputText) => {
    return listaSecciones.filter(
      (seccion) =>
        String(seccion.nombreBodega)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(seccion.nombreSeccion)
          .toLowerCase()
          .includes(String(inputText).toLowerCase()) ||
        String(seccion.establecimiento)
          .padStart(3, "0")
          .toLowerCase()
          .includes(String(inputText).toLowerCase())
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="p-4 flex flex-row w-full justify-between items-center bg-[#003B5B] rounded-t-md">
        <h1 className="text-xl pl-3 pr-3 text-white font-bold">Filtros</h1>
        <button
          className={`text-white ${
            openForm ? "rotate-180 duration-300" : "rotate-0 duration-300"
          }`}
          onClick={() => setOpenForm(!openForm)}
        >
          <DownArrowIcon />
        </button>
      </div>
      <form
        onSubmit={methods.handleSubmit(saveFilter)}
        autoComplete="off"
        className="flex flex-col gap-1 relative"
      >
        <Transition
          show={openForm}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="w-full border border-gray-300
           dark:border-gray-600 dark:bg-gray-700 rounded-b-lg p-6 pl-8 pr-8"
          >
            <div className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 w-full gap-4">
              <AutoCompleteInput
                title={"Categoria"}
                active={true}
                disabled={
                  methods.watch("producto") ||
                  (methods.watch("bodega") &&
                    (methods.watch("categoria") === null ||
                      methods.watch("categoria") === undefined ||
                      methods.watch("categoria") === "") &&
                    (methods.watch("producto") === null ||
                      methods.watch("producto") === undefined ||
                      methods.watch("producto") === ""))
                }
                array={categorias}
                identificador={"idCategoriaProducto"}
                option={(e) => e["nombre"]}
                parametro={"nombre"}
              />
              <AutoCompleteInput
                title={"Sección"}
                name={"seccion"}
                searchFn={searchSecciones}
                array={listaSecciones}
                parametro={"nombre"}
                identificador={"idSeccion"}
                option={(e) => `${String(e["bodegaSeccion"])}`}
              />
              <InputGlobal type={"month"} title={"Período"} name={"periodo"} />
            </div>
          </div>
        </Transition>
        <div className="flex flex-row gap-2 p-2 items-center mt-3 text-white text-[12px]">
          <button
            type="submit"
            className="p-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 pr-5 pl-5 flex flex-row gap-2 items-center"
          >
            <span>
              <SearchIcon />
            </span>
            Buscar
          </button>
          {valoracionInventario.length > 0 && filtros !== null && <button
            type="button"
            onClick={() => exportValoracionInventarioExcel()}
            className="p-3 pl-8 pr-8 rounded-lg whitespace-nowrap bg-gray-600 hover:bg-gray-700 flex flex-row gap-2 items-center"
          >
           {isDownloadingExcel === false ? (
                <>
                  <span>
                    <ExcelIcon />
                  </span>
                  <h1 className="hide-on-small">Generar Excel</h1>
                </>
              ) : (
                <>
                  <ClipLoader color="#d8d4d4" size={"19"} />
                  <span>Cargando</span>
                </>
              )}
          </button>}
        </div>
      </form>
    </FormProvider>
  );
};
