import { useDispatch, useSelector } from "react-redux";
import { WarningIcon } from "../../../../Icons/WarningIcon";
import { BodegaModalOff } from "./modalCrearBodega.slice";
import { useContext } from "react";
import { BodegaContext } from "./context/BodegasContext";
import { ModalBase } from "../../Shared/ModalBase";
import { toast } from "react-toastify";

export const ModalEliminarBodega = () => {
  const { deleteBodega } = useContext(BodegaContext);
  const registro = useSelector((state) => state.modalBodega.registroAfectado);
  const dispatch = useDispatch();

  const doBodegaDelete = async () => {
    dispatch(BodegaModalOff());
    await toast.promise(deleteBodega(registro.idBodega), {
      pending: "Eliminando bodega...",
      success: "¡La bodega fue eliminada con éxito!",
      error: {
        render({ data }) {
          return (
            data?.response?.data?.message ||
            "Hubo un error al eliminar la bodega."
          );
        },
      },
    });
  };

  return (
    <ModalBase onClose={BodegaModalOff} useRedux={true}>
      {" "}
      <section
        style={{
          padding: "38px",
          height: "fit-content",
          position: "relative",
        }}
        className="shadow-xl rounded-lg flex flex-col bg-white dark:bg-gray-900 gap-6 items-center lg:w-[600px] md:w-[450px] w-[375px]"
      >
        <div className="text-[#003B5B] h-full items-center justify-center">
          <WarningIcon />
        </div>
        <div className="flex flex-col gap-5 items-center">
          <h1 className="text-md font-semibold dark:text-white text-center">
            ¿Estás seguro que deseas eliminar la Bodega {registro.nombre}?
          </h1>
          <div className="flex flex-row-reverse gap-8 text-white">
            <button
              onClick={() => doBodegaDelete()}
              className="bg-[#003B5B] hover:bg-[#112935] p-8 pt-3 pb-3 h-8 text-[12px] w-28 flex items-center justify-center rounded-lg whitespace-nowrap"
            >
              Si, eliminar
            </button>
            <button
              onClick={() => dispatch(BodegaModalOff())}
              className="bg-gray-600 p-8 pt-2 pb-2 h-8 text-[12px] hover:bg-gray-700 flex items-center justify-center w-28 rounded-lg whitespace-nowrap"
            >
              No, mantener
            </button>
          </div>
        </div>
      </section>
    </ModalBase>
  );
};
