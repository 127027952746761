import React, { useContext } from "react";
import {
  getNoventaDiasAtras,
  rellenarCeros,
} from "../../../services/Utilitario";
import InputDate from "../../../features/components/common/InputDate";
import InputText from "../../../features/components/common/InputText";
import { SecuencialProviderContext } from "./hooks/SecuencialProvider";

const HeaderFactura = (props) => {
  const {
    EstablecimientoSelector,
    PuntoEmisionSelector
  } = useContext(SecuencialProviderContext);
  return (
    <>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-2 w-full lg:max-w-2xl text-center">
        <div className="">
          <EstablecimientoSelector />
        </div>
        <div>
          <PuntoEmisionSelector />
        </div>
        <div className="my-auto text-left">
          <InputText
            title={"Secuencial:"}
            name={"Secuencial"}
            value={rellenarCeros(props.SecuencialVal || "0", 9)}
            disabled={true}
          />
        </div>
        <div className="my-auto text-left">
          <InputDate
            title={"Fecha emisión:"}
            name={"fechaEmision"}
            min={getNoventaDiasAtras()}
          />
        </div>
      </div>
    </>
  );
};

export default HeaderFactura;
