import { DeleteIcon, EditIcon } from "../../../../Icons/ButtonIcons";
import { useStore } from "zustand";
import {
  ModalSeccionStatus,
  useModalSeccionStore,
} from "./SeccionModalContext";

export const CrearSeccionItem = ({
  Seccion,
  SeccionesChecked,
  handleClick,
}) => {
  const { changeSeccionModal } = useStore(useModalSeccionStore);

  return (
    <tr className="bg-white dark:bg-gray-700 text-center dark:text-white font-normal  border-y-2 border-gray-300 dark:border-gray-600">
      <th style={{ padding: "15px" }}>
        <input
          type="checkbox"
          checked={
            SeccionesChecked.find((seccion) => seccion === Seccion.idSeccion)
              ? true
              : false
          }
          onChange={() => handleClick(Seccion.idSeccion)}
        />
      </th>
      <th className="font-normal">
        {String(Seccion?.codigoEstablecimiento).padStart(3, "0")}
      </th>
      <th className="font-normal">{Seccion.nombreBodega}</th>
      <th className="font-normal">{Seccion.nombre}</th>
      <th
        style={{ padding: "15px" }}
        className="flex justify-center flex-row gap-2"
      >
        <button
          onClick={() => changeSeccionModal(ModalSeccionStatus.editar, Seccion)}
        >
          <EditIcon />
        </button>
        <button
          onClick={() =>
            changeSeccionModal(ModalSeccionStatus.eliminar, Seccion)
          }
        >
          <DeleteIcon />
        </button>
      </th>
    </tr>
  );
};
