import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getListV2, PutElement } from "../../../services";
import { UTF8RegExp } from "../../../services/Utilitario";
import {
  StyleInput,
  StyleLabel,
  StyleInputError,
  StyleLabelError,
} from "../../../features/Constantes";
import { LargeModalForm } from "../../../features/components/LargeModalForm";

import { ReporteServicioContext } from "./ReporteServicioProvider";
import InformacionAdicional from "./InformacionAdicional";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import InputNumber from "../../../features/components/common/InputNumber";

const Modals = () => {
  const { formik, DetalleItem, setDetalleItem } = useContext(
    ReporteServicioContext
  );

  const [ImpLista, setImpLista] = useState([]);
  const [TypeForAdd, setTypeForAdd] = useState("1");
  const [IsLoading, setIsLoading] = useState(false);
  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);

  const empresa = useSelector((store) => store.empresa.empresa);

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
    setValue,
    setError,
  } = useForm({ mode: "onSubmit" });

  const { ErrorToast } = useGlobalToastify();
  const [Open, SetOpen] = useState(false);

  useEffect(() => {
    Object.values(errors)[0] && ErrorToast(Object.values(errors)[0].message);
  }, [errors, ErrorToast]);

  useEffect(() => {
    if (ImpLista.length > 0) {
      setValue("idImpuestoIva", ImpLista[0].idImpuestoDetalle);
      setValue("idEmpresa", empresa.idEmpresa);
    }
  }, [ImpLista, empresa, setValue]);

  useEffect(() => {
    (async () => {
      let impList = await getListV2(
        empresa,
        "GetAllimpDetalles",
        "?activo=true&idImpuesto=1"
      );
      setImpLista([...impList._embedded]);
    })();
  }, [empresa]);

  useEffect(() => {
    if (DetalleItem) {
      reset({ ...DetalleItem, activo: DetalleItem.activo ? "True" : "False" });
      setInformacionAdicionalList([...DetalleItem.servicioDetalle]);
    }
  }, [DetalleItem, empresa, reset]);

  const handleSubmitModal = async () => {
    try {
      setIsLoading(true);
      let values = { ...getValues() };
      values.codigoAuxiliar = values.codigoAuxiliar?.trim();

      let res = await getListV2(
        empresa,
        "ExisteServicio",
        `?codigo=${values.codigoPrincipal}`
      );

      if (res && DetalleItem.codigoPrincipal !== values.codigoPrincipal) {
        setError("codigoPrincipal", {
          type: "custom",
          message: "Un producto con ese codigo principal ya existe!",
        });
        throw new Error("Codigo principal ya existe!");
      }
      if (values.descripcion.length < 1) {
        setError("descripcion", {
          type: "custom",
          message: "descripcion no valida!",
        });
        throw new Error("descripcion no tiene info!");
      }

      values.stock =
        values.stock === null ||
        values.stock === "" ||
        values.stock === undefined
          ? 0
          : values.stock;
      values.servicioDetalle = [...InformacionAdicionalList];

      values.codigoAuxiliar =
        values.codigoAuxiliar === "" ? null : values.codigoAuxiliar;
      values.descripcion = values.descripcion
        .trim()
        .replaceAll(/(\r\n|\n|\r)/gm, " ");
      await PutElement(empresa, "PutServicio", values);
      formik.handleSubmit();
      setDetalleItem(null);
      document.getElementById("editProductoModalclose").click();
    } catch (ex) {
      //console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LargeModalForm
      IsLoading={IsLoading}
      buttonModal={"editProductoModal"}
      title="Información del Producto / Servicio"
      subTitle="Editar información"
      submitText="Guardar"
      submitModal={handleSubmit(handleSubmitModal)}
    >
      <div className="relative z-0 mb-2 w-full group">
        <div className="w-full grid md:grid-cols-3 my-8 md:gap-4 mb-2 ">
          <div className="relative z-0 mb-2 w-full group">
            <input
              type="text"
              name="codigoPrincipal"
              id="codigoPrincipal"
              className={errors.codigoPrincipal ? StyleInputError : StyleInput}
              {...register("codigoPrincipal", {
                required: {
                  value: true,
                  message: "Código Principal es requerido",
                },
                maxLength: {
                  value: 25,
                  message:
                    "Código Principal no debe tener más de 25 carácteres!",
                },
                minLength: {
                  value: 1,
                  message: "Mínimo 1 carácter",
                },
                pattern: UTF8RegExp,
              })}
              // maxLength="25"
            />
            <label
              htmlFor="codigoPrincipal"
              className={errors.codigoPrincipal ? StyleLabelError : StyleLabel}
            >
              Código principal
            </label>
          </div>
          <div className="relative z-0 mb-2 w-full group">
            <input
              type="text"
              name="codigoAuxiliar"
              id="codigoAuxiliar"
              className={errors.codigoAuxiliar ? StyleInputError : StyleInput}
              {...register("codigoAuxiliar", {
                maxLength: {
                  value: 25,
                  message:
                    "Codigo Auxiliar no debe tener más de 25 carácteres!",
                },
                minLength: {
                  value: 1,
                  message: "Codigo Auxiliar debe tener mínimo 1 carácter",
                },
                pattern: UTF8RegExp,
              })}
              // maxLength="25"
            />
            <label
              htmlFor="codigoAuxiliar"
              className={errors.codigoAuxiliar ? StyleLabelError : StyleLabel}
            >
              Código auxiliar
            </label>
          </div>

          <div className="relative z-0 mb-2 w-full group">
            <select
              name="tipo"
              id="tipo"
              className={errors.tipo ? StyleInputError : StyleInput}
              x-model="item.gst"
              {...register("tipo", {
                required: {
                  value: true,
                  message: "Precio Unitario es requerido",
                },
              })}
              onChange={(e) => setTypeForAdd(e.target.value)}
            >
              <option value="1">PRODUCTO</option>
              <option value="2">SERVICIO</option>
            </select>
            <label
              htmlFor="tipo"
              className={errors.tipo ? StyleLabelError : StyleLabel}
            >
              Tipo
            </label>
          </div>
        </div>

        <div className="w-full grid md:grid-cols-4 md:gap-2 mb-2 ">
          <div className="relative  z-0 mb-2 w-full group">
            <select
              name="idImpuestoIva"
              id="idImpuestoIva"
              className={StyleInput}
              x-model="item.gst"
              {...register("idImpuestoIva", {
                required: {
                  value: true,
                  message: "El tipo de impuesto es requerido",
                },
              })}
            >
              {ImpLista.map((el, key) => {
                return (
                  <option key={key} value={el.idImpuestoDetalle}>
                    {el.detalle}
                  </option>
                );
              })}
            </select>
            <label
              htmlFor="idImpuestoIva"
              className={errors.idImpuestoIva ? StyleLabelError : StyleLabel}
            >
              IVA
            </label>
          </div>
          <div className="relative z-0 mb-2 w-full group">
            {/* <input
              type="number"
              name="precioUnitario"
              id="precioUnitario"
              className={errors.precioUnitario ? StyleInputError : StyleInput}
              {...register("precioUnitario", {
                required: {
                  value: true,
                  message: "Precio Unitario es requerido",
                },
                maxLength: {
                  value: 13,
                  message:
                    "precio unitario no deberia tener más de 13 carácteres!",
                },
                minLength: {
                  value: 1,
                  message: "precio unitario debe tener mínimo 1 carácter",
                },
              })} */}

            <InputNumber
              identifier="precioUnitario"
              value={getValues("precioUnitario")}
              onChange={(e) => {
                setValue("precioUnitario", e.target.value);
                console.log(e.target.value);
              }}
              decimalMax={6}
              className={errors.precioUnitario ? StyleInputError : StyleInput}
            />
            <label
              htmlFor="precioUnitario"
              className={errors.precioUnitario ? StyleLabelError : StyleLabel}
            >
              Subtotal unitario
            </label>
          </div>
          {/* {TypeForAdd === "1" ? (
            <div className="relative z-0 mb-2 w-full group">
              <input
                name="stock"
                id="stock"
                className={errors.stock ? StyleInputError : StyleInput}
                type="number"
                {...register("stock", {
                  maxLength: {
                    value: 13,
                    message: "Stock no debe tener más de 13 carácteres!",
                  },
                })}
              />
              <label
                htmlFor="stock"
                className={errors.stock ? StyleLabelError : StyleLabel}
              >
                Stock
              </label>
            </div>
          ) : (
            <></>
          )} */}
          <div className="relative z-0 mb-2 w-full group">
            <select
              name="activo"
              id="activo"
              className={errors.activo ? StyleInputError : StyleInput}
              x-model="item.gst"
              {...register("activo", {
                required: {
                  value: true,
                  message: "El estado es requerido",
                },
              })}
            >
              <option value="True">ACTIVO</option>
              <option value="False">INACTIVO</option>
            </select>
            <label
              htmlFor="activo"
              className={errors.activo ? StyleLabelError : StyleLabel}
            >
              Estado
            </label>
          </div>
        </div>
        <div className="w-full grid md:grid-cols-2 md:gap-4 mb-2 bg-red">
          <div className="relative z-0 col-span-2  mb-2 w-full group ">
            <textarea
              name="descripcion"
              id="descripcion"
              rows="4"
              className={
                errors.descripcion
                  ? StyleInputError + " resize-none "
                  : StyleInput + " resize-none overflow-hidden"
              }
              {...register("descripcion", {
                required: {
                  value: true,
                  message: "descripcion es requerido",
                },
                maxLength: {
                  value: 300,
                  message: "Descripción no puede tener más de 300 carácteres!",
                },
                minLength: {
                  value: 1,
                  message: "Descripción debe tener mínimo 1 carácteres",
                },
                pattern: UTF8RegExp,
              })}
              maxLength="300"
            />
            <label
              htmlFor="direccion"
              className={
                errors.descripcion
                  ? StyleLabelError + " -translate-y-28"
                  : StyleLabel + " -translate-y-28"
              }
            >
              Descripción
            </label>
          </div>
        </div>
        <div>
          <div>
            <p
              onClick={() => SetOpen(!Open)}
              className="text-lg font-semibold p-2 border-b border-b-gray-400 select-none cursor-pointer dark:text-white flex gap-x-2  items-center   font-body"
            >
              Información adicional (Opcional){" "}
              {!Open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              )}
            </p>
            {Open ? (
              <InformacionAdicional
                InformacionAdicionalList={InformacionAdicionalList}
                setInformacionAdicionalList={setInformacionAdicionalList}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </LargeModalForm>
  );
};

export default Modals;
