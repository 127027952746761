import { Menu } from "@headlessui/react";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

export const OptionOpenMenu = ({ setSubMenu, OpenMenu, subMenu, Data }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const OpenSubMenu = () => {
    setSubMenu(subMenu === Data.item ? null : Data.item);
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  useEffect(() => {
    if (subMenu !== Data.item) setIsSubMenuOpen(false);
  }, [subMenu]);

  return (
    <Menu>
      <Menu.Button className={"w-full"} onClick={() => OpenSubMenu()}>
        <li className=" py-3 px-12 relative transition-colors hover:bg-[#5F7F93]  duration-300 flex flex-row  items-center dark:hover:text-gray-200">
          <div className="flex items-center">
            <span
              className={`${
                !OpenMenu && "hidden"
              } origin-left duration-200 whitespace-nowrap`}
            >
              {Data.nameMenu}
            </span>
          </div>

          <span className={`${!OpenMenu && "hidden"} absolute right-2`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className={`w-4 h-4 ${
                isSubMenuOpen
                  ? "rotate-180 duration-300"
                  : "rotate-0 duration-300"
              }`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </span>
        </li>
      </Menu.Button>
      {subMenu === Data.item && (
        <ul>
          {Data.subMenus.map((submenu, i) => (
            <NavLink
              key={i}
              exact="true"
              className={
                "py-3 text-[13px] px-12 transition-colors hover:bg-[#5F7F93] hover:   w-full duration-300 inline-block   dark:hover:text-gray-200"
              }
              to={submenu.route}
            >
              <span className="ml-3 whitespace-nowrap">
                {submenu.nameSubMenu}
              </span>
            </NavLink>
          ))}
        </ul>
      )}
    </Menu>
  );
};
