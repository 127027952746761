import React, { useEffect, createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getFechaInicio,
  getFechaV1,
  rellenarCeros,
  roundToTwo,
} from "../../../services/Utilitario";
import { PostLiquidacion } from "../../../services/LiquidacionService";
import {
  CalcularSubTotales,
  TotalInicial,
} from "../../../features/bl/DocumentSharedBL";
import useReembolso from "../../../features/hooks/Reembolso/useReembolso";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import useTotales from "../../../features/hooks/useTotales";
import { CalcularImpuestosList } from "../EmitirFactura/services/impuestos";
import moment from "moment";
import { toast } from "react-toastify";
import { useHours } from "../../../features/hooks/useHours";
import { TextMessageCom } from "../../../features/components/TextMessageCom";

export const EmitirLiquidacionContext = createContext();

const EmitirLiquidacionProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const { ActiveReembolso, ReembolsoList, handleDeleteElement } =
    useReembolso();

  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);
  const [FormaPagoList, setFormaPagoList] = useState([]);
  const [saveData, setSaveData] = useState(false);
  const [saveDataMessage, setSaveDataMessage] = useState("");
  const [saveDataSvg, setSaveDataSvg] = useState(0);
  const [DetalleList, setDetalleList] = useState([]);
  const [DestinatariosList, setDestinatariosList] = useState([]);
  //const [Totales, setTotales] = useState({ ...TotalInicial });
  const { Totales } = useTotales(DetalleList, FormaPagoList);
  const [ValorTotal, setValorTotal] = useState(1);
  const [ObjSaveData, setObjSaveData] = useState({});
  const [DataFromAdd, setDataFromAdd] = useState([]);
  const [ActivarModalDetalle, setActivarModalDetalle] = useState(false);
  const [DetalleItem, setDetalleItem] = useState([]);

  const { ErrorToast } = useGlobalToastify();

  const formik = useFormik({
    initialValues: {
      establecimiento: "",
      puntoEmision: "",
      secuencial: "",
      fechaEmision: getFechaInicio(),
      clienteIdentificacion: "",
      clienteRazonSocial: "",
      clienteDireccion: "",
      clienteEmail: "",
      clienteTelefono: "",
      GuiaRemision: "",
    },
    validationSchema: Yup.object({
      /*establecimiento: Yup.string()
                .max(3, 'Tamaño del campo 3'),
            puntoEmision: Yup.string()
                .max(3, 'Tamaño del campo 3'),*/
      secuencial: Yup.string().max(9, "Tamaño del campo 9"),
      GuiaRemision: Yup.string()
        .min(17, "Guia Incorrecta")
        .matches(/([0-9]+(-[0-9]+)+)$/, "Guia Incorrecta"),
      clienteIdentificacion: Yup.string()
        .max(300)
        .required("Este campo es requerido"),
      clienteRazonSocial: Yup.string()
        .max(300)
        .required("Este campo es requerido"),
      clienteDireccion: Yup.string()
        .max(300)
        .required("Este campo es requerido"),
      clienteEmail: Yup.string().max(300).required("Este campo es requerido"),
      clienteTelefono: Yup.string().max(20),
    }),
    onSubmit: async (obj) => {
      const fechaEmision = moment(formik.values.fechaEmision, "YYYY-MM-DD");
      const fechaLimite = moment().subtract(3, "months");
      if (fechaEmision.isAfter(fechaLimite)) {
        console.log(
          "La fecha de emisión es válida y está dentro de los últimos 3 meses."
        );
      } else {
        toast.error(
          "La fecha de emisión no es válida, no está dentro de los últimos 3 meses."
        );

        throw new Error("Error de validacion");
      }

      if (formik.values.fechaEmision > moment().format("YYYY-MM-DD")) {
        toast.error("La fecha de inicio no puede ser mayor a la fecha actual");
        return;
      }
      setSaveDataSvg(0);
      if (isOver72Hours) {
        setSaveDataMessage(<TextMessageCom />);
      } else {
        setSaveDataMessage("¿ESTÁ SEGURO DE QUE DESEA ENVIAR AL SRI?");
      }

      setSaveData(true);
      //console.log('---------------------')
      //console.log(obj)
      setObjSaveData(obj);
    },
  });

  const [isOpenModalHours, setisOpenModalHours] = useState(false);

  const toggleModal = () => {
    setisOpenModalHours(!isOpenModalHours);
  };

  const { isOver72Hours } = useHours({
    fechaActual: getFechaInicio(),
    FechaInput: formik.values.fechaEmision,
    toggleModal,
  });

  const guardarDocumento = async () => {
    formik.validateForm();
    let arrayValorGuiaRemision = ObjSaveData.GuiaRemision.split("-");
    //ObjSaveData.GuiaRemision = arrayValorGuiaRemision.join("");
    setSaveDataSvg(1);
    setSaveDataMessage("PROCESANDO...");
    if (DetalleList.length > 0 && FormaPagoList.length > 0) {
      try {
        if (arrayValorGuiaRemision.join("").match(/^[0-9]*$/g) == null) {
          setSaveDataMessage("REVISE EL CAMPO GUIA REMISIÓN");
          setSaveDataSvg(-1);
          // formik.setFieldValue("GuiaRemision", "")
          return;
        }
        //console.log(JSON.stringify(FormaPagoList));
        if (DetalleList.length > 0 && FormaPagoList.length > 0) {
          const ModelReembolso = ReembolsoList.map((x) => {
            let iva0 = "";
            let iva12 = "";
            let iva15 = "";
            let iva5 = "";
            let iva8 = "";
            let iva13 = "";

            x.DataReembolso.forEach((y) => {
              switch (y.Impuesto.porcentaje) {
                case 0.12:
                  iva12 = y.SubTotalReembolso;
                  break;
                case 0.0:
                  iva0 = y.SubTotalReembolso;
                  break;
                case 0.15:
                  iva15 = y.SubTotalReembolso;
                  break;
                case 0.05:
                  iva5 = y.SubTotalReembolso;
                  break;
                case 0.08:
                  iva8 = y.SubTotalReembolso;
                  break;
                case 0.13:
                  iva13 = y.SubTotalReembolso;
                  break;
                default:
                  break;
              }
            });
            return {
              AutorizacionComprobante: x.AutorizacionComprobante,
              FechaEmisionComprobante: x.FechaEmisionComprobante,
              TipoDocumento: x.TipoDocumento,
              TipoProveedor: x.TipoProveedor,
              RucProveedor: x.RucProveedor,
              NumeroComprobante: x.NumeroComprobante,
              IdIva12: "A5B8F607-8D38-4150-97AF-C591F83678D8",
              IdIva0: "81BAF871-E113-48CF-8AA8-670CB43494B6",
              IdIva15: "BED68281-CF9C-458E-9E0F-517A8FB02528",
              IdIva5: "2ED52B34-BA6C-475B-A82D-0F7C933412A7",
              IdIva8: "20FA15DF-58A0-4546-9DB1-BEE1CCCACF3E",
              IdIva13: "47BFE2B8-3630-4F76-8727-2D6808B862AC",
              Iva12: iva12,
              Iva0: iva0,
              Iva15: iva15,
              Iva5: iva5,
              Iva8: iva8,
              Iva13: iva13,
            };
          });

          const documento = {
            Emisor: empresa.emisor,
            identificacionProveedor: ObjSaveData.clienteIdentificacion,
            razonSocialProveedor: ObjSaveData.clienteRazonSocial,
            direccionProveedor: ObjSaveData.clienteDireccion,
            TelefonoCelular: ObjSaveData.clienteTelefono,
            Correo: ObjSaveData.clienteEmail
              .replace(/,/g, ";")
              .replace(/\s+/g, ""),
            FechaEmision: getFechaV1(ObjSaveData.fechaEmision),
            GuiaRemision: ObjSaveData.GuiaRemision,
            Establecimiento: ObjSaveData.establecimiento,
            PuntoEmision: ObjSaveData.puntoEmision,
            ImporteTotal: Totales.importeTotal,
            TotalSinImpuestos: Totales.totalSinImpuesto,
            TotalDescuento: Totales.totalDescuentos,
            // TotalTotalIva0: 0,
            // TotalTotalIva: roundToTwo(Totales.totalIva),
            // TotalNoObjetoImpuesto: 0,
            // TotalExentoIva: 0,
            // TotalBaseIva0: Totales.subTotalIva0,
            // TotalBaseIva: roundToTwo(Totales.subTotalIva),
            // TotalBaseNoObjetoImpuesto: Totales.subTotalNoObjetoIva,
            // TotalBaseExentoIva: Totales.subTotalExentoIva,
            ListaImpuestoliq: await CalcularImpuestosList(
              DetalleList,
              empresa,
              Totales
            ),
            Ambiente: process.env.REACT_APP_ENVIRONMENT,
            IdEmpresa: empresa.idEmpresa,
            RucEmpresa: empresa.ruc,
            CodDoc: 3,
            Reembolsos: ActiveReembolso ? [...ModelReembolso] : null,
            InformacionAdicionalLiquidaciones: InformacionAdicionalList.map(
              (x) => {
                return {
                  Descripcion: x.nombre,
                  Valor: x.valor,
                };
              }
            ),
            FormaPagoLiquidaciones: FormaPagoList.map((x) => {
              return {
                UnidaDeTiempo: x.tiempoPago,
                Plazo: x.plazoPago,
                Total: x.valor,
                IdFormaPagoSri: x.tipoPago,
              };
            }),
            DetalleLiquidaciones: DetalleList.map((e) => {
              // console.log(e);
              return {
                Descripcion: e.descripcion,
                Cantidad: e.cantidad,
                CodigoPrincipal: e.codigoPrincipal,
                CodigoAuxiliar: e.codigoAuxiliar,
                PrecioUnitario: e.precioUnitario,
                Descuento: e.descuento !== 0 ? e.descuento : 0,
                ImpuestoCodigoIva: 2,
                ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
                PrecioTotalSinImpuesto: roundToTwo(e.subTotal),
                ImpuestoBaseImponibleIva: roundToTwo(e.subTotal),
                ImpuestoValorIva:
                  e.idImpuestoIvaNavigation.porcentaje === 0
                    ? 0
                    : roundToTwo(e.iva),
                Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
                InformacionAdicionalServicioLiquidaciones:
                  e.servicioDetalle === undefined
                    ? []
                    : e.servicioDetalle === null
                    ? []
                    : e.servicioDetalle.map((x) => {
                        return {
                          Descripcion: x.nombre,
                          Valor: x.valor,
                        };
                      }),
              };
            }),
            IdImpuestoNoObjectoImpuesto: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "8E7CF164-C66B-4312-945F-57AE08C86A33"
            )
              ? "8E7CF164-C66B-4312-945F-57AE08C86A33"
              : null,
            IdImpuestoExentoIva: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "F03C7F10-2773-4505-ACCB-B2FA88D62049"
            )
              ? "F03C7F10-2773-4505-ACCB-B2FA88D62049"
              : null,
            IdImpuestoIVA12: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "A5B8F607-8D38-4150-97AF-C591F83678D8"
            )
              ? "A5B8F607-8D38-4150-97AF-C591F83678D8"
              : null,
            IdImpuestoIVA0: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "81BAF871-E113-48CF-8AA8-670CB43494B6"
            )
              ? "81BAF871-E113-48CF-8AA8-670CB43494B6"
              : null,

            IdImpuestoIVA15: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "BED68281-CF9C-458E-9E0F-517A8FB02528"
            )
              ? "BED68281-CF9C-458E-9E0F-517A8FB02528"
              : null,

            IdImpuestoIVA5: DetalleList.find(
              (e) =>
                e.idImpuestoIva.toUpperCase() ===
                "2ED52B34-BA6C-475B-A82D-0F7C933412A7"
            )
              ? "2ED52B34-BA6C-475B-A82D-0F7C933412A7"
              : null,
          };

          const cadenaCorreosModificada = ObjSaveData.clienteEmail
            .replace(/,/g, ";")
            .replace(/\s+/g, "");

          if (
            cadenaCorreosModificada !== "" &&
            !/^([^\s@]+@[^\s@]+\.[^\s@]+)(;[^\s@]+@[^\s@]+\.[^\s@]+)*$/.test(
              cadenaCorreosModificada
            )
          ) {
            ErrorToast("El campo 'Correo' no coincide con el patron requerido");
            throw new Error("Error de validacion");
          }

          // console.log(ObjSaveData);
          if (ObjSaveData.clienteEmail !== null)
            if (ObjSaveData.clienteEmail.trim() !== "")
              documento.InformacionAdicionalLiquidaciones.push({
                Valor: ObjSaveData.clienteEmail
                  .replace(/,/g, ";")
                  .replace(/\s+/g, ""),
                Descripcion: "email",
              });

          const Establecimiento = ObjSaveData.establecimiento;
          const PuntoEmision = ObjSaveData.puntoEmision;
          const Secuencial = ObjSaveData.secuencial;
          //console.log(JSON.stringify(documento));
          console.log(documento);
          let element = await PostLiquidacion(documento);
          //console.log(JSON.stringify(element.data))
          formik.resetForm();
          formik.setFieldValue("establecimiento", Establecimiento);
          formik.setFieldValue("puntoEmision", PuntoEmision);
          formik.setFieldValue("secuencial", rellenarCeros(Secuencial + 1, 9));
          //setTotales({ ...TotalInicial });
          setDetalleList([]);
          setFormaPagoList([]);
          setInformacionAdicionalList([]);
          setSaveDataSvg(2);
          setSaveDataMessage("EL DOCUMENTO SE GENERO EXITOSAMENTE");
          formik.setFieldValue(
            "secuencial",
            Number(formik.values.secuencial) + 1
          );
        }
      } catch (error) {
        // console.log(error);
        setSaveDataSvg(-1);
        setSaveDataMessage("ERROR AL GENERAR EL DOCUMENTO");
      }
    } else {
      setSaveDataSvg(-1);
      if (DetalleList.length < 1) {
        alert("#");
        setSaveDataMessage("FALTA AGREGAR DETALLE AL DOCUMENTO");
      } else if (FormaPagoList.length < 1) {
        setSaveDataMessage("EL DOCUMENTO NO POSEE FORMA DE PAGO");
      }
    }
  };

  useEffect(() => {
    let Totales = CalcularSubTotales(DetalleList, FormaPagoList);
    //console.log(Totales)
    //setTotales(Totales);
    setValorTotal(Totales.ValorTotal);
  }, [DetalleList, FormaPagoList]);

  return (
    <EmitirLiquidacionContext.Provider
      value={{
        isOpenModalHours,
        setisOpenModalHours,
        toggleModal,
        DetalleList,
        setDetalleList,
        saveData,
        setSaveData,
        saveDataMessage,
        saveDataSvg,
        guardarDocumento,
        formik,
        Totales,
        FormaPagoList,
        setFormaPagoList,
        InformacionAdicionalList,
        setInformacionAdicionalList,
        ActivarModalDetalle,
        setActivarModalDetalle,
        DetalleItem,
        setDetalleItem,
        ValorTotal,
        //setTotales,
        DestinatariosList,
        ActiveReembolso,
        ReembolsoList,
        handleDeleteElement,
        DataFromAdd,
      }}
    >
      {props.children}
    </EmitirLiquidacionContext.Provider>
  );
};

export default EmitirLiquidacionProvider;
