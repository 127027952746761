import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getFechaInicio,
  getFechaV1,
  roundToTwo,
} from "../../../services/Utilitario";
import { PostFactura } from "../../../services/FacturaService";
import useReembolso from "../../../features/hooks/Reembolso/useReembolso";
import { CalcularImpuestosList } from "./services/impuestos";
import useTotales from "../../../features/hooks/useTotales";
import { useForm } from "react-hook-form";
import useFacturaExportacion from "./hooks/FacturaExportacion/useFacturaExportacion";
import useGlobalToastify from "../../../features/hooks/GlobalToastify/useGlobalToastify";
import { useHours } from "../../../features/hooks/useHours";

export const EmitirFacturaContext = createContext();

const EmitirFacturaProvider = (props) => {
  const empresa = useSelector((store) => store.empresa.empresa);

  const [DetalleList, setDetalleList] = useState([]);
  const [FormaPagoList, setFormaPagoList] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [secuencialesExistentes, setSecuencialesExistentes] = useState([]);
  const [secuencialesProblemas, setSecuencialesProblemas] = useState([]);

  const {
    ActiveReembolso,
    ReembolsoList,
    setReembolsoList,
    setActiveReembolso,
    handleDeleteElement,
  } = useReembolso();

  const [isOpenModalHours, setIsOpenModalHours] = useState(false);

  const toggleModal = () => {
    setIsOpenModalHours(!isOpenModalHours);
  };

  const {
    Totales,
    incrementarContador,
    decrementarContador,
    incrementarContadorIVA5,
    decrementarContadorIVA5,
    incrementarContadorIVA13,
    decrementarContadorIVA13,
    incrementarContadorIVA8,
    decrementarContadorIVA8,
    incrementarContadorIVA12,
    decrementarContadorIVA12,
  } = useTotales(DetalleList, FormaPagoList);

  const { IsActiveExportacion } = useFacturaExportacion();

  const methods = useForm({
    defaultValues: {
      fechaEmision: getFechaInicio(),
    },
  });

  const { isOver72Hours } = useHours({
    fechaActual: getFechaInicio(),
    FechaInput: methods.watch("fechaEmision"),
    toggleModal,
  });

  const [InformacionAdicionalList, setInformacionAdicionalList] = useState([]);
  const [saveData, setSaveData] = useState(false);
  const [saveDataMessage, setSaveDataMessage] = useState("");
  const [saveDataSvg, setSaveDataSvg] = useState(0);

  const [saveDataPTVenta, setSaveDataPTVenta] = useState(false);

  const [ActivarModalDetalle, setActivarModalDetalle] = useState(false);
  const [DetalleItem, setDetalleItem] = useState([]);
  const [ConsumidorFinalToggle, setConsumidorFinalToggle] = useState(false);

  const { ErrorToast } = useGlobalToastify();

  const guardarDocumento = async () => {
    const ObjSaveData = methods.getValues();
    if (!IsActiveExportacion) ObjSaveData.exportacion = null;

    let arrayValorGuiaRemision =
      ObjSaveData.guiaRemision && ObjSaveData.guiaRemision.split("-");
    setSaveDataSvg(1);
    setSaveDataMessage("PROCESANDO...");
    if (DetalleList.length > 0 && FormaPagoList.length > 0) {
      try {
        if (
          arrayValorGuiaRemision &&
          arrayValorGuiaRemision.join("").match(/^[0-9]*$/g) == null
        ) {
          setSaveDataMessage("REVISE EL CAMPO GUIA REMISIÓN");
          setSaveDataSvg(-1);
          return;
        }
        const ModelReembolso = ReembolsoList.map((x) => {
          let iva0 = "";
          let iva12 = "";
          let iva15 = "";
          let iva5 = "";
          let iva8 = "";
          let iva13 = "";

          x.DataReembolso.forEach((y) => {
            switch (y.Impuesto.porcentaje) {
              case 0.12:
                iva12 = y.SubTotalReembolso;
                break;
              case 0.0:
                iva0 = y.SubTotalReembolso;
                break;
              case 0.15:
                iva15 = y.SubTotalReembolso;
                break;
              case 0.05:
                iva5 = y.SubTotalReembolso;
                break;
              case 0.08:
                iva8 = y.SubTotalReembolso;
                break;
              case 0.13:
                iva13 = y.SubTotalReembolso;
                break;
              default:
                break;
            }
          });
          return {
            AutorizacionComprobante: x.AutorizacionComprobante,
            FechaEmisionComprobante: x.FechaEmisionComprobante,
            TipoDocumento: x.TipoDocumento,
            TipoProveedor: x.TipoProveedor,
            RucProveedor: x.RucProveedor,
            NumeroComprobante: x.NumeroComprobante,
            IdIva12: "A5B8F607-8D38-4150-97AF-C591F83678D8",
            IdIva0: "81BAF871-E113-48CF-8AA8-670CB43494B6",
            IdIva15: "BED68281-CF9C-458E-9E0F-517A8FB02528",
            IdIva5: "2ED52B34-BA6C-475B-A82D-0F7C933412A7",
            IdIva8: "20FA15DF-58A0-4546-9DB1-BEE1CCCACF3E",
            IdIva13: "47BFE2B8-3630-4F76-8727-2D6808B862AC",
            Iva12: iva12,
            Iva0: iva0,
            Iva15: iva15,
            Iva5: iva5,
            Iva8: iva8,
            Iva13: iva13,
          };
        });

        if (DetalleList.length > 0 && FormaPagoList.length > 0) {
          const documento = {
            IdentificacionCliente: ObjSaveData.clienteIdentificacion,
            Emisor: empresa.emisor ?? "",
            RazonSocial: ObjSaveData.clienteRazonSocial,
            Direccion: ObjSaveData.clienteDireccion,
            TelefonoCelular: ObjSaveData.clienteTelefono,
            Correo: ObjSaveData.clienteEmail
              .replace(/,/g, ";")
              .replace(/\s+/g, ""),
            FechaEmision: getFechaV1(ObjSaveData.fechaEmision),
            GuiaRemision: ObjSaveData.guiaRemision,
            Establecimiento: ObjSaveData.establecimiento,
            PuntoEmision: ObjSaveData.puntoEmision,
            //ImporteTotal: Totales.importeTotal + IsActiveExportacion ? Number(ObjSaveData.exportacion?.fleteInternacional) + Number(ObjSaveData.exportacion?.seguroInternacional) + Number(ObjSaveData.exportacion?.gastosAduaneros) + Number(ObjSaveData.exportacion?.gastosTransporteOtros): null,
            ImporteTotal: roundToTwo(
              Totales.importeTotal +
                (Number(ObjSaveData.exportacion?.fleteInternacional) || 0) +
                (Number(ObjSaveData.exportacion?.seguroInternacional) || 0) +
                (Number(ObjSaveData.exportacion?.gastosAduaneros) || 0) +
                (Number(ObjSaveData.exportacion?.gastosTransporteOtros) || 0)
            ),
            TotalSinImpuestos: Totales.totalSinImpuesto,
            TotalDescuento: Totales.totalDescuentos,
            ListaImpuesto: await CalcularImpuestosList(
              DetalleList,
              empresa,
              Totales
            ),

            Ambiente: process.env.REACT_APP_ENVIRONMENT,
            IdEmpresa: empresa.idEmpresa,
            RucEmpresa: empresa.ruc,
            CodDoc: 1,
            Reembolsos: ActiveReembolso ? [...ModelReembolso] : null,
            exportacion: IsActiveExportacion
              ? {
                  ...ObjSaveData.exportacion,
                  incotermFactura:
                    ObjSaveData.exportacion.incotermFactura.toUpperCase(),
                  incotermLugar:
                    ObjSaveData.exportacion.incotermLugar.toUpperCase(),
                  puertoDestino:
                    ObjSaveData.exportacion.puertoDestino.toUpperCase(),
                  puertoEmbarque:
                    ObjSaveData.exportacion.puertoEmbarque.toUpperCase(),
                  incotermTotalSinImp:
                    ObjSaveData.exportacion.incotermTotalSinImp.toUpperCase(),
                }
              : null,

            InformacionAdicionalFactura: InformacionAdicionalList.map((x) => {
              return {
                Descripcion: x.nombre,
                Valor: x.valor,
              };
            }),
            FormaPagoFactura: FormaPagoList.map((x) => {
              return {
                UnidaDeTiempo: x.tiempoPago,
                Plazo: x.plazoPago,
                Total: x.valor,
                IdFormaPagoSri: x.tipoPago,
              };
            }),

            DetalleFactura: DetalleList.map((e) => {
              return {
                Descripcion: e.descripcion,
                Cantidad: e.cantidad,
                CodigoPrincipal: e.codigoPrincipal,
                CodigoAuxiliar: e.codigoAuxiliar,
                PrecioUnitario: e.precioUnitario,
                Descuento: e.descuento !== "" ? e.descuento : 0,
                ImpuestoCodigoIva: 2,
                ImpuestoCodigoPorcentajeIva: e.idImpuestoIvaNavigation.codigo,
                PrecioTotalSinImpuesto: roundToTwo(e.subTotal),
                ImpuestoBaseImponibleIva: roundToTwo(e.subTotal),
                ImpuestoValorIva:
                  e.idImpuestoIvaNavigation.porcentaje === 0
                    ? 0
                    : roundToTwo(e.iva),
                Tarifa: roundToTwo(e.idImpuestoIvaNavigation.porcentaje * 100),
                DetalleServicioFactura:
                  e.servicioDetalle === undefined
                    ? []
                    : e.servicioDetalle === null
                    ? []
                    : e.servicioDetalle.map((x) => {
                        return {
                          Descripcion: x.nombre,
                          Valor: x.valor,
                        };
                      }),
              };
            }),
          };
          //console.log(documento)
          const cadenaCorreosModificada = ObjSaveData.clienteEmail
            .replace(/,/g, ";")
            .replace(/\s+/g, "");

          if (!ConsumidorFinalToggle) {
            if (
              cadenaCorreosModificada !== "" &&
              !/^([^\s@]+@[^\s@]+\.[^\s@]+)(;[^\s@]+@[^\s@]+\.[^\s@]+)*$/.test(
                cadenaCorreosModificada
              )
            ) {
              ErrorToast(
                "El campo 'Correo' no coincide con el patron requerido"
              );
              throw new Error("Error de validacion");
            }
          }

          if (ObjSaveData.clienteEmail !== null)
            if (ObjSaveData.clienteEmail.trim() !== "")
              documento.InformacionAdicionalFactura.push({
                Valor: ObjSaveData.clienteEmail
                  .replace(/,/g, ";")
                  .replace(/\s+/g, ""),
                Descripcion: "email",
              });
          let element = await PostFactura(documento);
          setDetalleList([]);
          setFormaPagoList([]);
          setInformacionAdicionalList([]);
          setSaveDataSvg(2);
          setSaveDataMessage("EL DOCUMENTO SE GENERO EXITOSAMENTE");
          methods.reset({
            fechaEmision: getFechaInicio(),
            clienteIdentificacion: "",
            clienteRazonSocial: "",
            clienteDireccion: "",
            clienteEmail: "",
            clienteTelefono: "",
            guiaRemision: "",
            secuencial: Number(methods.getValues().secuencial) + 1,
            exportacion: {
              incotermLugar: "",
              puertoEmbarque: "",
              puertoDestino: "",
              incotermFactura: "",
              incotermTotalSinImp: "",
              fleteInternacional: "",
              seguroInternacional: "",
              gastosAduaneros: "",
              gastosTransporteOtros: "",
            },
          });
          setReembolsoList([]);
          setActiveReembolso(false);
        }
      } catch (error) {
        //AQUI
        // console.log(error);
        setSaveDataSvg(-1);
        setSaveDataMessage("ERROR AL GENERAR EL DOCUMENTO");
      }
    } else {
      setSaveDataSvg(-1);
      if (DetalleList.length < 1) {
        setSaveDataMessage("FALTA AGREGAR DETALLE AL DOCUMENTO");
      } else if (FormaPagoList.length < 1) {
        setSaveDataMessage("EL DOCUMENTO NO POSEE FORMA DE PAGO");
      }
    }
  };

  useEffect(() => {
    //console.log("DetalleList", Totales.importeTotal);
  }, []);

  const handleResetDocument = () => {
    let oldPuntoEmision = methods.getValues().puntoEmision;
    let oldEstablecimiento = methods.getValues().establecimiento;
    let oldSecuencial = Number(methods.getValues().secuencial);
    handleDeleteElement();
    methods.reset({
      establecimiento: "",
      puntoEmision: "",
      secuencial: "",
      fechaEmision: getFechaInicio(),
      clienteIdentificacion: "",
      clienteRazonSocial: "",
      clienteDireccion: "",
      clienteEmail: "",
      clienteTelefono: "",
      guiaRemision: "",
      exportacion: {
        incotermLugar: "",
        puertoEmbarque: "",
        puertoDestino: "",
        incotermFactura: "",
        incotermTotalSinImp: "",
        fleteInternacional: "",
        seguroInternacional: "",
        gastosAduaneros: "",
        gastosTransporteOtros: "",
      },
    });
    methods.setValue("secuencial", oldSecuencial);
    methods.setValue("puntoEmision", oldPuntoEmision);
    methods.setValue("establecimiento", oldEstablecimiento);
    // setTotales({ ...TotalInicial });
    setDetalleList([]);
    setFormaPagoList([]);
    setInformacionAdicionalList([]);
  };

  return (
    <EmitirFacturaContext.Provider
      value={{
        toggleModal,
        isOpenModalHours,
        setIsOpenModalHours,
        isOver72Hours,
        DetalleList,
        setDetalleList,
        saveData,
        setSaveData,
        saveDataMessage,
        saveDataSvg,
        guardarDocumento,
        Totales,
        FormaPagoList,
        setFormaPagoList,
        InformacionAdicionalList,
        setInformacionAdicionalList,
        ActivarModalDetalle,
        setActivarModalDetalle,
        DetalleItem,
        setDetalleItem,
        ReembolsoList,
        ActiveReembolso,
        setReembolsoList,
        setActiveReembolso,
        setSaveDataMessage,
        setSaveDataSvg,
        methods,
        handleResetDocument,
        saveDataPTVenta,
        setSaveDataPTVenta,
        ConsumidorFinalToggle,
        setConsumidorFinalToggle,
        incrementarContador,
        decrementarContador,
        incrementarContadorIVA5,
        decrementarContadorIVA5,
        incrementarContadorIVA13,
        decrementarContadorIVA13,
        incrementarContadorIVA8,
        decrementarContadorIVA8,
        incrementarContadorIVA12,
        decrementarContadorIVA12,
        /* Carga Masiva */
        groupedData,
        setGroupedData,
        secuencialesExistentes,
        setSecuencialesExistentes,
        secuencialesProblemas,
        setSecuencialesProblemas,
      }}
    >
      {props.children}
    </EmitirFacturaContext.Provider>
  );
};

export default EmitirFacturaProvider;
