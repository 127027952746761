import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  CambiarEstadoProducto,
  EliminarProducto,
  AgregarProducto,
  ObtenerProductos,
  EditarProducto,
  SubirExcel,
  ObtenerIDsProductos,
  CambiarEstadoMasivoProducto,
} from "../../../../services/InventarioService";

export const ProductoContext = createContext();

export const ProductoProvider = ({ children }) => {
  const empresa = useSelector((state) => state.empresa.empresa);
  const [isLoading, setIsLoading] = useState(false);
  const [productos, setProductos] = useState([]);
  const [productosChecked, setProductosChecked] = useState([]);
  const [IsCheckedAll, setIsCheckedAll] = useState(false);
  const [infoPaginacion, setInfoPaginacion] = useState({
    totalRecords: 0,
    totalPages: 0,
  });
  const [parametroPaginacion, setParametroPaginacion] = useState({
    pageNumber: 1,
    pageSize: 50,
  });
  const [Filtros, setFiltros] = useState({
    nombre: "",
    estado: "",
    usoProducto: "",
  });

  useEffect(() => {
    if (empresa && empresa.idEmpresa) getProductos();
  }, [parametroPaginacion, empresa, Filtros]);

  const getProductos = async () => {
    setIsLoading(true);
    try {
      const response = await ObtenerProductos(
        "/api/producto/obtener-productos",
        empresa.idEmpresa,
        parametroPaginacion.pageSize,
        parametroPaginacion.pageNumber,
        Filtros.nombre,
        Filtros.estado,
        Filtros.usoProducto
      );
      setProductos(response.data.data);
      setInfoPaginacion({
        totalPages: response.data.totalPages,
        totalRecords: response.data.totalRecords,
      });
    } catch (err) {
      throw new Error(
        err,
        "No se pudo obtener la información de las bodegas. Inténtelo de nuevo más tarde."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const addProducto = async (newProducto) => {
    const res = await AgregarProducto(
      "/api/producto/crear-producto",
      newProducto
    );
    if (res.code === 200) {
      await getProductos();
    } else {
      throw new Error("No se pudo guardar el producto");
    }
  };

  const deleteProducto = async (idProducto) => {
    const res = await EliminarProducto(
      "/api/producto/eliminar-producto",
      idProducto
    );
    if (res.code === 200) {
      await getProductos();
    } else {
      throw new Error("No se pudo eliminar el producto");
    }
  };

  const updateProducto = async (editProducto) => {
    const res = await EditarProducto(
      "/api/producto/editar-producto",
      editProducto
    );
    if (res.code === 200) {
      await getProductos();
    } else {
      throw new Error("No se pudo editar el producto");
    }
  };

  const CancelProductos = async () => {
    let ids;
    if (IsCheckedAll === true) {
      const res = await ObtenerIDsProductos(
        "/api/producto/obtener-productos-ids",
        empresa.idEmpresa,
        Filtros.nombre,
        Filtros.estado,
        Filtros.usoProducto
      );
      ids = res.data;
    } else {
      ids = productosChecked;
    }
    const res = await CambiarEstadoMasivoProducto(
      "/api/producto/desactivar-productos",
      ids
    );
    if (res.code === 200) {
      setProductos((prevProductos) => {
        return prevProductos.map((producto) => {
          if (ids.includes(producto.idProducto)) {
            return {
              ...producto,
              estado: false,
            };
          }
          return producto;
        });
      });
    }
  };

  const changeStateProducto = async (idProducto) => {
    try {
      const res = await CambiarEstadoProducto(
        "/api/producto/cambiar-estado",
        idProducto
      );
      if (res.code === 200) {
        setProductos((prevProductos) =>
          prevProductos.map((producto) =>
            producto.idProducto === idProducto
              ? {
                  ...producto,
                  estado: !producto.estado,
                }
              : producto
          )
        );
      } else {
        console.log("No se pudo cambiar el estado del Producto");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const uploadExcelProductos = async (productos) => {
    const res = await SubirExcel(
      "/api/producto/crear-producto-excel",
      productos
    );
    if (res.code === 200) {
    } else {
      throw new Error("No se pudo subir los productos.");
    }
  };

  const handleSelectAll = async () => {
    if (!IsCheckedAll) {
      const allIDs = productos
        .filter((producto) => producto.estado === true)
        .map((el) => el.idProducto);
      const AllItems = new Set([...allIDs, ...productosChecked]);
      setProductosChecked([...AllItems]);
    } else {
      setProductosChecked([]);
    }
  };

  const handleClick = (id) => {
    const isChecked = productosChecked.find((el) => el === id);
    if (isChecked) {
      const itemsWithoutElement = productosChecked.filter((el) => el !== id);
      setIsCheckedAll(false);
      setProductosChecked([...itemsWithoutElement]);
    } else {
      setProductosChecked((prevState) => [...prevState, id]);
    }
  };

  const CleanChecks = () => {
    setProductosChecked([]);
    setIsCheckedAll(false);
  };

  return (
    <ProductoContext.Provider
      value={{
        productos,
        setFiltros,
        addProducto,
        IsCheckedAll,
        setIsCheckedAll,
        setProductosChecked,
        productosChecked,
        deleteProducto,
        updateProducto,
        uploadExcelProductos,
        CancelProductos,
        handleClick,
        CleanChecks,
        handleSelectAll,
        isLoading,
        parametroPaginacion,
        changeStateProducto,
        infoPaginacion,
        setParametroPaginacion,
        getProductos,
      }}
    >
      {children}
    </ProductoContext.Provider>
  );
};
